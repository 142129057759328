import actions from '../../redux/tour/actions';
import { store } from '../../redux/store';
import React from 'react';
import {
  TOUR_DATA_ATTRIBUTE,
  tourStep0,
  tourStep1,
  tourStep2,
  tourStep3,
  tourStep4,
  tourStep5,
  tourStep6,
} from './constants';
import { Translate } from '../../utility/helpers';
import { ReactComponent as TourVideo } from 'images/svgs/tourVideo.svg';
import { ReactComponent as BulbIcon } from 'images/svgs/bulbIcon.svg';
import { ReactComponent as BackIcon } from 'images/svgs/backIcon.svg';
import { ReactComponent as CurveArrowIcon } from 'images/svgs/curveArrow.svg';
import { Flex } from 'antd';
import { addAsyncAction } from '../../utility/asyncHelper';
import authActions from '../../redux/auth/actions';

export function updateModalScreen(screen) {
  store.dispatch({
    type: actions.UPDATE_MODAL_SCREEN,
    payload: screen,
  });
}
export function apiActionForGroups() {
  const { onBoardingList } = store.getState().BoardGroups;
  const groupIds = onBoardingList
    .flatMap((list) =>
      list.groups.map((groups) =>
        groups && groups.checked ? groups.id : null,
      ),
    )
    .filter((id) => id !== null);
  addAsyncAction(actions.CREATE_GROUPS, {
    payload: {
      groupIds,
      isOnboarding: true,
    },
  });
}

export function handleTourModalVisibility(visibility) {
  store.dispatch({
    type: actions.TOUR_MODAL_VISIBILITY,
    payload: visibility,
  });
}

export function tourSteps({
  nextStep = () => {},
  prevStep = () => {},
  skipTour = () => {},
  endTour = () => {},
  playVideo = () => {},
}) {
  const steps = [
    tourStep0,
    tourStep1,
    tourStep2,
    tourStep3,
    tourStep4,
    tourStep5,
    tourStep6,
  ];
  return steps.map(
    (
      {
        title,
        description,
        placement = 'left',
        className = '',
        target = null,
        nextButtonLabel = 'next',
      },
      index,
    ) => {
      if (index === 0) {
        return {
          title: <Translate>{title}</Translate>,
          target: () => getTargetSelector(target),
          placement,
          className,
          mask: true,
        };
      } else if (index === 6) {
        return {
          description: (
            <div>
              <Translate>{description}</Translate>
            </div>
          ),
          target: () => getTargetSelector(target),
          placement,
          className,
          mask: true,
        };
      }
      return {
        title: (
          <Flex justify={'space-between'} align={'center'}>
            <div>{title}</div>
            <div className={'bulb-icon'}>
              <BulbIcon />
            </div>
            {/*Arrow icon for pointing target*/}
            {index === 5 && (
              <div className={'curve-arrow-icon'}>
                <CurveArrowIcon />
              </div>
            )}
          </Flex>
        ),
        description: (
          <div>
            <Translate>{description}</Translate>
          </div>
        ),
        cover: <TourVideo onClick={() => playVideo()} />,
        target: () => getTargetSelector(target),
        placement,
        className,
        mask: true,
        prevButtonProps: {
          children: (
            <Flex justify={'space-between'}>
              <div className={'back-icon'} onClick={() => prevStep()}>
                <BackIcon />
              </div>
              <div className={'skip-btn'} onClick={() => skipTour()}>
                <Translate>skip_tour</Translate>
              </div>
            </Flex>
          ),
        },
        nextButtonProps: {
          children: (
            <div>
              <Translate>{nextButtonLabel}</Translate>
            </div>
          ),
          onClick: () => {
            nextStep();
            index === 5 && endTour();
          },
        },
      };
    },
  );
}

function getTargetSelector(target) {
  // Exceptional case: .captures and body query selector used to prevent null values for target. Tour becomes unresponsive if target value is null
  if (!document.querySelector(`[${TOUR_DATA_ATTRIBUTE}="${target}"]`)) {
    return (
      document.querySelector('.captures') || document.querySelector('body')
    );
  }
  return document.querySelector(`[${TOUR_DATA_ATTRIBUTE}="${target}"]`);
}

export function updateOnBoardingSteps(step) {
  store.dispatch({
    type: authActions.UPDATE_ONBOARDING_TOUR_STEPS,
    payload: step,
  });
}
