import React from 'react';
import i18n from '../../i18n';
import { store } from 'redux/store';
import alertSuccessIcon from 'images/svgs/latest-V2/iconSuccess.svg';
import alertCloseIcon from 'images/svgs/latest-V2/iconCloseAlert.svg';
import alertFailureIcon from 'images/svgs/latest-V2/iconFailure.svg';
import alertInfoIcon from 'images/svgs/latest-V2/iconInfo.svg';
import alertWarningIcon from 'images/svgs/latest-V2/iconWarning.svg';
import {
  isObjectEmpty,
  randomIdGenerate,
  Translate,
} from '../../utility/helpers';

const alertView = ({
  alertText,
  type,
  delay,
  closeable = true,
  data,
  extractName = {},
}) => {
  const { captureNotification } = store.getState().commonReducer;


  if (type === 'info') {
    const notificationKey = randomIdGenerate('info-alert');
    return captureNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertInfoIcon} alt={'plusIcon'} />
          <span>{i18n.t(alertText, { data })}</span>
        </div>
      ),
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      className: 'custom-alert info',
      placement: 'bottom',
      duration: delay || 3,
      onClose() {
        captureNotification.destroy(notificationKey);
      },
    });
  } else if (type === 'success') {
    const notificationKey = randomIdGenerate('success-alert');
    return captureNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertSuccessIcon} alt={'plusIcon'} />
          <div className={'main-message'}>
            {!isObjectEmpty(extractName) ? (
              <>
                {extractName.sourceName}{' '}
                <Translate>successfully_moved</Translate>{' '}
                {extractName.destinationName}
              </>
            ) : (
              <div>{i18n.t(alertText, { data })}</div>
            )}
          </div>
        </div>
      ),
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      onClose() {
        captureNotification.destroy(notificationKey);
      },
      className: 'custom-alert success',
      placement: 'bottom',
      duration: delay || 3,
    });
  } else if (type === 'warning') {
    const notificationKey = randomIdGenerate('warning-alert');
    return captureNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertWarningIcon} alt={'plusIcon'} />
          <span>{i18n.t(alertText, { data })}</span>
        </div>
      ),
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      className: 'custom-alert warning',
      placement: 'bottom',
      duration: delay || 3,
      onClose() {
        captureNotification.destroy(notificationKey);
      },
    });
  } else if (type === 'error') {
    const notificationKey = randomIdGenerate('error-alert');
    return captureNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertFailureIcon} alt={'plusIcon'} />
          <span>{i18n.t(alertText, { data })}</span>
        </div>
      ),
      onClose() {
        captureNotification.destroy(notificationKey);
      },
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      className: 'custom-alert failure',
      placement: 'bottom',
      duration: delay || 3,
    });
  }
  const defaultNotificationKey = randomIdGenerate('default-alert');
  return captureNotification.open({
    key: defaultNotificationKey,
    message: i18n.t(alertText),
    className: 'custom-alert success',
    placement: 'bottom',
    duration: delay || 3,
    onClose() {
      captureNotification.destroy(defaultNotificationKey);
    },
  });
};

export default alertView;
