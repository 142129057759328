import { store } from 'redux/store';
import captureActions from 'redux/capture/actions';
import jotsActions from 'redux/jots/actions';
import commonActions from 'redux/common/actions';
import boardActions from 'redux/boardGroups/actions';
import { alertView, versionHandling } from '../../utility/helpers';
import { CONTAINER_TYPES, DEFAULT_ITEM_PROPERTIES } from './constant';
import { arrayMove } from '@dnd-kit/sortable';
import boardGroupActions from "../../redux/boardGroups/actions";

const { OFFSET_X, OFFSET_Y, MAX_Y } = DEFAULT_ITEM_PROPERTIES;
export function addSingleItemToCapture({ itemDetails, captureLists }) {
  store.dispatch({
    type: captureActions.ADD_DRAG_ITEM_TO_CAPTURE,
    payload: {
      itemDetails: {
        ...itemDetails,
        node_id: null,
        project_id: null,
        name: versionHandling(
          itemDetails.name,
          captureLists.map((data) => data?.name && data?.name?.toLowerCase()),
        ),
      },
    },
  });
}

export function removeSingleItemFromCapture({ activeId }) {
  store.dispatch({
    type: captureActions.REMOVE_DRAG_ITEM_FROM_CAPTURE,
    payload: {
      activeId,
    },
  });
}

export function removeMultipleItemsFromCapture({ activeIds }) {
  store.dispatch({
    type: captureActions.REMOVE_MULTIPLE_ITEM_FROM_CAPTURE,
    payload: {
      activeIds,
    },
  });
}

export function removeMultipleItemsFromJots({ activeIds }) {
  store.dispatch({
    type: jotsActions.REMOVE_MULTIPLE_ITEM_FROM_JOTS,
    payload: {
      activeIds,
    },
  });
}

export function removeMultipleItemsFromMyFiles({ activeIds }) {
  store.dispatch({
    type: boardActions.REMOVE_MULTIPLE_ITEM_FROM_MY_FILES,
    payload: {
      activeIds,
    },
  });
}

export function addSingleItemToJots({
  itemDetails,
  overContainerId,
  jotsProjectItems,
}) {
  store.dispatch({
    type: jotsActions.ADD_DRAG_ITEM_TO_JOTS,
    payload: {
      itemDetails: {
        ...itemDetails,
        project_id: overContainerId,
        name: versionHandling(
          itemDetails?.name,
          jotsProjectItems.map(
            (data) => data?.name && data?.name?.toLowerCase(),
          ),
        ),
      },
    },
  });
  setTimeout(()=> {
    store.dispatch({
      type: commonActions.HIGHLIGHT_ITEM,
      payload: itemDetails.id,
    });
  }, 0);
}

export function addMultipleItemsToJots({
  itemsDetailsArray,
  overContainerId,
  jotsProjectItems,
}) {
  const updatedItemsDetailsArray = itemsDetailsArray.map((itemDetails) => ({
    ...itemDetails,
    project_id: overContainerId,
    name: versionHandling(
      itemDetails.name,
      jotsProjectItems.map((data) => data?.name && data?.name?.toLowerCase()),
    ),
  }));

  store.dispatch({
    type: jotsActions.ADD_DRAG_ITEM_TO_JOTS,
    payload: {
      itemDetails: updatedItemsDetailsArray,
    },
  });
}

export function addMultipleItemsToCaptures({
  itemsDetailsArray,
  captureLists,
}) {
  const updatedItemsDetailsArray = itemsDetailsArray.map((itemDetails) => ({
    ...itemDetails,
    project_id: null,
    node_id: null,
    name: versionHandling(
      itemDetails.name,
      captureLists.map((data) => data?.name && data?.name?.toLowerCase()),
    ),
  }));

  store.dispatch({
    type: captureActions.ADD_DRAG_ITEM_TO_CAPTURE,
    payload: {
      itemDetails: updatedItemsDetailsArray,
    },
  });
}

export function addMultpleItemsToMyFiles({
  itemsDetailsArray,
  overContainerId,
  myFilesItems,
}) {
  const updatedItemsDetailsArray = itemsDetailsArray.map((itemDetails) => ({
    ...itemDetails,
    parent_id: overContainerId,
    name: versionHandling(
      itemDetails.name,
      myFilesItems.map((data) => data?.name && data?.name?.toLowerCase()),
    ),
  }));

  store.dispatch({
    type: boardActions.ADD_DRAG_ITEM_TO_BOARD,
    payload: {
      itemDetails: updatedItemsDetailsArray,
    },
  });
}

export function removeSingleItemFromJots({ activeId }) {
  store.dispatch({
    type: jotsActions.REMOVE_JOTS_DRAG_ITEM,
    payload: {
      activeId,
    },
  });
}

export function dndCreateModalVisibility({
  module,
  overContainerId,
  selectedType,
  dropAction,
  isCategory = false,
}) {
  store.dispatch({
    type: commonActions.SET_DROP_ACTION,
    payload: { dropAction },
  });
  if(isCategory) {
    store.dispatch({
      type: boardGroupActions.ADD_NEW_BOARD_GROUP_MODAL,
      visible: true,
    });
  } else {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module,
      payload: {
        visibility: true,
      },
    });
  }
  store.dispatch({
    type: commonActions.SHOW_CREATE_FOLDER_MODAL,
    projectId: overContainerId,
    selectedType,
  });
}

export function addSingleItemToMyFiles({
  itemDetails,
  overContainerId,
  boardDetails,
}) {
  store.dispatch({
    type: boardActions.ADD_DRAG_ITEM_TO_BOARD,
    payload: {
      itemDetails: {
        ...itemDetails,
        parent_id: overContainerId,
        name: versionHandling(
          itemDetails?.name,
          boardDetails.map((data) => data?.name && data?.name?.toLowerCase()),
        ),
      },
    },
  });
}

export function removeSingleItemFromMyFiles({ activeId }) {
  store.dispatch({
    type: boardActions.REMOVE_ITEM_FROM_BOARD,
    payload: {
      activeId,
    },
  });
}

export function reorderHelper({
  oldIndex = null,
  newIndex = null,
  projectItems = [],
  container = null,
}) {
  const changedOrderList = arrayMove(projectItems, oldIndex, newIndex);
  store.dispatch({
    type:
      container === CONTAINER_TYPES.JOTS
        ? jotsActions.REORDER_JOTS
        : boardActions.REORDER_MY_FILES,
    payload: { changedOrderList },
  });
}

export function resetMultipleCaptureSelect() {
  store.dispatch({
    type: captureActions.RESET_CAPTURE_MULTI_SELECT,
  });
}
export function resetMultipleJotsSelect() {
  store.dispatch({
    type: jotsActions.RESET_JOTS_MULTI_SELECT,
  });
}
export function resetMultipleMyFilesSelect() {
  store.dispatch({
    type: boardActions.RESET_MY_FILES_MULTI_SELECT,
  });
}
export function updateMyFilesMultiSelect({ updatedMultiSelect }) {
  store.dispatch({
    type: boardActions.UPDATE_MY_FILES_MULTI_SELECT_FROM_DND,
    payload: {
      updatedMultiSelect,
    },
  });
}

export function updateJotsMultiSelect({ updatedMultiSelect }) {
  store.dispatch({
    type: jotsActions.UPDATE_JOTS_MULTI_SELECT_FROM_DND,
    payload: {
      updatedMultiSelect,
    },
  });
}

export function determineObjectType({ activeItemType }) {
  switch (activeItemType) {
    case CONTAINER_TYPES.JOTS_ITEM:
    case CONTAINER_TYPES.MY_FILES_ITEM:
      return 'item';
    case CONTAINER_TYPES.JOTS_PAGE:
    case CONTAINER_TYPES.MY_FILES_PAGE:
    case CONTAINER_TYPES.FOLDER:
      return 'node';
    default:
      return 'item';
  }
}

export function removeItemsFromCaptureHelper({
  activeId,
  isMultipleCaptureSelect,
}) {
  const { captureMultiSelect } = store.getState().Capture;
  if (isMultipleCaptureSelect) {
    // Remove Multiple Items from Capture
    removeMultipleItemsFromCapture({ activeIds: captureMultiSelect });
    // Reset the multiple capture select
    resetMultipleCaptureSelect();
  } else {
    // Remove Item From Capture
    removeSingleItemFromCapture({ activeId });
  }
}

export function removeItemsFromJotsHelper({
  activeIds,
  isMultipleJotsItemSelect,
  restrictJotsMultiSelectUpdate = false,
}) {
  if (isMultipleJotsItemSelect) {
    // Remove Multiple Items From Jots
    removeMultipleItemsFromJots({
      activeIds,
    });

    // Reset Logic for MultiSelect
    const { jotsMultiSelect } = store.getState().jotsReducer;
    const containsPage = jotsMultiSelect.some(
      (item) => item.objectType === 'page',
    );
    if (!containsPage || restrictJotsMultiSelectUpdate) {
      resetMultipleJotsSelect();
    } else {
      const updatedMultiSelect = jotsMultiSelect.filter(
        (item) => item.objectType === 'page',
      );
      updateJotsMultiSelect({ updatedMultiSelect });
    }
  } else {
    // Remove Item From Jots
    removeSingleItemFromJots({ activeId: activeIds[0] });
  }
}
export function removeItemFromMyFilesHelper({
  activeIds,
  toCapture = false,
  toPage = false,
  toFolder = false,
  toCategory = false,
  isMultipleMyFilesItemSelect,
  sourceType,
}) {
  if (isMultipleMyFilesItemSelect) {
    const { myFilesMultiSelect } = store.getState().BoardGroups;
    const containsFolder = myFilesMultiSelect.some(
      (item) => item.objectType === 'folder',
    );
    const containsPage = myFilesMultiSelect.some(
      (item) => item.objectType === 'page',
    );
    // Reset Logic for MultiSelect

    if (toCapture && !containsPage) {
      resetMultipleMyFilesSelect();
    } else if (toPage) {
      let updatedMultiSelect = myFilesMultiSelect.filter(
        (item) => item.objectType === 'folder' || item.objectType === 'page',
      );
      updateMyFilesMultiSelect({ updatedMultiSelect });
    } else if (toCategory) {
      let updatedMultiSelect = myFilesMultiSelect.filter(
        (item) => !activeIds.includes(item.id),
      );
      updateMyFilesMultiSelect({ updatedMultiSelect });
    } else if (!containsFolder || toFolder) {
      resetMultipleMyFilesSelect();
    } else {
      let updatedMultiSelect = [];
      if (toCapture) {
        updatedMultiSelect = myFilesMultiSelect.filter(
          (item) => item.objectType === 'folder' || item.objectType === 'page',
        );
      } else {
        updatedMultiSelect = myFilesMultiSelect.filter(
          (item) => item.objectType === 'folder',
        );
      }
      updateMyFilesMultiSelect({ updatedMultiSelect });
    }
    // Remove Multiple Items From MyFiles
    removeMultipleItemsFromMyFiles({
      activeIds,
    });
  } else {
    // To remove category from myfiles
    if (sourceType === CONTAINER_TYPES.MY_FILES_CATEGORY) {
      store.dispatch({
        type: boardActions.REMOVE_ITEM_FROM_CATEGORY,
        payload: {
          activeId: activeIds[0],
        },
      });
    }
    // Remove Item From MyFiles
    removeSingleItemFromMyFiles({ activeId: activeIds[0] });
  }
}

export function addItemToMyFilesHelper({
  isMultipleSelectScenario,
  itemDetails,
  overContainerId,
  myFilesItems,
}) {
  // Adjust the coordinates of the items dropped as per the free space
  let itemsDetailsArray = adjustItemCoordinates({
    array: isMultipleSelectScenario ? itemDetails : [itemDetails],
    droppedOver: CONTAINER_TYPES.MY_FILES,
    projectId: overContainerId,
  });

  if (isMultipleSelectScenario) {
    addMultpleItemsToMyFiles({
      itemsDetailsArray,
      overContainerId,
      myFilesItems,
    });
  } else {
    addSingleItemToMyFiles({
      itemDetails: itemsDetailsArray[0],
      overContainerId,
      boardDetails: myFilesItems,
    });
  }
}
export function addItemToCaptureHelper({
  isMultipleSelectScenario,
  itemDetails,
  captureLists,
}) {
  if (isMultipleSelectScenario) {
    addMultipleItemsToCaptures({
      itemsDetailsArray: itemDetails,
      captureLists,
    });
  } else {
    addSingleItemToCapture({ itemDetails, captureLists });
  }
}
// Adjust the properties of Object for freespace
export function adjustItemCoordinates({
  array = [],
  droppedOver = CONTAINER_TYPES.JOTS, // Default to "jots" if not provided
  offsetX = OFFSET_X,
  offsetY = OFFSET_Y,
  maxY = MAX_Y,
  projectId = null,
}) {
  const defaultItemProperties = {
    x_coordinate: OFFSET_X,
    y_coordinate: OFFSET_Y,
  };
  const { myFilesLastItemProperties } =
    store.getState().BoardGroups || defaultItemProperties;
  const { jotsLastItemProperties } =
    store.getState().jotsReducer || defaultItemProperties;

  // Determine which last item properties to use based on the parent
  const lastItemProperties =
    droppedOver === CONTAINER_TYPES.MY_FILES
      ? myFilesLastItemProperties
      : jotsLastItemProperties;

  let currentX = lastItemProperties.x_coordinate || OFFSET_X;
  let currentY = lastItemProperties.y_coordinate || OFFSET_Y;

  const updatedArray = array.map((data) => {
    // Update both x_coordinate and y_coordinate in a cross pattern
    const updatedItem = {
      ...data,
      project_id: projectId,
      properties: {
        ...data?.properties,
        x_coordinate: currentX,
        y_coordinate: currentY,
      },
    };

    // Increment both x and y to create a cross-like arrangement
    currentX += offsetX;
    currentY += offsetY;

    // If the y_coordinate exceeds maxY, reset y and move to next column
    if (currentY > maxY) {
      currentX += offsetX; // Move further in x-direction for the next column
      currentY = 0; // Reset y-coordinate
    }

    return updatedItem;
  });

  // Update the respective last item properties based on the parent
  store.dispatch({
    type:
      droppedOver === CONTAINER_TYPES.MY_FILES
        ? boardActions.UPDATE_MY_FILES_LAST_ITEM_PROPERTIES
        : jotsActions.UPDATE_JOTS_LAST_ITEM_PROPERTIES,
    payload: {
      x_coordinate: currentX,
      y_coordinate: currentY,
    },
  });

  return updatedArray;
}

export function addItemToJotsHelper({
  isMultipleSelectScenario,
  itemDetails,
  overContainerId,
  jotsProjectItems,
}) {
  // Adjust the coordinates of the items dropped as per the free space
  let itemsDetailsArray = adjustItemCoordinates({
    array: isMultipleSelectScenario ? itemDetails : [itemDetails],
    droppedOver: CONTAINER_TYPES.JOTS,
    projectId: overContainerId,
  });

  if (isMultipleSelectScenario) {
    addMultipleItemsToJots({
      itemsDetailsArray,
      overContainerId,
      jotsProjectItems,
    });
  } else {
    addSingleItemToJots({
      itemDetails: itemsDetailsArray[0],
      overContainerId,
      jotsProjectItems,
    });
  }
}

export function updateJotsFreeSpaceCoordinates({ activeId, delta }) {
  store.dispatch({
    type: jotsActions.JOTS_FREE_SPACE_COORDINATES,
    payload: {
      activeId,
      coordinates: delta,
    },
  });
}

export function updateMyFilesFreeSpaceCoordinates({ activeId, delta }) {
  store.dispatch({
    type: boardActions.FREE_SPACE_COORDINATES,
    payload: {
      activeId,
      coordinates: delta,
    },
  });
}

export function reorderDocumentItemHelper({
  projectItems,
  activeContainerId,
  oldIndex,
  newIndex,
  initiatorContainer,
}) {
  const document = projectItems.find((item) => item.id === activeContainerId),
    documentItems = document.items;
  const changedOrderList = arrayMove(documentItems, oldIndex, newIndex);
  store.dispatch({
    type:
      initiatorContainer === CONTAINER_TYPES.JOTS
        ? jotsActions.REORDER_JOTS_DOCUMENT_ITEM
        : boardActions.REORDER_MY_FILES_DOCUMENT_ITEM,
    payload: { changedOrderList, nodeId: activeContainerId },
  });
}

export function triggerTargetItemHighlightAction() {
  const { highlightItem } = store.getState().commonReducer;
  if (highlightItem) {
    store.dispatch({
      type: commonActions.TRIGGER_TARGET_ITEM_HIGHLIGHT,
      payload: true,
    });
  }
}

export function addItemToPage({
  activeId = null,
  multiSelectArray = [],
  pageId = null,
  toJotsPage = false,
  isMultipleSelectScenario,
  from = CONTAINER_TYPES.JOTS,
}) {
  let itemDetails = [];

  // Get the item Details
  if (from === CONTAINER_TYPES.JOTS) {
    const { jotsProjectItems } = store.getState().jotsReducer;
    itemDetails = isMultipleSelectScenario
      ? jotsProjectItems?.filter((data) => multiSelectArray.includes(data?.id))
      : jotsProjectItems.find((data) => data.id === activeId);
  } else if (from === CONTAINER_TYPES.CAPTURE) {
    const { captureLists } = store.getState().Capture;
    itemDetails = isMultipleSelectScenario
      ? captureLists?.filter((data) => multiSelectArray.includes(data?.id))
      : captureLists.find((data) => data.id === activeId);
  } else {
    const { boardDetails } = store.getState().BoardGroups;
    itemDetails = isMultipleSelectScenario
      ? boardDetails?.filter((data) => multiSelectArray.includes(data?.id))
      : boardDetails.find((data) => data.id === activeId);
  }

  // Update it thorough the reducer
  if (toJotsPage) {
    store.dispatch({
      type: jotsActions.ADD_ITEMS_TO_JOTS_PAGE,
      payload: {
        pageId,
        itemDetails: Array.isArray(itemDetails) ? itemDetails : [itemDetails],
      },
    });
  } else {
    store.dispatch({
      type: boardActions.ADD_ITEMS_TO_MY_FILES_PAGE,
      payload: {
        pageId,
        itemDetails: Array.isArray(itemDetails) ? itemDetails : [itemDetails],
      },
    });
  }
}

export function alertViewHelper({
  sourceType,
  targetType,
  overNull = false,
  overCategory = false,
}) {
  let alertText = '';
  if (sourceType === targetType || overNull) {
    return;
  }
  const captureContainer = [
    CONTAINER_TYPES.CAPTURE,
    CONTAINER_TYPES.CAPTURE_ITEM,
  ];
  const isCaptureSource = captureContainer.includes(sourceType),
    isCaptureDestination = captureContainer.includes(targetType);
  switch (sourceType) {
    case CONTAINER_TYPES.JOTS_PAGE:
    case CONTAINER_TYPES.JOTS_FREE_SPACE_PAGE:
    case CONTAINER_TYPES.MY_FILES_PAGE:
    case CONTAINER_TYPES.MY_FILES_FREE_SPACE_PAGE: {
      switch (targetType) {
        case CONTAINER_TYPES.CAPTURE:
        case CONTAINER_TYPES.CAPTURE_ITEM:
        case CONTAINER_TYPES.MY_FILES_CATEGORY:
        case CONTAINER_TYPES.INDEX_SECTION_CATEGORY: {
          alertText = 'drag_and_drop_default_handle';
          sourceType = 'Page';
          break;
        }
        default: {
          alertText = '';
        }
      }
      break;
    }
    case CONTAINER_TYPES.FOLDER:
    case CONTAINER_TYPES.MY_FILES_FREE_SPACE_FOLDER: {
      switch (targetType) {
        case CONTAINER_TYPES.JOTS_ITEM:
        case CONTAINER_TYPES.JOTS_PAGE:
        case CONTAINER_TYPES.JOTS_FREE_SPACE_PAGE:
        case CONTAINER_TYPES.JOTS: {
          alertText = 'drag_and_drop_default_handle';
          sourceType = 'Folder';
          targetType = 'Jots';
          break;
        }
        case CONTAINER_TYPES.CAPTURE:
        case CONTAINER_TYPES.CAPTURE_ITEM: {
          alertText = 'drag_and_drop_default_handle';
          sourceType = 'Folder';
          break;
        }
        default: {
          alertText = '';
        }
      }
      break;
    }
    default:
      break;
  }
  if (alertText !== '') {
    if (isCaptureSource) {
      sourceType = 'Capture';
    }
    if (overCategory) {
      targetType = 'Category';
    } else if (isCaptureDestination) {
      targetType = 'Capture';
    }

    alertView({
      alertText,
      data: {
        sourceType: sourceType,
        targetType: targetType,
      },
      type: 'warning',
      delay: 3,
    });
  }
}

export function setDroppedDataFolderId({ overId = null }) {
  store.dispatch({
    type: commonActions.SET_DROPPED_DATA,
    payload: {
      droppedOnOverId: overId,
    },
  });
}

export function updateItemSize({
  containerArray = [],
  activeIds = [],
  overId = null,
  overContainer = null,
}) {
  const activeSize = containerArray.reduce((acc, item) => {
    if (activeIds.includes(item.id)) {
      return acc + (Number(item?.size) || 0);
    }
    return acc;
  }, 0);
  switch (overContainer) {
    case CONTAINER_TYPES.MY_FILES: {
      const { boardDetails } = store.getState().BoardGroups;
      const updatedBoardDetails = boardDetails.map((item) => {
        if (item.id === overId) {
          return {
            ...item,
            size: (item?.size || 0) + activeSize,
          };
        }
        return item;
      });
      store.dispatch({
        type: boardActions.UPDATE_MY_FILES_ITEM_SIZE,
        payload: updatedBoardDetails,
      });
      break;
    }
    case CONTAINER_TYPES.JOTS: {
      const { jotsProjectItems } = store.getState().jotsReducer;
      const updatedJotsProjectItems = jotsProjectItems.map((item) => {
        if (item.id === overId) {
          return {
            ...item,
            size: (item?.size || 0) + activeSize,
          };
        }
        return item;
      });
      store.dispatch({
        type: jotsActions.UPDATE_JOTS_ITEM_SIZE,
        payload: updatedJotsProjectItems,
      });
      break;
    }
    default: {
      // Not In use
    }
  }
}
