import React from 'react';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <div>
          {error.name === 'ChunkLoadError' ? (
            <div>
              This application has been updated, please refresh your browser to
              see the latest content.
            </div>
          ) : (
          <p>Loading failed! Please reload.</p>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
