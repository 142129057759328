import actions from 'redux/folders/actions';
import { store } from 'redux/store';
import activitiesAction from 'redux/activities/actions';
import captureActions from 'redux/capture/actions';
import { addAsyncAction } from 'utility/asyncHelper';
import {
  findActivityData,
  captureItemsToNewFolder,
  convertFolderItemToNewFolder,
  checkIsHomeActivity,
  uploadMedia,
  getFolderDetails,
  Translate,
  getCurrentProjectId,
  isStaticActivity,
  sharedSparksDateConverter,
  updateHistory,
  openFullScreenFolderModal,
  isHomeActivityNode,
  handleNavigation,
  closeShareSparkModal,
} from 'utility/helpers';
import homeActions from 'redux/home/actions';
import folderActions from 'redux/folders/actions';
import commonActions from 'redux/common/actions';
import authActions from 'redux/auth/actions';
import foldersActions from 'redux/folders/actions';
import Compressor from 'compressorjs';
import Resizer from 'react-image-file-resizer';
import sharedSparksActions from 'redux/sharedSparks/actions';
import activitiesActions from 'redux/activities/actions';
import sharedSparkActions from 'redux/sharedSparks/actions';
import React from 'react';
import { screenName } from '../constants';
import { find, isEqual } from 'lodash';
import foldersAction from '../../redux/folders/actions';
import cloudAreaAlertView from 'components/notification/myFilesAlertView';
import jotsAlertView from 'components/notification/jotsAlertView';

export function createNewFolderFromCaptureToBoard(
  newFolderModalData,
  folderData,
) {
  const { payload, result, isCaptureToOtherBoard } = newFolderModalData;
  if (isCaptureToOtherBoard) {
    store.dispatch({
      type: actions.SET_NODES_LIST_LOADER,
      payload: true,
    });
    addAsyncAction(
      actions.CREATE_NODE_FROM_CAPTURE_TO_BOARD,
      {
        ...payload,
        details: {
          ...payload.details,
          name: folderData?.name || '',
          description: folderData?.description || '',
        },
      },
      [payload?.tempId],
    );
    store.dispatch({
      type: activitiesAction.CLOSE_ACTIVITY_MODAL,
    });
    store.dispatch({
      type: actions.CHANGE_SELECTED_ACTIVITY,
      selectedActivity: findActivityData(result?.combine?.draggableId),
    });
    store.dispatch({
      type: captureActions.UPDATE_SELECTED_CAPTURE,
      payload: [],
    });
  } else {
    captureItemsToNewFolder(payload, folderData);
  }
}

export function createNewFolderFromFolderItemToBoard(
  newFolderModalData,
  folderData,
) {
  const { payload, result, isFolderItemToOtherBoard } = newFolderModalData;
  if (isFolderItemToOtherBoard) {
    const { draggableId } = result,
      { nodeId, details, sourceFolderDetails } = payload;
    store.dispatch({
      type: actions.SET_NODES_LIST_LOADER,
      payload: true,
    });
    if (checkIsHomeActivity(sourceFolderDetails?.project_id)) {
      store.dispatch({
        type: homeActions.DELETE_HOME_NODE_ITEMS,
        nodeId: nodeId,
        items: [draggableId],
      });
    }
    store.dispatch({
      type: actions.CREATE_NODE_FROM_ITEM_TO_ANOTHER_BOARD,
      payload: {
        nodeId,
        details: {
          ...details,
          name: folderData?.name || '',
          description: folderData?.description || '',
        },
      },
    });
  } else {
    convertFolderItemToNewFolder(newFolderModalData, folderData);
  }
}

export const onNodeShare = (
  data,
  isHomeActivity,
  sparkConfig,
  sparkProfileImageDetails,
  firstName,
  lastName,
  shareType,
) => {
  if (!data.lock) {
    store.dispatch({
      type: folderActions.SHARE_NODE,
      nodeId: data.node_id,
      projectId: data.project_id,
      isHomeActivity,
    });
  }
  setTimeout(() => {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'folders',
      payload: {
        folderVisibility: false,
        status: 'share',
        currentNodeId: data.node_id,
        isHomeActivity: checkIsHomeActivity(data.project_id),
      },
    });
    const payload = {
      node_id: data.node_id,
      share_as: shareType
        ? shareType
        : sparkConfig.share_as
          ? sparkConfig.share_as
          : 'webpage',
      style: sparkConfig.style,
      name: sparkConfig.title,
      description: sparkConfig.description,
      user_avatar_image_string:
        sparkProfileImageDetails?.user_avatar_image_string?.name,
      user_first_name: sparkConfig?.user_first_name,
      user_last_name: sparkConfig?.user_last_name,
      remove_header_image: sparkConfig?.remove_header_image,
    };
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'share',
      payload: {
        saveVisibility: false,
      },
    });
    if (
      firstName !== sparkConfig?.user_first_name ||
      lastName !== sparkConfig?.user_last_name
    ) {
      addAsyncAction(authActions.ASYNC_UPDATE_USER_DETAILS, {
        payload: {
          first_name: sparkConfig?.user_first_name,
          last_name: sparkConfig?.user_last_name,
        },
      });
    }
    store.dispatch({
      type: folderActions.SHARE_SPARK,
      payload,
      module: checkIsHomeActivity(data.project_id) ? 'homeDetails' : 'folders',
      isHomeActivity: checkIsHomeActivity(data.project_id),
    });
    isHomeActivity && store.dispatch({ type: homeActions.HOME_SHARE_SPARK });
    if (shareType) {
      store.dispatch({
        type: foldersActions.SHARE_TYPE,
        payload: '',
      });
    }
  }, 2000);
  store.dispatch({
    type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
    module: 'folders',
    payload: {
      folderVisibility: true,
      status: 'share',
      currentNodeId: data.node_id,
      isHomeActivity: checkIsHomeActivity(data.project_id),
    },
  });
};

export function uploadFile(
  uploadData,
  imageType,
  nodeId,
  headerAvatar,
  sparkData,
  isHomeActivity,
  sparkConfig,
  sparkProfileImageDetails,
  firstName,
  lastName,
  quality,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  shareType,
) {
  new Compressor(uploadData, {
    quality: quality,
    mimeType: ['image/bmp', 'image/jpg', 'image/jpeg'].includes(
      uploadData?.type,
    )
      ? 'image/jpeg'
      : uploadData?.type,
    async success(compressedFile) {
      Resizer.imageFileResizer(
        compressedFile,
        maxWidth,
        maxHeight,
        compressedFile.type.split('image/')[1],
        100,
        0,
        (outputFile) => {
          uploadMedia(
            {
              file: outputFile,
              name: `SP_${Date.now()}-${outputFile.name}`.replace(/ /gi, '-'),
            },
            (data) => {
              if (imageType === 'avatar') {
                store.dispatch({
                  type: authActions.CHANGE_USER_AVATAR,
                  payload: {
                    new_file_name: data?.key?.split('/')[1].replace(/ /gi, '-'),
                  },
                });
              } else {
                store.dispatch({
                  type: sharedSparksActions.ASYNC_UPDATE_SPARK_HEADER_IMAGE,
                  payload: {
                    fileDetails: {
                      new_file_name: data?.payload?.name,
                    },
                    nodeId,
                  },
                });
              }
              if (headerAvatar) {
                onNodeShare(
                  sparkData,
                  isHomeActivity,
                  sparkConfig,
                  sparkProfileImageDetails,
                  firstName,
                  lastName,
                  shareType,
                );
              }
            },
            (err) => {
              console.error(err);
            },
          );
        },
        'file',
        minWidth,
        minHeight,
      );
    },
    error(err) {
      console.error(err.message);
    },
  });
}

function onLock(lock, count, isHomeActivity, nodeId, projectId) {
  store.dispatch({
    type: isHomeActivity
      ? homeActions.SET_HOME_LOCK_STATUS
      : folderActions.SET_LOCK_STATUS,
    nodeId,
    payload: {
      lock: true,
      noOfTimeShared: count,
    },
  });
  store.dispatch({
    type: activitiesActions.UPDATE_NODES_LISTS,
    nodeId,
    projectId,
    lock: true,
    isFromLock: true,
  });
}

export const updateLockStatus = (
  folderItemsList,
  isHomeActivity,
  archiveStatus,
  isFromSharedSparks,
  setNodeId,
  selectedActivity,
  data,
) => {
  const { nodeId, projectId, isLock, title, isFolderArchived, count } = data,
    { homeActivity, homeFolderItemsList } = store.getState().Home;
  if (
    isFolderArchived &&
    { ...folderItemsList, ...homeFolderItemsList }[nodeId]?.data?.length !== 1
  ) {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'unArchiveFolderModal',
      payload: {
        visibility: true,
        node_id: nodeId,
        project_id: projectId,
        isLock: isLock,
        folderTitle: title,
        isFromLock: true,
        isHomeActivity: isHomeActivity,
      },
    });
  } else {
    if (!archiveStatus) {
      const nodeIdUpdatePromise = new Promise(function (resolve, reject) {
        resolve(setNodeId(nodeId));
      });
      nodeIdUpdatePromise.then(function () {
        if (
          { ...folderItemsList, ...homeFolderItemsList }[nodeId]?.data
            ?.length === 1
        ) {
          store.dispatch({
            type: !isHomeActivity
              ? foldersActions.UPDATE_EMPTY_SHARE_MODAL_VISIBILITY
              : homeActions.UPDATE_HOME_EMPTY_SHARE_MODAL_VISIBILITY,
            payload: true,
          });
        } else {
          const nodeLockUpdatePromise = new Promise(function (resolve, reject) {
            resolve(onLock(true, count, isHomeActivity, nodeId, projectId));
          });
          nodeLockUpdatePromise.then(function () {
            addAsyncAction(folderActions.ASYNC_SET_LOCK_STATUS, {
              nodeId,
              projectId: isHomeActivity
                ? homeActivity.project_id
                : selectedActivity.project_id,
              payload: {
                lock: true,
              },
              activityName: isHomeActivity
                ? homeActivity.name
                : selectedActivity.name,
              folderName: title,
              isFolderUpdate: true,
            });
            const alertObj = {
              alertText: 'success_update_spark_message_web',
              type: 'success',
              delay: 3,
              data: 'Folder',
            };
            if (isHomeActivity) {
              jotsAlertView(alertObj);
            } else {
              cloudAreaAlertView(alertObj);
            }
          });
        }
      });
    } else {
      if (!isFromSharedSparks) {
        store.dispatch({
          type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
          module: 'activityUnArchive',
          payload: {
            activityUnArchiveModalVisible: true,
            activityUnArchiveCurrentNodeId: nodeId,
            activityUnArchiveStatus: !isLock ? 'lock' : 'unlock',
          },
        });
      }
    }
  }
};

export const onShare = (
  nodeId,
  isFromSharedSparks,
  selectedId,
  archiveStatus,
  setNodeId,
) => {
  const folderDetails = getFolderDetails(nodeId),
    { folderItemsList } = store.getState().Folder,
    { allNodesList } = store.getState().Activities,
    { homeActivity, homeFolderItemsList } = store.getState().Home,
    { revisionPermissions } = store.getState().Auth,
    projectId = folderDetails?.project_id,
    isHomeActivity = checkIsHomeActivity(projectId),
    title = folderDetails?.name || `${Translate({ children: 'folder_name' })}`,
    count = folderDetails?.no_of_time_shared,
    isLock = folderDetails?.lock,
    isFolderArchived = folderDetails?.is_folder_archived,
    previewNodeDetails = {
      no_of_time_shared: count,
      node_id: nodeId,
      archived: archiveStatus,
      project_id: isHomeActivity
        ? homeActivity.project_id
        : getCurrentProjectId(),
      beforeShare: true,
      lock: isLock,
      urlValue: folderDetails?.url,
      isPaused: folderDetails?.paused,
    };
  if (
    isFolderArchived &&
    !isFromSharedSparks &&
    { ...folderItemsList, ...homeFolderItemsList }[nodeId]?.data?.length !== 1
  ) {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'unArchiveFolderModal',
      payload: {
        visibility: true,
        node_id: nodeId,
        project_id: projectId,
        isLock,
        folderTitle: title,
        isHomeActivity: isHomeActivity,
        isFromLock: false,
        previewNodeDetails: previewNodeDetails,
      },
    });
  } else {
    if (!isFromSharedSparks && nodeId !== selectedId) setNodeId(nodeId);
    let shareCount = 0,
      currentDocument,
      lockCount = 0;
    allNodesList.forEach((node) => {
      if (node.url) {
        shareCount += 1;
      }
      if (node.node_id === nodeId) {
        currentDocument = node;
      }
      if (node.lock) {
        lockCount += 1;
      }
    });
    if (
      (shareCount < revisionPermissions.share_spark_limit &&
        ((revisionPermissions.create_spark_limit === null
          ? true
          : lockCount < revisionPermissions.create_spark_limit) ||
          (currentDocument && currentDocument.lock))) ||
      (currentDocument && currentDocument.url) ||
      revisionPermissions.share_spark_limit === null
    ) {
      let folderDetails = getFolderDetails(previewNodeDetails?.node_id),
        isHomeActivity = checkIsHomeActivity(folderDetails?.project_id),
        folderItems = folderItemsList?.[folderDetails?.node_id],
        type = foldersActions.UPDATE_EMPTY_SHARE_MODAL_VISIBILITY;
      if (isHomeActivity) {
        folderItems = homeFolderItemsList?.[folderDetails?.node_id];
        type = homeActions.UPDATE_HOME_EMPTY_SHARE_MODAL_VISIBILITY;
      }
      if (folderItems?.data?.length <= 1) {
        store.dispatch({
          type,
          payload: true,
        });
      } else {
        if (isFromSharedSparks) {
          store.dispatch({
            type: sharedSparkActions.SET_SELECTED_ID,
            selectedId: previewNodeDetails?.node_id,
          });
          store.dispatch({
            type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
            module: 'sharedSpark',
            payload: {
              visibility: true,
            },
          });
          setTimeout(() => {
            store.dispatch({
              type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
              module: 'sharedSpark',
              payload: {
                visibility: false,
              },
            });
            store.dispatch({
              type: folderActions.SHARE_SPARK,
              payload: { node_id: previewNodeDetails?.node_id },
              module: 'sharedSpark',
            });
          }, 2000);
        } else {
          store.dispatch({
            type: checkIsHomeActivity(projectId)
              ? homeActions.UPDATE_HOME_PREVIEW_MODAL_VISIBILITY
              : foldersActions.UPDATE_PREVIEW_MODAL_VISIBILITY,
            payload: previewNodeDetails,
            visibility: true,
          });
        }
      }
    } else {
      store.dispatch({
        type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
        module: 'upgradeModal',
        payload: {
          upgradeModalVisible: true,
          upgradeModalMessage: (
            <Translate>
              {!(lockCount < revisionPermissions.create_spark_limit)
                ? 'share_limit'
                : 'share_spark_limit_exceeded'}
            </Translate>
          ),
        },
      });
    }
  }
};

export const showPropertyModel = (
  data,
  archiveStatus,
  setNodeId,
  selectedId,
) => {
  const { nodeId, projectId, isFolderArchived, isLock } = data,
    { selectedActivity, foldersList } = store.getState().Folder,
    { homeFoldersList } = store.getState().Home,
    isHomeActivity = checkIsHomeActivity(projectId);
  if (nodeId !== selectedId) setNodeId(nodeId);
  if (
    !archiveStatus &&
    (checkIsHomeActivity(projectId) || !isStaticActivity(selectedActivity)) &&
    !isFolderArchived
  ) {
    window.analytics && window.analytics.page(
      isLock ? screenName.spark_properties : screenName.folder_properties,
    );
    store.dispatch({
      type: isHomeActivity
        ? homeActions.SHOW_HOME_FOLDER_PROPERTIES_MODAL
        : folderActions.SHOW_FOLDER_PROPERTIES_MODAL,
      payload: find(isHomeActivity ? homeFoldersList : foldersList, [
        'node_id',
        nodeId,
      ]),
      visibility: true,
    });
  }
};

export function showFolderSpark(data) {
  const { sharedSparkDocumentView } = store.getState().Auth,
    { sortSharedSparksBy } = store.getState().SharedSparks;
  if (data.shared_at) {
    let expand = data.archived
        ? 'archived'
        : sharedSparksDateConverter(data.shared_at),
      payload = {
        shared_spark_document_view: {
          ...sharedSparkDocumentView,
        },
      };
    if (
      sharedSparkDocumentView[sortSharedSparksBy].list_expand.length &&
      !sharedSparkDocumentView[sortSharedSparksBy].list_expand.includes(expand)
    ) {
      payload = {
        shared_spark_document_view: {
          ...payload.shared_spark_document_view,
          [sortSharedSparksBy]: {
            ...payload.shared_spark_document_view[sortSharedSparksBy],
            list_expand: [
              ...payload.shared_spark_document_view[sortSharedSparksBy]
                .list_expand,
              expand,
            ],
          },
        },
      };
    } else if (
      !sharedSparkDocumentView[sortSharedSparksBy].list_expand.length
    ) {
      payload = {
        shared_spark_document_view: {
          ...payload.shared_spark_document_view,
          [sortSharedSparksBy]: {
            chronological: 'expand',
            list_expand: [expand],
          },
        },
      };
    }
    store.dispatch({
      type: authActions.SET_SHARED_SPARK_EXPAND_DETAILS,
      payload,
    });
    store.dispatch({
      type: sharedSparksActions.SET_EXPAND_SHARED_SPARK_DETAILS,
      payload:
        payload.shared_spark_document_view[sortSharedSparksBy].list_expand,
    });
    updateHistory(`app/shared?id=${data.node_id}`);
    openFullScreenFolderModal(false);
  }
}

export function moveIndividualFolder(nodeId, projectId) {
  store.dispatch({
    type: commonActions.SET_MOVE_MODAL_VISIBILITY,
    moveModalVisibility: true,
    selectedMoveFolderNodeId: nodeId,
    selectedMoveFolderProjectId: projectId,
  });
}

export function moveFolder() {
  const {
      selectedMoveFolderNodeId,
      selectedMoveFolderProjectId,
      selectedMenuProjectId,
    } = store.getState().commonReducer,
    { homeFolderItemsList } = store.getState().Home,
    { folderItemsList } = store.getState().Folder;
  if (!checkIsHomeActivity(selectedMenuProjectId)) {
    store.dispatch({
      type: actions.CHANGE_SELECTED_ACTIVITY,
      selectedActivity: findActivityData(selectedMenuProjectId),
    });
  }
  store.dispatch({
    type: checkIsHomeActivity(selectedMenuProjectId)
      ? homeActions.MOVE_NODE_TO_HOME_ACTIVITY_SUCCESS
      : foldersAction.MOVE_NODE_TO_ACTIVITY_SUCCESS,
    payload: {
      projectId: selectedMenuProjectId,
      nodeId: selectedMoveFolderNodeId,
      sourceFolderDetails: getFolderDetails(selectedMoveFolderNodeId),
      sourceFolderItemsList: checkIsHomeActivity(selectedMoveFolderProjectId)
        ? homeFolderItemsList
        : folderItemsList,
    },
  });
}

export function asyncFolderCreation(
  tempId,
  tempNodeData,
  tempActiveId = false,
) {
  const { createModuleType } = store.getState().commonReducer;
  if (createModuleType === 'document') {
    store.dispatch({
      type: actions.ASYNC_FOLDER_CREATION,
      tempId,
      tempNodeData,
      tempActiveId,
    });
  }
}

export function setBarView(nodeId) {
  const { selectedActivity } = store.getState().Folder,
    { homeActivity } = store.getState().Home,
    folderDetails = getFolderDetails(nodeId),
    { project_id: projectId } = folderDetails,
    isHomeActivity = isHomeActivityNode(nodeId);
  store.dispatch({
    type: foldersActions.SET_BAR_VIEW,
    payload: nodeId,
  });
  let activityDetails = isHomeActivity ? homeActivity : selectedActivity;
  if (
    !checkIsHomeActivity(projectId) &&
    (isStaticActivity(selectedActivity?.type) || selectedActivity?.archived)
  ) {
    return;
  }
  store.dispatch({
    type: isHomeActivity
      ? homeActions.UPDATE_HOME_FOLDER_PROPERTIES_SUCCESS
      : folderActions.UPDATE_FOLDER_PROPERTIES_SUCCESS,
    payload: {
      ...folderDetails,
      bar_view: !folderDetails?.bar_view,
    },
    visibility: false,
    changeViewAction: true,
  });
  addAsyncAction(folderActions.UPDATE_FOLDER_PROPERTIES, {
    folderPropertiesDetails: {
      bar_view: !folderDetails?.bar_view,
    },
    nodeId: folderDetails?.node_id,
    projectId: folderDetails?.project_id,
    changeViewAction: true,
  });
  if (!isHomeActivity) {
    store.dispatch({
      type: folderActions.UPDATE_SELECTED_ACTIVITY_DETAILS,
      selectedActivity: {
        ...selectedActivity,
        document_view: {
          ...selectedActivity.document_view,
          chronological: '',
        },
      },
    });
  } else {
    store.dispatch({
      type: homeActions.UPDATE_HOME_ACTIVITY_DETAILS,
      homeActivity: {
        ...homeActivity,
        document_view: {
          ...homeActivity.document_view,
          chronological: '',
        },
      },
    });
  }
  addAsyncAction(activitiesActions.UPDATE_DOCUMENT_VIEW, {
    document_view: {
      ...activityDetails.document_view,
      chronological: '',
    },
    projectId: folderDetails.project_id,
    activityName: activityDetails?.name,
    isActivityUpdate: true,
  });
}

export function folderExpandCollapseView(nodeId) {
  store.dispatch({
    type: commonActions.DOCUMENT_EXPAND_COLLAPSE_VIEW,
    payload: nodeId,
  })
}

export function reShareSparkModalVisibility(nodeId) {
  store.dispatch({
    type: foldersActions.RESHARE_SPARK_ID,
    reshareSparkId: nodeId,
  });
}

export function onShareSpark(nodeId, shareType) {
  const folderDetails = getFolderDetails(nodeId),
    previewNodeDetails = {
      no_of_time_shared: folderDetails?.no_of_time_shared,
      node_id: folderDetails.node_id,
      archived: folderDetails?.archived,
      project_id: folderDetails.project_id,
      beforeShare: true,
      lock: folderDetails?.lock,
      urlValue: folderDetails?.url,
      isPaused: folderDetails?.paused,
    },
    isHomeActivity = isHomeActivityNode(nodeId),
    { sparkConfig } = store.getState().Folder,
    {
      sparkHeaderImageDetails,
      sparkProfileImageDetails,
    } = store.getState().commonReducer,
    { firstName, lastName } = store.getState().Auth;
  if (sparkConfig?.spark_image && !sparkConfig?.user_avatar_image_string) {
    uploadFile(
      sparkConfig?.spark_image,
      'headerImage',
      sparkHeaderImageDetails?.nodeId,
      true,
      previewNodeDetails,
      isHomeActivity,
      sparkConfig,
      sparkProfileImageDetails,
      firstName,
      lastName,
      0.95,
      1500,
      844,
      400,
      225,
      shareType,
    );
  }
  if (!sparkConfig?.spark_image && sparkConfig?.user_avatar_image_string) {
    uploadFile(
      sparkConfig?.user_avatar_image_string,
      'avatar',
      '',
      true,
      previewNodeDetails,
      isHomeActivity,
      sparkConfig,
      sparkProfileImageDetails,
      firstName,
      lastName,
      0.8,
      200,
      200,
      200,
      200,
      shareType,
    );
  }
  if (!sparkConfig?.spark_image && !sparkConfig?.user_avatar_image_string) {
    let initialValue = { ...window.initialSparkConfig };
    delete initialValue.share_as;
    let tempSparkConfig = { ...sparkConfig };
    delete tempSparkConfig.share_as;
    if (!isEqual(initialValue, tempSparkConfig) || shareType) {
      onNodeShare(
        previewNodeDetails,
        isHomeActivity,
        sparkConfig,
        sparkProfileImageDetails,
        firstName,
        lastName,
        shareType,
      );
    }
  }
  if (sparkConfig?.spark_image && sparkConfig?.user_avatar_image_string) {
    const uploadData = new Promise((resolve) => {
      resolve(
        uploadFile(
          sparkConfig?.spark_image,
          'headerImage',
          sparkHeaderImageDetails?.nodeId,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          0.95,
          1500,
          844,
          400,
          225,
          shareType,
        ),
      );
    });
    uploadData
      .then(() => {
        uploadFile(
          sparkConfig?.user_avatar_image_string,
          'avatar',
          '',
          'both',
          previewNodeDetails,
          isHomeActivity,
          sparkConfig,
          sparkProfileImageDetails,
          firstName,
          lastName,
          0.8,
          200,
          200,
          200,
          200,
          shareType,
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

export function emptySparkModal(nodeId) {
  const isHomeActivity = isHomeActivityNode(nodeId);
  store.dispatch({
    type: isHomeActivity
      ? homeActions.UPDATE_HOME_EMPTY_SHARE_MODAL_VISIBILITY
      : foldersActions.UPDATE_EMPTY_SHARE_MODAL_VISIBILITY,
    payload: true,
  });
}

export function saveSparkStyle(nodeId) {
  const {
      sparkConfig,
      isOpenFullScreenFolderModal,
      reshareSparkId,
    } = store.getState().Folder,
    { isSharedFromSpark } = store.getState().commonReducer,
    payload = {
      style: sparkConfig.style,
      name: sparkConfig.title,
      description: sparkConfig.description,
    };
  if (isOpenFullScreenFolderModal && isSharedFromSpark) {
    store.dispatch({
      type: folderActions.OPEN_FULL_SCREEN_FOLDER_MODAL,
      payload: false,
    });
    handleNavigation();
  }
  store.dispatch({
    type: actions.SAVE_SPARK_PROPERTIES,
    payload,
    nodeId: nodeId,
    projectId: getFolderDetails(nodeId)?.project_id,
  });
  if (reshareSparkId) {
    reShareSparkModalVisibility();
  }
}

export function dontSave() {
  const { reshareSparkId } = store.getState().Folder;
  if (reshareSparkId) {
    reShareSparkModalVisibility();
  } else {
    closeShareSparkModal();
  }
}
