const actions = {
  GET_PLANS_LIST: 'GET_PLANS_LIST',
  GET_PLANS_LIST_SUCCESS: 'GET_PLANS_LIST_SUCCESS',
  GET_PLANS_LIST_FAILURE: 'GET_PLANS_LIST_FAILURE',
  SELECT_PLAN: 'SELECT_PLAN',
  EDIT_PLAN: 'EDIT_PLAN',
  EDIT_PLAN_SUCCESS: 'EDIT_PLAN_SUCCESS',
  EDIT_PLAN_FAILURE: 'EDIT_PLAN_FAILURE',
  CANCEL_PLAN: 'CANCEL_PLAN',
  GET_PAY_NOW_URL: 'GET_PAY_NOW_URL',
  GET_COLLECT_NOW_URL: 'GET_COLLECT_NOW_URL',
  GET_PLAN_DETAIL: 'GET_PLAN_DETAIL',
  GET_PLAN_DETAIL_SUCCESS: 'GET_PLAN_DETAIL_SUCCESS',
  GET_PLAN_DETAIL_FAILURE: 'GET_PLAN_DETAIL_FAILURE',
  CANCEL_SCHEDULED_CHANGES: 'CANCEL_SCHEDULED_CHANGES',
  GET_CLIENT_PORTAL_URL: 'GET_CLIENT_PORTAL_URL',
  GET_CLIENT_PORTAL_URL_SUCCESS: 'GET_CLIENT_PORTAL_URL_SUCCESS',
  GET_CANCEL_SUBSCRIPTION_URL: 'GET_CANCEL_SUBSCRIPTION_URL',
  GET_CANCEL_SUBSCRIPTION_URL_SUCCESS: 'GET_CANCEL_SUBSCRIPTION_URL_SUCCESS',
  GET_CANCEL_SUBSCRIPTION_URL_FAILURE: 'GET_CANCEL_SUBSCRIPTION_URL_FAILURE',
  GET_REMOVE_CANCELLATION_URL: 'GET_REMOVE_CANCELLATION_URL',
  GET_REMOVE_CANCELLATION_URL_SUCCESS: 'GET_REMOVE_CANCELLATION_URL_SUCCESS',
  GET_REMOVE_CANCELLATION_URL_FAILURE: 'GET_REMOVE_CANCELLATION_URL_FAILURE',
};

export default actions;
