const actions = {
  GET_ACTIVITIES_LISTS: 'GET_ACTIVITIES_LISTS',
  GET_ACTIVITIES_LISTS_SUCCESS: 'GET_ACTIVITIES_LISTS_SUCCESS',
  GET_ACTIVITIES_LISTS_FAILURE: 'GET_ACTIVITIES_LISTS_FAILURE',
  VIEW_ACTIVITY: 'VIEW_ACTIVITY',
  VIEW_ACTIVITY_SUCCESS: 'VIEW_ACTIVITY_SUCCESS',
  VIEW_ACTIVITY_FAILURE: 'VIEW_ACTIVITY_FAILURE',
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
  UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
  UPDATE_ACTIVITY_FAILURE: 'UPDATE_ACTIVITY_FAILURE',
  UPDATE_ACTIVITY_AVATAR: 'UPDATE_ACTIVITY_AVATAR',
  UPDATE_ACTIVITY_AVATAR_SUCCESS: 'UPDATE_ACTIVITY_AVATAR_SUCCESS',
  UPDATE_ACTIVITY_AVATAR_FAILURE: 'UPDATE_ACTIVITY_AVATAR_FAILURE',
  GET_ACTIVE_ACTIVITIES: 'GET_ACTIVE_ACTIVITIES',
  GET_ACTIVE_ACTIVITIES_SUCCESS: 'GET_ACTIVE_ACTIVITIES_SUCCESS',
  GET_ACTIVE_ACTIVITIES_FAILURE: 'GET_ACTIVE_ACTIVITIES_FAILURE',
  GET_ARCHIVED_ACTIVITIES: 'GET_ARCHIVED_ACTIVITIES',
  GET_ARCHIVED_ACTIVITIES_SUCCESS: 'GET_ARCHIVED_ACTIVITIES_SUCCESS',
  GET_ARCHIVED_ACTIVITIES_FAILURE: 'GET_ARCHIVED_ACTIVITIES_FAILURE',
  ARCHIVE_ACTIVITIES: 'ARCHIVE_ACTIVITIES',
  ARCHIVE_ACTIVITIES_SUCCESS: 'ARCHIVE_ACTIVITIES_SUCCESS',
  ARCHIVE_ACTIVITIES_FAILURE: 'ARCHIVE_ACTIVITIES_FAILURE',
  UNARCHIVE_ACTIVITIES: 'UNARCHIVE_ACTIVITIES',
  UNARCHIVE_ACTIVITIES_SUCCESS: 'UNARCHIVE_ACTIVITIES_SUCCESS',
  UNARCHIVE_ACTIVITIES_FAILURE: 'UNARCHIVE_ACTIVITIES_FAILURE',
  REORDER_ACTIVE_ACTIVITIES: 'REORDER_ACTIVE_ACTIVITIES',
  REORDER_ACTIVE_ACTIVITIES_FAILURE: 'REORDER_ACTIVE_ACTIVITIES_FAILURE',
  REORDER_ARCHIVED_ACTIVITIES: 'REORDER_ARCHIVED_ACTIVITIES',
  REORDER_ARCHIVED_ACTIVITIES_FAILURE: 'REORDER_ARCHIVED_ACTIVITIES_FAILURE',
  UPDATE_NODE: 'UPDATE_NODE',
  UPDATE_NODE_SUCCESS: 'UPDATE_NODE_SUCCESS',
  UPDATE_NODE_FAILURE: 'UPDATE_NODE_FAILURE',
  UPDATE_NODE_AVATAR: 'UPDATE_NODE_AVATAR',
  UPDATE_NODE_AVATAR_SUCCESS: 'UPDATE_NODE_AVATAR_SUCCESS',
  UPDATE_NODE_AVATAR_FAILURE: 'UPDATE_NODE_AVATAR_FAILURE',
  REMOVE_NODE_AVATAR: 'REMOVE_NODE_AVATAR',
  REMOVE_NODE_AVATAR_SUCCESS: 'REMOVE_NODE_AVATAR_SUCCESS',
  REMOVE_NODE_AVATAR_FAILURE: 'REMOVE_NODE_AVATAR_FAILURE',
  REORDER_NODE: 'REORDER_NODE',
  REORDER_NODE_SUCCESS: 'REORDER_NODE_SUCCESS',
  REORDER_NODE_FAILURE: 'REORDER_NODE_FAILURE',
  CREATE_SPARK: 'CREATE_SPARK',
  CREATE_SPARK_SUCCESS: 'CREATE_SPARK_SUCCESS',
  CREATE_SPARK_FAILURE: 'CREATE_SPARK_FAILURE',
  SPARK_A_NODE: 'SPARK_A_NODE',
  SPARK_A_NODE_SUCCESS: 'SPARK_A_NODE_SUCCESS',
  SPARK_A_NODE_FAILURE: 'SPARK_A_NODE_FAILURE',
  UNSPARK_A_NODE: 'UNSPARK_A_NODE',
  UNSPARK_A_NODE_SUCCESS: 'UNSPARK_A_NODE_SUCCESS',
  UNSPARK_A_NODE_FAILURE: 'UNSPARK_A_NODE_FAILURE',
  SPARK_PRESENTATION: 'SPARK_PRESENTATION',
  SPARK_PRESENTATION_SUCCESS: 'SPARK_PRESENTATION_SUCCESS',
  SPARK_PRESENTATION_FAILURE: 'SPARK_PRESENTATION_FAILURE',
  DELETE_ACTIVITY_MODAL_VISIBILITY: 'DELETE_ACTIVITY_MODAL_VISIBILITY',
  DELETE_ACTIVITY: 'DELETE_ACTIVITY',
  DELETE_ACTIVITY_SUCCESS: 'DELETE_ACTIVITY_SUCCESS',
  DELETE_ACTIVITY_FAILURE: 'DELETE_ACTIVITY_FAILURE',
  ADD_NEW_ACTIVITY_MODAL: 'ADD_NEW_ACTIVITY_MODAL',
  CLOSE_ACTIVITY_MODAL: 'CLOSE_ACTIVITY_MODAL',
  UPDATE_ACTIVITY_AVATAR_LOADER: 'UPDATE_ACTIVITY_AVATAR_LOADER',
  UPDATE_ACTIVITY_AVATAR_DATA: 'UPDATE_ACTIVITY_AVATAR_DATA',
  GET_ALL_NODES_LIST: 'GET_ALL_NODES_LIST',
  GET_ALL_NODES_LIST_SUCCESS: 'GET_ALL_NODES_LIST_SUCCESS',
  GET_ALL_NODES_LIST_FAILURE: 'GET_ALL_NODES_LIST_FAILURE',
  GET_ALL_NODES_LIST_WITHOUT_ITEMS: 'GET_ALL_NODES_LIST_WITHOUT_ITEMS',
  GET_ALL_NODES_LIST_WITHOUT_ITEMS_SUCCESS:
    'GET_ALL_NODES_LIST_WITHOUT_ITEMS_SUCCESS',
  GET_ALL_NODES_LIST_WITHOUT_ITEMS_FAILURE:
    'GET_ALL_NODES_LIST_WITHOUT_ITEMS_FAILURE',
  UPDATE_NODES_LISTS: 'UPDATE_NODES_LISTS',
  ASYNC_UPDATE_NODES_LISTS: 'ASYNC_UPDATE_NODES_LISTS',
  UPDATE_ACTIVITY_MODAL: 'UPDATE_ACTIVITY_MODAL',
  SET_ACTIVITY_INFO_MESSAGE_ALERT_SHOW: 'SET_ACTIVITY_INFO_MESSAGE_ALERT_SHOW',
  SET_ACTIVITY_INFO_MESSAGE_ALERT_DISPLAY_OFF:
    'SET_ACTIVITY_INFO_MESSAGE_ALERT_DISPLAY_OFF',
  ASYNC_DELETE_ACTIVITY: 'ASYNC_DELETE_ACTIVITY',
  UPDATE_ACTIVITIES_LISTS_FETCH_TIME: 'UPDATE_ACTIVITIES_LISTS_FETCH_TIME',
  UPDATE_ACTIVE_ACTIVITIES_SUCCESS: 'UPDATE_ACTIVE_ACTIVITIES_SUCCESS',
  UPDATE_ARCHIVED_ACTIVITIES_SUCCESS: 'UPDATE_ARCHIVED_ACTIVITIES_SUCCESS',
  UPDATE_ACTIVITY_DOCUMENT_VIEW: 'UPDATE_ACTIVITY_DOCUMENT_VIEW',
  UPDATE_DOCUMENT_VIEW: 'UPDATE_DOCUMENT_VIEW',
  UPDATE_DELETED_ACTIVITY: 'UPDATE_DELETED_ACTIVITY',
  GET_ALL_PROJECT_LIST_WITH_SIZE: 'GET_ALL_PROJECT_LIST_WITH_SIZE',
  GET_ALL_PROJECT_LIST_WITH_SIZE_SUCCESS: 'GET_ALL_PROJECT_LIST_WITH_SIZE_SUCCESS',
  UPDATE_DISABLE_ACTIVITY: 'UPDATE_DISABLE_ACTIVITY'
};

export default actions;
