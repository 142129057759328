const actions = {
  TOUR_VISIBILITY: 'TOUR_VISIBILITY',
  UPDATE_MODAL_SCREEN: 'UPDATE_MODAL_SCREEN',
  TOUR_MODAL_VISIBILITY: 'TOUR_MODAL_VISIBILITY',
  CREATE_GROUPS: 'CREATE_GROUPS',
  END_TOUR: 'END_TOUR',
  QUICK_TOUR: 'QUICK_TOUR',
};

export default actions;
