import React from 'react';
import { ReactComponent as Camera } from 'images/svgs/camera.svg';
import { ReactComponent as Video } from 'images/svgs/video.svg';
import { ReactComponent as Marker } from 'images/svgs/marker.svg';
import { ReactComponent as Mic } from 'images/svgs/mic.svg';
import { ReactComponent as Edit } from 'images/svgs/edit.svg';
import { ReactComponent as Url } from 'images/svgs/url.svg';
import { ReactComponent as User } from 'images/svgs/user.svg';
import { ReactComponent as FileFormat } from 'images/svgs/fileFormat.svg';
import { Translate } from 'utility/helpers';
import { store } from '../../redux/store';
import boardGroupsActions from '../../redux/boardGroups/actions';

export function getIcon(type) {
  switch (type) {
    case 'image': {
      return <Camera alt={'camera'} />;
    }
    case 'video': {
      return <Video alt={'video'} />;
    }
    case 'contact': {
      return <User alt={'contact'} />;
    }
    case 'location': {
      return <Marker alt={'location'} />;
    }
    case 'url': {
      return <Url alt={'url'} />;
    }
    case 'audio': {
      return <Mic alt={'audio'} />;
    }
    case 'note': {
      return <Edit alt={'note'} />;
    }
    case 'unknown': {
      return <FileFormat alt={'file'} />;
    }
    default: {
      return <></>;
    }
  }
}

export function getHierarchyLevel(type, from) {
  switch (type) {
    case 'boards_group':
      return Translate({ children: 'board_groups' });
    case 'boards':
      return Translate({ children: 'activities' });
    default:
      if (from === 'fromArchive') {
        if (type?.length > 8) {
          type = `${type.substring(0, 8)}...`;
        }
      }
      return type;
  }
}

export function updateFolderFilesCount(nodeId, count = 1) {
  store.dispatch({
    type: boardGroupsActions.UPDATE_FILES_COUNT,
    nodeId,
    count,
  });
}
export const indexSectionCreate = (list, key, payload) =>
  list.map((node) => {
    if (node.id === key) {
      return {
        ...node,
        folders: [...node.folders, payload],
      };
    }
    if (node.folders) {
      return {
        ...node,
        folders: indexSectionCreate(node.folders, key, payload),
      };
    }
    return node;
  });

export const indexSectionUpdate = (list, id, payload) =>
  list.map((node) => {
    if (node.id === id) {
      return {
        ...node,
        ...payload,
      };
    }
    if (node.folders) {
      return {
        ...node,
        folders: indexSectionUpdate(node.folders, id, payload),
      };
    }
    return node;
  });

export const indexSectionDelete = (list, ids) => {
  const idsArray = Array.isArray(ids) ? ids : [ids];

  return list
    .filter((node) => !idsArray.includes(node.id))
    .map((node) => {
      if (node.folders) {
        return {
          ...node,
          folders: indexSectionDelete(node.folders, idsArray),
        };
      }
      return node;
    });
};
