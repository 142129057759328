const actions = {
  GET_NODES_LIST: 'GET_NODES_LIST',
  GET_NODES_LIST_SUCCESS: 'GET_NODES_LIST_SUCCESS',
  GET_NODES_LIST_FAILURE: 'GET_NODES_LIST_FAILURE',
  UPDATE_FOLDER: 'UPDATE_FOLDER',
  ASYNC_UPDATE_FOLDER: 'ASYNC_UPDATE_FOLDER',
  UPDATE_FOLDER_SUCCESS: 'UPDATE_FOLDER_SUCCESS',
  UPDATE_FOLDER_FAILURE: 'UPDATE_FOLDER_FAILURE',
  CHANGE_SELECTED_ACTIVITY: 'CHANGE_SELECTED_ACTIVITY',
  GET_FOLDER_ITEMS_LIST: 'GET_FOLDER_ITEMS_LIST',
  GET_FOLDER_ITEMS_LIST_SUCCESS: 'GET_FOLDER_ITEMS_LIST_SUCCESS',
  GET_FOLDER_ITEMS_LIST_FAILURE: 'GET_FOLDER_ITEMS_LIST_FAILURE',
  REORDER_NODE_ITEM: 'REORDER_NODE_ITEM',
  REORDER_NODE_ITEM_SUCCESS: 'REORDER_NODE_ITEM_SUCCESS',
  REORDER_NODE_ITEM_FAILURE: 'REORDER_NODE_ITEM_FAILURE',
  MOVE_NODE_ITEM: 'MOVE_NODE_ITEM',
  MOVE_NODE_ITEM_SUCCESS: 'MOVE_NODE_ITEM_SUCCESS',
  MOVE_NODE_ITEM_FAILURE: 'MOVE_NODE_ITEM_FAILURE',
  MOVE_NODE_TO_ACTIVITY: 'MOVE_NODE_TO_ACTIVITY',
  MOVE_NODE_TO_ACTIVITY_SUCCESS: 'MOVE_NODE_TO_ACTIVITY_SUCCESS',
  MOVE_NODE_TO_ACTIVITY_FAILURE: 'MOVE_NODE_TO_ACTIVITY_FAILURE',
  SHOW_FOLDER_PROPERTIES_MODAL: 'SHOW_FOLDER_PROPERTIES_MODAL',
  UPDATE_FOLDER_PROPERTIES: 'UPDATE_FOLDER_PROPERTIES',
  UPDATE_FOLDER_PROPERTIES_SUCCESS: 'UPDATE_FOLDER_PROPERTIES_SUCCESS',
  UPDATE_FOLDER_PROPERTIES_FAILURE: 'UPDATE_FOLDER_PROPERTIES_FAILURE',
  DUPLICATE_NODE: 'DUPLICATE_NODE',
  DUPLICATE_SPARK_SUCCESS: 'DUPLICATE_SPARK_SUCCESS',
  DUPLICATE_SUCCESS: 'DUPLICATE_SUCCESS',
  DUPLICATE_SPARK: 'DUPLICATE_SPARK',
  DELETE_NODE: 'DELETE_NODE',
  DELETE_NODE_SUCCESS: 'DELETE_NODE_SUCCESS',
  DELETE_NODE_FAILURE: 'DELETE_NODE_FAILURE',
  SHARE_NODE: 'SHARE_NODE',
  SHARE_NODE_SUCCESS: 'SHARE_NODE_SUCCESS',
  SHARE_SPARK: 'SHARE_SPARK',
  SHARE_SPARK_SUCCESS: 'SHARE_SPARK_SUCCESS',
  UPDATE_PREVIEW_MODAL_VISIBILITY: 'UPDATE_PREVIEW_MODAL_VISIBILITY',
  ADD_ITEM_FROM_CAPTURE: 'ADD_ITEM_FROM_CAPTURE',
  ADD_ITEM_FROM_CAPTURE_SUCCESS: 'ADD_ITEM_FROM_CAPTURE_SUCCESS',
  ADD_ITEM_FROM_CAPTURE_FAILURE: 'ADD_ITEM_FROM_CAPTURE_FAILURE',
  CONVERTING_ITEM_TO_NODE: 'CONVERTING_ITEM_TO_NODE',
  CONVERTING_ITEM_TO_NODE_SUCCESS: 'CONVERTING_ITEM_TO_NODE_SUCCESS',
  CONVERTING_ITEM_TO_NODE_FAILURE: 'CONVERTING_ITEM_TO_NODE_FAILURE',
  CREATE_NODE_FROM_CAPTURE: 'CREATE_NODE_FROM_CAPTURE',
  CREATE_NODE_FROM_CAPTURE_SUCCESS: 'CREATE_NODE_FROM_CAPTURE_SUCCESS',
  CREATE_NODE_FROM_CAPTURE_FAILURE: 'CREATE_NODE_FROM_CAPTURE_FAILURE',
  SET_SORT_BY_VALUE_FOLDERS: 'SET_SORT_BY_VALUE_FOLDERS',
  SET_EXPAND_FOLDER_DETAILS: 'SET_EXPAND_FOLDER_DETAILS',
  DUPLICATE_NODE_ITEMS: 'DUPLICATE_NODE_ITEMS',
  UPDATE_NODE_ITEMS_DATA: 'UPDATE_NODE_ITEMS_DATA',
  DELETE_NODE_ITEMS: 'DELETE_NODE_ITEMS',
  DELETE_NODE_ITEMS_SUCCESS: 'DELETE_NODE_ITEMS_SUCCESS',
  DELETE_NODE_ITEMS_FAILURE: 'DELETE_NODE_ITEMS_FAILURE',
  SHOW_ITEM_PROPERTIES_MODAL: 'SHOW_ITEM_PROPERTIES_MODAL',
  EDIT_MODAL_VISIBLE: 'EDIT_MODAL_VISIBLE',
  CHANGE_NODE_AVATAR_SUCCESS: 'CHANGE_NODE_AVATAR_SUCCESS',
  UPDATE_ITEM_PROPERTIES: 'UPDATE_ITEM_PROPERTIES',
  UPDATE_ITEM_PROPERTIES_SUCCESS: 'UPDATE_ITEM_PROPERTIES_SUCCESS',
  UPDATE_NOTE_ITEM: 'UPDATE_NOTE_ITEM',
  UPDATE_NOTE_ITEM_SUCCESS: 'UPDATE_NOTE_ITEM_SUCCESS',
  UPDATE_LOCATION_ITEM: 'UPDATE_LOCATION_ITEM',
  UPDATE_LOCATION_ITEM_SUCCESS: 'UPDATE_LOCATION_ITEM_SUCCESS',
  UPDATE_CONTACT_ITEM: 'UPDATE_CONTACT_ITEM',
  UPDATE_CONTACT_ITEM_SUCCESS: 'UPDATE_CONTACT_ITEM_SUCCESS',
  UPDATE_LINK_ITEM: 'UPDATE_LINK_ITEM',
  UPDATE_LINK_ITEM_SUCCESS: 'UPDATE_LINK_ITEM_SUCCESS',
  UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
  UPDATE_FOLDER_ITEM_SUCCESS: 'UPDATE_FOLDER_ITEM_SUCCESS',
  UPDATE_DELETE_NODE_MODAL_VISIBILITY: 'UPDATE_DELETE_NODE_MODAL_VISIBILITY',
  UPDATE_DELETE_NODE_ITEMS_MODAL_VISIBILITY:
    'UPDATE_DELETE_NODE_ITEMS_MODAL_VISIBILITY',
  UPDATE_SHARE_MODAL_VISIBILITY: 'UPDATE_SHARE_MODAL_VISIBILITY',
  UPDATE_NODE_AVATAR_DATA: 'UPDATE_NODE_AVATAR_DATA',
  GOTO_SPARK_INFO_MODAL_VISIBLE: 'GOTO_SPARK_INFO_MODAL_VISIBLE',
  UPDATE_FOLDER_PROPERTIES_WITH_ACTIVITY:
    'UPDATE_FOLDER_PROPERTIES_WITH_ACTIVITY',
  UPDATE_PHOTO: 'UPDATE_PHOTO',
  UPDATE_VIDEO: 'UPDATE_VIDEO',
  UPDATE_AUDIO: 'UPDATE_AUDIO',
  UPDATE_CAPTION: 'UPDATE_CAPTION',
  UPDATE_CAPTION_FAILURE: 'UPDATE_CAPTION_FAILURE',
  UPDATE_CAPTION_SUCCESS: 'UPDATE_CAPTION_SUCCESS',
  UPDATE_RENDER_VARIABLE: 'UPDATE_RENDER_VARIABLE',
  ROTATE_IMAGE: 'ROTATE_IMAGE',
  CHANGE_NODE_AVATAR_FAILURE: 'CHANGE_NODE_AVATAR_FAILURE',
  UPDATE_NODE_AVATAR_LOADER: 'UPDATE_NODE_AVATAR_LOADER',
  SHOW_DESCRIPTION_MODAL: 'SHOW_DESCRIPTION_MODAL',
  SET_SELECTED_NODE_ID: 'SET_SELECTED_NODE_ID',
  HIDE_TARGET_ZONE_ID: 'HIDE_TARGET_ZONE_ID',
  SET_COMPONENT_IS_DRAGGING: 'SET_COMPONENT_IS_DRAGGING',
  CREATE_QUICK_SPARK_FOLDER: 'CREATE_QUICK_SPARK_FOLDER',
  ROTATE_IMAGE_SUCCESS: 'ROTATE_IMAGE_SUCCESS',
  ROTATE_IMAGE_FAILURE: 'ROTATE_IMAGE_FAILURE',
  REMOVE_FOLDER_ON_TOP: 'REMOVE_FOLDER_ON_TOP',
  DISABLE_DRAG_IMAGE: 'DISABLE_DRAG_IMAGE',
  SET_SHOULD_REORDER: 'SET_SHOULD_REORDER',
  SET_DESTINATION_ID: 'SET_DESTINATION_ID',
  OPEN_FULL_SCREEN_FOLDER_MODAL: 'OPEN_FULL_SCREEN_FOLDER_MODAL',
  SET_FULL_SCREEN_FOLDER_DETAILS: 'SET_FULL_SCREEN_FOLDER_DETAILS',
  SET_LOCK_STATUS: 'SET_LOCK_STATUS',
  DISABLE_COMBINE_ENABLED: 'DISABLE_COMBINE_ENABLED',
  SET_DOCUMENT_VIEW_TYPE: 'SET_DOCUMENT_VIEW_TYPE',
  UPDATE_ACTIVITY_PROPERTIES: 'UPDATE_ACTIVITY_PROPERTIES',
  UPDATE_SHOW_NODE_IN_DOM: 'UPDATE_SHOW_NODE_IN_DOM',
  REORDER_NODE: 'REORDER_NODE',
  REORDER_NODE_FAILURE: 'REORDER_NODE_FAILURE',
  SET_DRAGGABLE_SCALE: 'SET_DRAGGABLE_SCALE',
  SET_DRAG_STATUS: 'SET_DRAG_STATUS',
  UPDATE_FOLDER_DETAILS: 'UPDATE_FOLDER_DETAILS',
  SET_DOCUMENT_VIEW: 'SET_DOCUMENT_VIEW',
  UPDATE_DOCUMENT_VIEW: 'UPDATE_DOCUMENT_VIEW',
  UPDATE_DOCUMENT_VIEW_SUCCESS: 'UPDATE_DOCUMENT_VIEW_SUCCESS',
  SHOW_PREVIEW_MODAL_ON_REDIRECTION: 'SHOW_PREVIEW_MODAL_ON_REDIRECTION',
  UPDATE_FOLDER_STACK_ORDER: 'UPDATE_FOLDER_STACK_ORDER',
  SHOW_ALERT_DETAILS: 'SHOW_ALERT_DETAILS',
  RESET_ALL_MODAL: 'RESET_ALL_MODAL',
  UPDATE_UNKNOWN_TYPE_FILE: 'UPDATE_UNKNOWN_TYPE_FILE',
  SET_GEOLOCATION_DETAILS: 'SET_GEOLOCATION_DETAILS',
  SAVE_ROTATED_IMAGE: 'SAVE_ROTATED_IMAGE',
  SAVE_ROTATED_IMAGE_SUCCESS: 'SAVE_ROTATED_IMAGE_SUCCESS',
  ASYNC_SAVE_ROTATED_IMAGE_SUCCESS: 'ASYNC_SAVE_ROTATED_IMAGE_SUCCESS',
  SAVE_ROTATED_IMAGE_FAILURE: 'SAVE_ROTATED_IMAGE_FAILURE',
  SET_LOCK_STATUS_SUCCESS: 'SET_LOCK_STATUS_SUCCESS',
  SET_LOCK_STATUS_FAILURE: 'SET_LOCK_STATUS_FAILURE',
  UPDATE_FOLDERS_AND_ITEM_LISTS: 'UPDATE_FOLDERS_AND_ITEM_LISTS',
  DUPLICATE_NODE_OR_SPARK: 'DUPLICATE_NODE_OR_SPARK',
  CREATE_QUICK_NODE_FROM_CAPTURE: 'CREATE_QUICK_NODE_FROM_CAPTURE',
  CREATE_QUICK_NODE_FROM_CAPTURE_SUCCESS:
    'CREATE_QUICK_NODE_FROM_CAPTURE_SUCCESS',
  CREATE_QUICK_NODE_FROM_CAPTURE_FAILURE:
    'CREATE_QUICK_NODE_FROM_CAPTURE_FAILURE',
  CREATE_NODE_FROM_CAPTURE_TO_BOARD: 'CREATE_NODE_FROM_CAPTURE_TO_BOARD',
  CREATE_NODE_FROM_ITEM_TO_ANOTHER_BOARD:
    'CREATE_NODE_FROM_ITEM_TO_ANOTHER_BOARD',
  ASYNC_SET_LOCK_STATUS: 'ASYNC_SET_LOCK_STATUS',
  ASYNC_DELETE_NODE: 'ASYNC_DELETE_NODE',
  ASYNC_DELETE_NODE_ITEMS: 'ASYNC_DELETE_NODE_ITEMS',
  ASYNC_DUPLICATE_NODE_ITEMS: 'ASYNC_DUPLICATE_NODE_ITEMS',
  ASYNC_REORDER_NODE_ITEM: 'ASYNC_REORDER_NODE_ITEM',
  ASYNC_REORDER_NODE: 'ASYNC_REORDER_NODE',
  ASYNC_MOVE_NODE_ITEM: 'ASYNC_MOVE_NODE_ITEM',
  ASYNC_DUPLICATE_NODE_OR_SPARK: 'ASYNC_DUPLICATE_NODE_OR_SPARK',
  ASYNC_CREATE_NODE_FROM_CAPTURE: 'ASYNC_CREATE_NODE_FROM_CAPTURE',
  DUPLICATE_NODE_ITEMS_SUCCESS: 'DUPLICATE_NODE_ITEMS_SUCCESS',
  ASYNC_CONVERTING_ITEM_TO_NODE: 'ASYNC_CONVERTING_ITEM_TO_NODE',
  ASYNC_UPDATE_FOLDER_STACK_ORDER: 'ASYNC_UPDATE_FOLDER_STACK_ORDER',
  CREATE_NEW_FOLDER_FROM_ITEM_SUCCESS: 'CREATE_NEW_FOLDER_FROM_ITEM_SUCCESS',
  ROTATE_IMAGE_ANGLE: 'ROTATE_IMAGE_ANGLE',
  ASYNC_UPDATE_NODES_LISTS: 'ASYNC_UPDATE_NODES_LISTS',
  ADD_FOLDER_INTO_CURRENT_PROJECT: 'ADD_FOLDER_INTO_CURRENT_PROJECT',
  ASYNC_ADD_ITEM_FROM_CAPTURE: 'ASYNC_ADD_ITEM_FROM_CAPTURE',
  SAVE_SPARK_PROPERTIES: 'SAVE_SPARK_PROPERTIES',
  SAVE_SPARK_PROPERTIES_SUCCESS: 'SAVE_SPARK_PROPERTIES_SUCCESS',
  SAVE_SPARK_PROPERTIES_FAILURE: 'SAVE_SPARK_PROPERTIES_FAILURE',
  SET_SPARK_THEME_CONFIG: 'SET_SPARK_THEME_CONFIG',
  UPDATE_FOLDER_PROPERTIES_LOADER: 'UPDATE_FOLDER_PROPERTIES_LOADER',
  SET_FOCUSED_ITEM_ID_FOR_SCROLLING: 'SET_FOCUSED_ITEM_ID_FOR_SCROLLING',
  UPDATE_UNLOCK_MODAL_VISIBILITY: 'UPDATE_UNLOCK_MODAL_VISIBILITY',
  UPDATE_FOLDER_MEDIA_ITEM: 'UPDATE_FOLDER_MEDIA_ITEM',
  UPDATE_EMPTY_SHARE_MODAL_VISIBILITY: 'UPDATE_EMPTY_SHARE_MODAL_VISIBILITY',
  SET_DESELECTED_NODE_ID: 'SET_DESELECTED_NODE_ID',
  SET_NODES_LIST_LOADER: 'SET_NODES_LIST_LOADER',
  UPDATE_FOLDER_LISTS_FETCH_TIME: 'UPDATE_FOLDER_LISTS_FETCH_TIME',
  UPDATE_FOLDER_ITEM_IMAGE_THUMBNAIL_VALUES:
    'UPDATE_FOLDER_ITEM_IMAGE_THUMBNAIL_VALUES',
  UPDATE_NODE_MEDIA_ITEM: 'UPDATE_NODE_MEDIA_ITEM',
  SET_BOARDS_FOLDER_LOADER: 'SET_BOARDS_FOLDER_LOADER',
  SET_FOLDER_FULL_SCREEN_SLIDER_TOOLTIP_VISIBLE: 'SET_FOLDER_FULL_SCREEN_SLIDER_TOOLTIP_VISIBLE',
  FOLDER_VIEW_UPDATE_FROM_SEARCH: 'FOLDER_VIEW_UPDATE_FROM_SEARCH',
  UPDATE_SELECTED_ACTIVITY_DETAILS: 'UPDATE_SELECTED_ACTIVITY_DETAILS',
  UPDATE_SELECT_MULTIPLE_FOLDER_ITEMS_MODAL:
    'UPDATE_SELECT_MULTIPLE_FOLDER_ITEMS_MODAL',
  UPDATE_SELECT_MULTIPLE_FOLDER_ITEMS: 'UPDATE_SELECT_MULTIPLE_FOLDER_ITEMS',
  UPDATE_DELETED_NODE_ITEMS: 'UPDATE_DELETED_NODE_ITEMS',
  UPDATE_DELETED_NODE: 'UPDATE_DELETED_NODE',
  ASYNC_ARCHIVE_FOLDER_STATUS: 'ASYNC_ARCHIVE_FOLDER_STATUS',
  ARCHIVE_FOLDER: 'ARCHIVE_FOLDER',
  ASYNC_UNARCHIVE_FOLDER_STATUS: 'ASYNC_UNARCHIVE_FOLDER_STATUS',
  UNARCHIVE_FOLDER: 'UNARCHIVE_FOLDER',
  REORDER_ARCHIVED_NODE: 'REORDER_ARCHIVED_NODE',
  REORDER_ARCHIVED_NODE_FAILURE: 'REORDER_ARCHIVED_NODE_FAILURE',
  UPDATE_TARGET_ZONE_REORDER: 'UPDATE_TARGET_ZONE_REORDER',
  ITEMS_FOLDER_DOWNLOAD_SUCCESSFULLY: 'ITEMS_FOLDER_DOWNLOAD_SUCCESSFULLY',
  UPDATE_ALL_FOLDERS_FOLDER_VIEW: 'UPDATE_ALL_FOLDERS_FOLDER_VIEW',
  UPDATE_MULTIPLE_FOLDER_ITEM_DELETE: 'UPDATE_MULTIPLE_FOLDER_ITEM_DELETE',
  SET_DRAG_ID: 'SET_DRAG_ID',
  SET_ITEM_VIEW_TYPE: 'SET_ITEM_VIEW_TYPE',
  SHOW_PREVIEW: 'SHOW_PREVIEW',
  SET_BAR_VIEW: 'SET_BAR_VIEW',
  EXPANDED_FOLDER_ADD_BAR: 'EXPANDED_FOLDER_ADD_BAR',
  ADDBAR_COMPONENTS_VISIBLE: 'ADDBAR_COMPONENTS_VISIBLE',
  RESHARE_SPARK_ID: 'RESHARE_SPARK_ID',
  SHARE_TYPE: 'SHARE_TYPE',
  SET_FOLDER_ADDBAR_VISIBILITY: 'SET_FOLDER_ADDBAR_VISIBILITY',
  REMOVE_COMMON_FOLDER_ACTION_NODE_ID: 'REMOVE_COMMON_FOLDER_ACTION_NODE_ID',
  COMMON_FOLDER_ACTION_NODE_ID: 'COMMON_FOLDER_ACTION_NODE_ID',
  MOVE_NODE_SELECTED_ITEM: 'MOVE_NODE_SELECTED_ITEM',
  GET_FOLDER_NOTE_ITEM_ID: 'GET_FOLDER_NOTE_ITEM_ID',
  RESET_FOLDER_NOTE_ITEM_ID: 'RESET_FOLDER_NOTE_ITEM_ID',
  ASYNC_FOLDER_CREATION: 'ASYNC_FOLDER_CREATION',
  UPDATE_TARGET_ZONE_REORDER_CALLBACK: 'UPDATE_TARGET_ZONE_REORDER_CALLBACK',
  FOLDER_ADD_BAR_VISIBILITY: 'FOLDER_ADD_BAR_VISIBILITY',
  UPDATE_SELECTED_FULLSCREEN_ITEM: 'UPDATE_SELECTED_FULLSCREEN_ITEM',
  UPDATE_MULTIPLE_FULLSCREEN_ITEM_DELETE: 'UPDATE_MULTIPLE_FULLSCREEN_ITEM_DELETE',
  UPDATE_DELETE_FULLSCREEN_ITEM_MODAL: 'UPDATE_DELETE_FULLSCREEN_ITEM_MODAL',
  RESET_SELECTED_DOCUMENT_ITEMS_LIST: 'RESET_SELECTED_DOCUMENT_ITEMS_LIST',
};

export default actions;
