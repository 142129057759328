const actions = {
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_SOCIAL: 'LOGIN_USER_SOCIAL',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',
  REGISTER_USER: 'REGISTER_USER',
  REGISTER_USER_SOCIAL: 'REGISTER_USER_SOCIAL',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  LOGOUT_USER: 'LOGOUT_USER',
  LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAILURE: 'LOGOUT_USER_FAILURE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
  UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
  UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
  UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',
  MODAL_VISIBLE: 'MODAL_VISIBLE',
  RESEND_OTP: 'RESEND_OTP',
  FETCH_DEACTIVATE_REASON_LIST: 'FETCH_DEACTIVATE_REASON_LIST',
  DEACTIVATE_ACCOUNT: 'DEACTIVATE_ACCOUNT',
  DEACTIVATE_ACCOUNT_SUCCESS: 'DEACTIVATE_ACCOUNT_SUCCESS',
  DEACTIVATE_ACCOUNT_FAILURE: 'DEACTIVATE_ACCOUNT_FAILURE',
  FETCH_DEACTIVATE_REASON_LIST_SUCCESS: 'FETCH_DEACTIVATE_REASON_LIST_SUCCESS',
  CHANGE_PASSWORD_VISIBLE: 'CHANGE_PASSWORD_VISIBLE',
  ACCOUNT_DEACTIVATION_VISIBLE: 'ACCOUNT_DEACTIVATION_VISIBLE',
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_FAILURE: 'ACTIVATE_ACCOUNT_FAILURE',
  DEACTIVATE_CONFIRMATION_VISIBLE: 'DEACTIVATE_CONFIRMATION_VISIBLE',
  REACTIVATE_CONFIRMATION_VISIBLE: 'REACTIVATE_CONFIRMATION_VISIBLE',
  VERIFY_MODAL_VISIBLE: 'VERIFY_MODAL_VISIBLE',
  CHANGE_USER_AVATAR: 'CHANGE_USER_AVATAR',
  CHANGE_USER_AVATAR_SUCCESS: 'CHANGE_USER_AVATAR_SUCCESS',
  CHANGE_USER_AVATAR_FAILURE: 'CHANGE_USER_AVATAR_FAILURE',
  SET_HISTORY: 'SET_HISTORY',
  CHANGE_USER_AVATAR_LOADER: 'CHANGE_USER_AVATAR_LOADER',
  SET_ON_BOARDING_MODAL_VISIBLE: 'SET_ON_BOARDING_MODAL_VISIBLE',
  ON_BOARD_COMPLETE_PROCESS: 'ON_BOARD_COMPLETE_PROCESS',
  SET_LOCATION_ACCESS_STATUS: 'SET_LOCATION_ACCESS_STATUS',
  SET_LOCATION_ACCESS_STATUS_SUCCESS: 'SET_LOCATION_ACCESS_STATUS_SUCCESS',
  GET_PASSWORDS_LIST: 'GET_PASSWORDS_LIST',
  GET_PASSWORDS_LIST_SUCCESS: 'GET_PASSWORDS_LIST_SUCCESS',
  GET_ACTIVE_SESSIONS: 'GET_ACTIVE_SESSIONS',
  GET_ACTIVE_SESSIONS_SUCCESS: 'GET_ACTIVE_SESSIONS_SUCCESS',
  GET_ACTIVE_SESSIONS_FAILURE: 'GET_ACTIVE_SESSIONS_FAILURE',
  REVOKE_ALL_SESSIONS: 'REVOKE_ALL_SESSIONS',
  REVOKE_SPECIFIC_SESSION: 'REVOKE_SPECIFIC_SESSION',
  REVOKE_SPECIFIC_SESSION_SUCCESS: 'REVOKE_SPECIFIC_SESSION_SUCCESS',
  REVOKE_SPECIFIC_SESSION_FAILURE: 'REVOKE_SPECIFIC_SESSION_FAILURE',
  SET_SHARED_SPARK_EXPAND_DETAILS: 'SET_SHARED_SPARK_EXPAND_DETAILS',
  SET_SHARED_SPARK_EXPAND_DETAILS_SUCCESS:
    'SET_SHARED_SPARK_EXPAND_DETAILS_SUCCESS',
  CHANGE_UPGRADE_MODAL_VISIBILITY: 'CHANGE_UPGRADE_MODAL_VISIBILITY',
  SET_DEVICE_LANGUAGE: 'SET_DEVICE_LANGUAGE',
  UPDATE_DEVICE_LANGUAGE: 'UPDATE_DEVICE_LANGUAGE',
  UPDATE_PLAN_DETAILS: 'UPDATE_PLAN_DETAILS',
  UPDATE_ACTIVE_SESSION_DETAILS: 'UPDATE_ACTIVE_SESSION_DETAILS',
  SHOW_REGISTRATION_FORM: 'SHOW_REGISTRATION_FORM',
  SET_ASYNC_LOADER_STATUS: 'SET_ASYNC_LOADER_STATUS',
  ASYNC_UPDATE_USER_DETAILS: 'ASYNC_UPDATE_USER_DETAILS',
  UPDATE_PROFILE_DETAILS: 'UPDATE_PROFILE_DETAILS',
  RESET_REDIRECT_MODAL: 'RESET_REDIRECT_MODAL',
  SET_LAST_ACCESSED_PAGE: 'SET_LAST_ACCESSED_PAGE',
  SET_SOCIAL_LOADER: 'SET_SOCIAL_LOADER',
  SET_EXTENSION_STORE_VISIBLE_MODAL: 'SET_EXTENSION_STORE_VISIBLE_MODAL',
  SET_SOCKET_ID: 'SET_SOCKET_ID',
  SET_SHOW_TIPS_STATUS: 'SET_SHOW_TIPS_STATUS',
  ASYNC_SET_SHOW_TIPS_STATUS: 'ASYNC_SET_SHOW_TIPS_STATUS',
  SET_SHOW_ALL_TIPS_STATUS: 'SET_SHOW_ALL_TIPS_STATUS',
  ASYNC_SET_SHOW_ALL_TIPS_STATUS: 'ASYNC_SET_SHOW_ALL_TIPS_STATUS',
  SET_MULTI_FILE_UPLOAD: 'SET_MULTI_FILE_UPLOAD',
  UPDATE_TRASH_EXPAND_COLLAPSE: 'UPDATE_TRASH_EXPAND_COLLAPSE',
  UPDATE_TRASH_STORAGE_LIMIT_AND_CONSUMPTION:
    'UPDATE_TRASH_STORAGE_LIMIT_AND_CONSUMPTION',
  UPDATE_CREATE_PROJECTS_COUNT: 'UPDATE_CREATE_PROJECTS_COUNT',
  UPDATE_APP_THEME: 'UPDATE_APP_THEME',
  ASYNC_UPDATE_APP_THEME: 'ASYNC_UPDATE_APP_THEME',
  ENTRY_PANEL_VALUES: 'ENTRY_PANEL_VALUES',
  ENTRY_PANEL_VISIBILITY: 'ENTRY_PANEL_VISIBILITY',
  ENTRY_PANEL_BUTTONS: 'ENTRY_PANEL_BUTTONS',
  ENTRY_PANEL_TOGGLE: 'ENTRY_PANEL_TOGGLE',
  SET_HOME_ICON: 'SET_HOME_ICON',
  CONTINUE_DELETE_MODAL: 'CONTINUE_DELETE_MODAL',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',
  ACCOUNT_DELETION_VISIBLE: 'ACCOUNT_DELETION_VISIBLE',
  ACCOUNT_DELETED_MODAL: 'ACCOUNT_DELETED_MODAL',
  DOWNLOAD_ASSETS: 'DOWNLOAD_ASSETS',
  FETCH_DELETE_ACCOUNT_REASON_LIST: 'FETCH_DELETE_ACCOUNT_REASON_LIST',
  FETCH_DELETE_ACCOUNT_REASON_LIST_SUCCESS: 'FETCH_DELETE_ACCOUNT_REASON_LIST_SUCCESS',
  UPDATE_SELECTED_SESSION_ID: 'UPDATE_SELECTED_SESSION_ID',
  IMMEDIATE_VERIFY_MODAL_VISIBLE: 'IMMEDIATE_VERIFY_MODAL_VISIBLE',
  VALIDATE_REGISTER_EMAIL: 'VALIDATE_REGISTER_EMAIL',
  VALIDATE_REGISTER_EMAIL_FAILURE: 'VALIDATE_REGISTER_EMAIL_FAILURE',
  UPDATE_OPEN_API_KEY: 'UPDATE_OPEN_API_KEY',
  UPDATE_OPEN_API_KEY_SUCCESS: 'UPDATE_OPEN_API_KEY_SUCCESS',
  UPDATE_OPEN_API_KEY_FAILURE: 'UPDATE_OPEN_API_KEY_FAILURE',
  UPDATE_ON_BOARDING_STATUS: 'UPDATE_ON_BOARDING_STATUS',
  UPDATE_ON_BOARDING_STATUS_SUCCESS: 'UPDATE_ON_BOARDING_STATUS_SUCCESS',
  UPDATE_ON_BOARDING_STATUS_FAILURE: 'UPDATE_ON_BOARDING_STATUS_FAILURE',
  UPDATE_USER_PLAN_STATUS: 'UPDATE_USER_PLAN_STATUS',
  FORM_INITIATION: 'FORM_INITIATION',
  UPDATE_USER_VERIFICATION_PROCESS: 'UPDATE_USER_VERIFICATION_PROCESS',
  UPDATE_ONBOARDING_TOUR_STEPS: 'UPDATE_ONBOARDING_TOUR_STEPS',
  INVOKE_PRE_NAMED_CONFIG_MODAL: 'INVOKE_PRE_NAMED_CONFIG_MODAL',
  RESET_STORE: 'RESET_STORE',
  PLANS_INVITE_EMAIL_SUCCESS: 'PLANS_INVITE_EMAIL_SUCCESS',
  SET_CURRENT_PLAN_DETAILS: 'SET_CURRENT_PLAN_DETAILS',
  SET_STORAGE_LIMIT: 'SET_STORAGE_LIMIT',
  SET_DONT_SHOW_EMPTY_CONTAINER_MODAL: 'SET_DONT_SHOW_EMPTY_CONTAINER_MODAL',
  SET_SHOW_CONFIGURATOR_MODAL: 'SET_SHOW_CONFIGURATOR_MODAL',
  UPDATE_USED_STORAGE: 'UPDATE_USED_STORAGE',
};

export default actions;
