import React from 'react';
import { ReactComponent as NoItemsYet } from 'images/svgs/noItemsYet.svg';
import { Translate } from 'utility/helpers';
import 'styles/components/noItems.scss';

function NoItems({
  messageFrom,
  heightFrom,
  positionfrom,
  topfrom,
  paddingtopFrom,
  className = '',
  renderDescription = true,
  children = null,
  isFromTrash = false,
}) {
  return (
    <div
      className={`no-items-yet ${className}`}
      style={{
        position: positionfrom,
        top: topfrom,
        minHeight: heightFrom,
        paddingTop: paddingtopFrom,
      }}
    >
      <NoItemsYet />
      {renderDescription && (
        <div className={'no-items-description'}>
          <Translate data={messageFrom}>
            {!isFromTrash ? 'empty_items' : 'trash_empty_items'}
          </Translate>
        </div>
      )}
      {children}
    </div>
  );
}

export default NoItems;
