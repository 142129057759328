import actions from './actions';
const initState = {
  premiumPlanIndexModalVisibility: false,
  premiumPlanHelperModalVisibility: false,
  isPremiumPlanIndexModalClosable: false,
  plansLoader: false,
  plansList: {},
  plansButtonLoader: false,
  inviteEmailButtonLoader: false,
  currentPlanDetailsLoader: false,
  currentPlanDetails: {},
  clientPortalUrl: null,
};
export default function PremiumPlansReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LATEST_PLANS_LIST: {
      return {
        ...state,
        plansLoader: true,
      };
    }
    case actions.GET_LATEST_PLANS_LIST_SUCCESS: {
      return {
        ...state,
        plansList: action.payload,
        plansLoader: false,
      };
    }
    case actions.GET_LATEST_PLANS_LIST_FAILURE: {
      return {
        ...state,
        plansLoader: false,
      };
    }
    case actions.SET_PREMIUM_PLAN_INDEX_MODAL_VISIBILITY: {
      const { visibility, isPremiumPlanIndexModalClosable } = action.payload;
      return {
        ...state,
        premiumPlanIndexModalVisibility: visibility,
        isPremiumPlanIndexModalClosable: isPremiumPlanIndexModalClosable,
      };
    }
    case actions.SET_PREMIUM_PLAN_HELPER_MODAL_VISIBILITY: {
      const { visibility, fromSettingsPage } = action.payload;
      return {
        ...state,
        premiumPlanHelperModalVisibility: visibility,
        premiumPlanHelperModalFromSettingsPage: fromSettingsPage,
      };
    }
    case actions.UPDATE_PLAN: {
      return {
        ...state,
        plansButtonLoader: true,
      };
    }
    case actions.UPDATE_PLAN_SUCCESS: {
      return {
        ...state,
        plansButtonLoader: false,
      };
    }
    case actions.UPDATE_PLAN_FAILURE: {
      return {
        ...state,
        plansButtonLoader: false,
      };
    }
    case actions.CHECKOUT_TO_CHARGEBEE: {
      return {
        ...state,
        plansButtonLoader: true,
      };
    }
    case actions.CHECKOUT_TO_CHARGEBEE_SUCCESS: {
      return {
        ...state,
        plansButtonLoader: false,
      };
    }
    case actions.CHECKOUT_TO_CHARGEBEE_FAILURE: {
      return {
        ...state,
        plansButtonLoader: false,
      };
    }
    case actions.PLAN_CHECKOUT: {
      return {
        ...state,
        plansButtonLoader: true,
      };
    }
    case actions.PLAN_CHECKOUT_SUCCESS: {
      return {
        ...state,
        plansButtonLoader: false,
      };
    }
    case actions.PLAN_CHECKOUT_FAILURE: {
      return {
        ...state,
        plansButtonLoader: false,
      };
    }
    case actions.INVITE_EMAIL: {
      return {
        ...state,
        inviteEmailButtonLoader: true,
      };
    }
    case actions.SET_INVITE_EMAIL_BUTTON_LOADER: {
      return {
        ...state,
        inviteEmailButtonLoader: false,
      };
    }
    case actions.GET_CURRENT_PLAN_DETAILS: {
      return {
        ...state,
        currentPlanDetailsLoader: true,
      };
    }
    case actions.GET_CURRENT_PLAN_DETAILS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        currentPlanDetailsLoader: false,
        currentPlanDetails: payload,
      };
    }
    case actions.GET_CURRENT_PLAN_DETAILS_FAILURE: {
      return {
        ...state,
        currentPlanDetailsLoader: false,
      };
    }
    case actions.GET_PREMIUM_PLANS_CLIENT_PORTAL_URL_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        clientPortalUrl: payload,
      };
    }
    default: {
      return { ...state };
    }
  }
}
