import { store } from 'redux/store';
import plansActions from 'redux/premiumPlans/actions';
export function setPremiumPlanIndexModalVisibility({
  visibility = false,
  initiateAPI = false,
  isPremiumPlanIndexModalClosable = false,
}) {
  //Reducer Update
  store.dispatch({
    type: plansActions.SET_PREMIUM_PLAN_INDEX_MODAL_VISIBILITY,
    payload: {
      visibility,
      isPremiumPlanIndexModalClosable,
    },
  });
  if (initiateAPI) {
    // API Call
    store.dispatch({
      type: plansActions.UPDATE_PLANS_INDEX_MODAL_VISIBILITY,
      payload: {
        visibility,
      },
    });
  }
}

export function setPremiumPlanHelperModalVisibility({
  visibility = false,
  fromSettingsPage = false,
}) {
  store.dispatch({
    type: plansActions.SET_PREMIUM_PLAN_HELPER_MODAL_VISIBILITY,
    payload: {
      visibility,
      fromSettingsPage,
    },
  });
}

export function formatDate({ isoDate }) {
  const dayjs = require('dayjs');
  const date = dayjs(isoDate);
  return date.format('MMM DD YYYY');
}
