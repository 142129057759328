import React from 'react';
import { ReactComponent as Marker } from 'images/svgs/marker.svg';
import { ReactComponent as Camera } from 'images/svgs/camera.svg';
import { ReactComponent as Video } from 'images/svgs/video.svg';
import { ReactComponent as Audio } from 'images/svgs/mic.svg';
import { ReactComponent as Edit } from 'images/svgs/edit.svg';
import { ReactComponent as Url } from 'images/svgs/url.svg';
import { ReactComponent as User } from 'images/svgs/user.svg';
import { ReactComponent as ListNumbers } from 'images/svgs/list-numbers.svg';
import { ReactComponent as Files } from 'images/svgs/unknownTypeFile.svg';

export const urlRegex = RegExp(
  '^(((H|h)(T|t)(T|t)(P|p)(S|s)?):\\/\\/)([a-zA-Z0-9-_]+\\.)[a-zA-Z0-9][a-zA-Z0-9-_]+\\.[a-zA-Z]{2,11}',
);
export const urlValidationRegex = RegExp(
  '^(https?:\\/\\/)' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
);
export const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

export const addbarComponentsIcon = [
  {
    type: 'photo',
    disabled: '',
    img: <Camera alt={'camera'} />,
    translation: 'photo',
    tooltip: 'tt_add_bar_photo',
  },
  {
    type: 'video',
    disabled: '',
    img: <Video alt={'video'} />,
    translation: 'video',
    tooltip: 'tt_add_bar_video',
  },
  {
    type: 'audio',
    disabled: '',
    img: <Audio alt={'audio'} />,
    translation: 'add_voice_memo_object',
    tooltip: 'tt_add_bar_voice_memo',
  },
  {
    type: 'location',
    disabled: '',
    img: <Marker alt={'Location'} />,
    translation: 'location',
    tooltip: 'tt_add_bar_location',
  },
  {
    type: 'note',
    disabled: '',
    img: <Edit alt={'edit'} />,
    translation: 'notes',
    tooltip: 'tt_add_bar_notes',
  },
  {
    type: 'url',
    disabled: '',
    img: <Url alt={'url'} />,
    translation: 'link',
    tooltip: 'tt_add_bar_link',
  },
  {
    type: 'contact',
    disabled: '',
    img: <User alt={'contact'} />,
    translation: 'signature',
    tooltip: 'tt_add_bar_signature',
  },
  {
    type: 'list',
    disabled: '',
    img: <ListNumbers alt={'Numbers'} />,
    translation: 'add_list_object',
    tooltip: 'tt_add_bar_list',
  },
  {
    type: 'unknown',
    disabled: '',
    img: <Files alt={'unknown'} />,
    translation: 'file_text',
    tooltip: 'tt_add_bar_file',
  },
];

export const collapseAddbarIcons = [
  {
    type: 'note',
    img: <Edit alt={'edit'} />,
    tooltip: 'tt_add_bar_notes',
  },
  {
    type: 'location',
    img: <Marker alt={'Location'} />,
    tooltip: 'tt_add_bar_location',
  },
  {
    type: 'url',
    img: <Url alt={'url'} />,
    tooltip: 'tt_add_bar_link',
  },
  {
    type: 'contact',
    img: <User alt={'contact'} />,
    tooltip: 'tt_add_bar_signature',
  },
];

export const folderAddBarComponentsIcon = [
  {
    type: 'photo',
    disabled: '',
    img: <Camera alt={'camera'} />,
    translation: 'photo',
    tooltip: 'tt_add_bar_photo',
  },
  {
    type: 'video',
    disabled: '',
    img: <Video alt={'video'} />,
    translation: 'video',
    tooltip: 'tt_add_bar_video',
  },
  {
    type: 'audio',
    disabled: '',
    img: <Audio alt={'audio'} />,
    translation: 'voice',
    tooltip: 'tt_add_bar_voice_memo',
  },
  {
    type: 'location',
    disabled: '',
    img: <Marker alt={'Location'} />,
    translation: 'location',
    tooltip: 'tt_add_bar_location',
  },
  {
    type: 'note',
    disabled: '',
    img: <Edit alt={'edit'} />,
    translation: 'notes',
    tooltip: 'tt_add_bar_notes',
  },
  {
    type: 'url',
    disabled: '',
    img: <Url alt={'url'} />,
    translation: 'link',
    tooltip: 'tt_add_bar_link',
  },
  {
    type: 'contact',
    disabled: '',
    img: <User alt={'contact'} />,
    translation: 'add_signature_object',
    tooltip: 'tt_add_bar_signature',
  },
  {
    type: 'list',
    disabled: '',
    img: <ListNumbers alt={'Numbers'} />,
    translation: 'add_list_object',
    tooltip: 'tt_add_bar_list',
  },
  {
    type: 'unknown',
    disabled: '',
    img: <Files alt={'unknown'} />,
    translation: 'file_text',
    tooltip: 'tt_add_bar_file',
  },
  // {
  //   type: '',
  //   disabled: 'disable',
  //   img: '',
  //   translation: '',
  //   tooltip: '',
  // },
  // {
  //   type: 'currentLocation',
  //   disabled: '',
  //   img: <GeoTag alt={'geoTag'} />,
  //   translation: 'pin',
  //   tooltip: 'tt_add_bar_file',
  // },
  // {
  //   type: '',
  //   disabled: 'disable',
  //   img: '',
  //   translation: '',
  //   tooltip: '',
  // },
];
export const dropdownAddBarItems = [
  {
    type: 'note',
    disabled: '',
    img: <Edit alt={'edit'} />,
    translation: 'add_notes_object',
    tooltip: 'tt_add_bar_notes',
  },
  {
    menuType: 'divider',
  },
  {
    type: 'photo',
    disabled: '',
    img: <Camera alt={'camera'} />,
    translation: 'add_photo_object',
    tooltip: 'tt_add_bar_photo',
  },
  {
    menuType: 'divider',
  },
  {
    type: 'video',
    disabled: '',
    img: <Video alt={'video'} />,
    translation: 'add_video_object',
    tooltip: 'tt_add_bar_video',
  },
  {
    menuType: 'divider',
  },
  {
    type: 'audio',
    disabled: '',
    img: <Audio alt={'audio'} />,
    translation: 'add_voice_memo_object',
    tooltip: 'tt_add_bar_voice_memo',
  },
  {
    menuType: 'divider',
  },
  {
    type: 'url',
    disabled: '',
    img: <Url alt={'url'} />,
    translation: 'add_link_object',
    tooltip: 'tt_add_bar_link',
  },
  {
    menuType: 'divider',
  },
  {
    type: 'list',
    disabled: '',
    img: <ListNumbers alt={'Numbers'} />,
    translation: 'add_list_object',
    tooltip: 'tt_add_bar_list',
  },
  {
    menuType: 'divider',
  },
  {
    type: 'contact',
    disabled: '',
    img: <User alt={'contact'} />,
    translation: 'add_signature_object',
    tooltip: 'tt_add_bar_signature',
  },
  {
    menuType: 'divider',
  },
  {
    type: 'unknown',
    disabled: '',
    img: <Files alt={'unknown'} />,
    translation: 'add_files_object',
    tooltip: 'tt_add_bar_unknown',
  },
  {
    menuType: 'divider',
  },
  // {
  //   type: 'currentLocation',
  //   disabled: '',
  //   img: <GeoTag alt={'geoTag'} />,
  //   translation: 'add_geo_tag_object',
  //   tooltip: 'tt_add_bar_file',
  // },
  // {
  //   menuType: 'divider',
  // },
  {
    type: 'location',
    disabled: '',
    img: <Marker alt={'Location'} />,
    translation: 'add_location_object',
    tooltip: 'tt_add_bar_location',
  },
  {
    menuType: 'divider',
  },
];

export const itemsPerRow = 3;
