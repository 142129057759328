import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { postRequest } from 'utility/axiosClient';
import { handleException } from 'utility/helpers';
import { removeAsyncAction, retryAsync } from '../../utility/asyncHelper';
import boardGroupsAction from '../boardGroups/actions';

export function* createGroups(params) {
  try {
    yield call(() =>
      postRequest('user/preload-submit', params.payload.payload),
    );
    yield put({
      type: boardGroupsAction.GET_ALL_GROUPS_LIST,
    });
    yield put({
      type: boardGroupsAction.GET_BOARD_GROUPS_LIST,
    });
    removeAsyncAction(params.id);
  } catch (error) {
    if (params?.id) {
      retryAsync(params.id, error);
    } else {
      handleException(error);
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_GROUPS, createGroups)]);
}
