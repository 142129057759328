import React from 'react';
import { useDroppable } from '@dnd-kit/core';
export default function Droppable({
  id,
  children,
  onDrop,
  data,
  disabled,
  className = '',
  allowHighLight = false,
  isFromIndexSection = false,
}) {
  const { setNodeRef, isOver } = useDroppable({
    id,
    onDrop,
    data,
    disabled,
  });

  let showDroppableBorder = '';
  if (allowHighLight && !isFromIndexSection && isOver) {
    showDroppableBorder = 'highlight-dnd-item';
  } else if (isFromIndexSection && isOver) {
    showDroppableBorder = 'drag-over';
  } else {
    showDroppableBorder = '';
  }

  return (
    <div
      ref={setNodeRef}
      id={id}
      className={`${className} ${showDroppableBorder}`}
    >
      {children}
    </div>
  );
}
