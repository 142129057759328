import { store } from '../../../redux/store';
import boardGroupActions from '../../../redux/boardGroups/actions';
import { addAsyncAction } from '../../../utility/asyncHelper';
import commonActions from '../../../redux/common/actions';
import actions from '../../../redux/folders/actions';
import jotsActions from 'redux/jots/actions';
import { alertView } from '../../../utility/helpers';

export function duplicateOnFinish(
  data,
  setWarning,
  setFormData,
  isHomeActivity,
  isFromCopy,
) {
  const { duplicateData, documentDetails } = store.getState().commonReducer;
  const { avatarData } = store.getState().commonReducer;
  // To handle create copy functionality
  if (!data.name) {
    data = duplicateData;
  }
  // To handle Board group duplicate
  if (duplicateData.duplicateType === 'group') {
    const { boardGroupsList } = store.getState().BoardGroups;
    const isUnique = !boardGroupsList.find(
      (grp) => grp.name?.toLowerCase() === data.name?.toLowerCase(),
    );
    if (isUnique || isFromCopy) {
      setWarning(false);
      initiateDuplicationProgress({
        id: duplicateData.id,
      });
      addAsyncAction(boardGroupActions.DUPLICATE_GROUP_API, {
        payload: {
          name: data.name,
          description: data?.description || data?.note,
          new_file_name: avatarData.new_file_name,
          type: 'category',
        },
        groupId: duplicateData.id,
        tempId: duplicateData.tempId,
      });
      store.dispatch({
        type: commonActions.CLOSE_DUPLICATE_MODAL,
      });
      store.dispatch({
        type: commonActions.RESET_DUPLICATE_MODAL_DATA,
      });
    } else {
      setFormData({ ...duplicateData, name: data.name });
      setWarning(true);
    }
    // To handle Folder level duplicate
  } else if (duplicateData.duplicateType === 'folder') {
    const { boardDetails } = store.getState().BoardGroups;
    const isUnique = !boardDetails?.find(
        (folder) => folder.name?.toLowerCase() === data.name?.toLowerCase(),
      );
    if (isUnique || isFromCopy) {
      setWarning(false);
      initiateDuplicationProgress({
        id: duplicateData.nodeId,
      });
      addAsyncAction(boardGroupActions.DUPLICATE_FOLDER_API, {
        payload: {
          name: data.name,
          description: data?.description || data?.note,
          new_file_name: avatarData.new_file_name,
          parent_id: duplicateData.parentId,
          type: 'folder',
        },
        nodeId: duplicateData.nodeId,
        tempId: duplicateData.tempId,
      });
      store.dispatch({
        type: commonActions.CLOSE_DUPLICATE_MODAL,
      });
      store.dispatch({
        type: commonActions.RESET_DUPLICATE_MODAL_DATA,
      });
    } else {
      setFormData({
        ...duplicateData,
        name: data.name,
        parent_details: { value: data.parent_details.value },
      });
      setWarning(true);
    }

    // To handle Document level duplication
  } else if (duplicateData.duplicateType === 'document') {
    const { jotsProjectItems } = store.getState().jotsReducer;
    const { boardDetails } = store.getState().BoardGroups;
    let isUnique;
    if (duplicateData.isHomeActivity) {
      isUnique = !jotsProjectItems?.find(
        (doc) => doc.name?.toLowerCase() === data.name?.toLowerCase(),
      );
    } else {
      isUnique = !boardDetails?.documents?.find(
        (doc) => doc.name?.toLowerCase() === data.name?.toLowerCase(),
      );
    }
    if (isUnique || isFromCopy) {
      setWarning(false);
      initiateDuplicationProgress({
        id: duplicateData.nodeId,
      });
      addAsyncAction(
        actions.ASYNC_DUPLICATE_NODE_OR_SPARK,
        {
          payload: {
            name: data.name,
            description: data.description || data?.note,
            parent_id: duplicateData.parentId,
            type: 'page',
          },
          nodeId: duplicateData.nodeId,
        },
      );
      store.dispatch({
        type: commonActions.CLOSE_DUPLICATE_MODAL,
      });
      store.dispatch({
        type: commonActions.RESET_DUPLICATE_MODAL_DATA,
      });
    } else {
      setFormData({
        ...duplicateData,
        name: data.name,
        parent_details: { value: data?.parent_details?.value },
      });
      setWarning(true);
    }

    // To handle Item level duplication
  } else if (duplicateData.duplicateType === 'item') {
    const { jotsProjectItems } = store.getState().jotsReducer;
    const { boardDetails } = store.getState().BoardGroups;

    let isUnique;

    if (duplicateData.subType === 'jotsItem') {
      isUnique = !jotsProjectItems?.find(
        (doc) => doc.name?.toLowerCase() === data.name?.toLowerCase(),
      );
    } else if (duplicateData.subType === 'jotsDocumentItem') {
      isUnique = !jotsProjectItems
        ?.find((doc) => doc?.id === data.nodeId)
        ?.items.find(
          (item) => item.name?.toLowerCase() === data.name?.toLowerCase(),
        );
    } else if (duplicateData.subType === 'boardsDocumentItem') {
      isUnique = !boardDetails
        ?.find((doc) => doc?.id === data.nodeId)
        ?.items.find(
          (item) => item.name?.toLowerCase() === data.name?.toLowerCase(),
        );
    } else if (duplicateData.subType === 'boardItem') {
      isUnique = !boardDetails?.find(
        (doc) => doc?.name?.toLowerCase() === data?.name?.toLowerCase(),
      );
    } else if (duplicateData.subType === 'boardNodeItem') {
      isUnique = !boardDetails?.documents
        ?.find((doc) => doc.id === data.nodeId)
        ?.items.find(
          (item) => item.name?.toLowerCase() === data.name?.toLowerCase(),
        );
    } else if (duplicateData.subType === 'listViewFullScreenDocumentItem') {
      isUnique = !documentDetails.some(
        (item) => item.name?.toLowerCase() === data.name?.toLowerCase(),
      );
    } else if (
      duplicateData.subType === 'listBoardItem' ||
      duplicateData.subType === 'listFolderItem'
    ) {
      isUnique = true;
    }

    if (isUnique || isFromCopy) {
      setWarning(false);
      if (duplicateData.subType === 'jotsItem') {
        addAsyncAction(jotsActions.DUPLICATE_JOTS_INDIVIDUAL_ITEM, {
          description: data.description || data?.note,
          itemId: duplicateData.itemId,
          name: data.name,
        });
      } else if (duplicateData.subType === 'jotsDocumentItem') {
        addAsyncAction(commonActions.DUPLICATE_DOCUMENT_ITEM_API, {
          name: data.name,
          itemId: duplicateData.itemId,
          isJotsActivity: duplicateData.isHomeActivity,
          description: data.description || data?.note,
        });
      } else if (duplicateData.subType === 'boardItem') {
        addAsyncAction(boardGroupActions.DUPLICATE_FOLDER_INDIVIDUAL_ITEM, {
          itemId: duplicateData.itemId,
          name: data.name,
          nodeId: duplicateData.nodeId,
          description: data.description || data?.note,
        });
      } else if (duplicateData.subType === 'boardNodeItem') {
        addAsyncAction(commonActions.DUPLICATE_DOCUMENT_ITEM_API, {
          itemId: duplicateData.itemId,
          name: data.name,
          description: data.description || data?.note,
          isHomeActivity: duplicateData?.isHomeActivity,
        });
      }

      store.dispatch({
        type: commonActions.CLOSE_DUPLICATE_MODAL,
      });
      store.dispatch({
        type: commonActions.RESET_DUPLICATE_MODAL_DATA,
      });
    } else {
      setFormData({
        ...duplicateData,
        name: data.name,
        parent_details: { value: data?.parent_details?.value },
      });
      setWarning(true);
    }

    // To handle node or spark level duplication
  } else {
    addAsyncAction(
      actions.ASYNC_DUPLICATE_NODE_OR_SPARK,
      {
        nodeId: duplicateData.nodeId,
        name: data.name,
        version: duplicateData.version,
        projectId: data?.parent_details?.value,
        tempId: duplicateData.tempId,
        lock: duplicateData.lock,
        isHomeActivity: duplicateData.isHomeActivity,
        description: duplicateData.description || data?.note,
      },
      [duplicateData.tempId],
    );
  }
}

export function initiateDuplicationProgress(data) {
  if (data && data?.progress_status === 'completed') {
    let alertText = 'document_duplicated_successfully';
    switch (data?.type) {
      case 'category': {
        alertText = 'group_duplicated_successfully';
        break;
      }
      case 'page': {
        alertText = 'document_duplicated_successfully';
        break;
      }
      case 'folder': {
        alertText = 'folder_duplicated_successfully';
        break;
      }
      default: {
        alertText = 'document_duplicated_successfully';
        break;
      }
    }
    alertView({
      parentContainer: 'direct',
      alertText: alertText,
      type: 'success',
    });
  }
  store.dispatch({
    type: commonActions.DUPLICATION_PROGRESS,
    payload: data,
  });
}

export function getParentDetails(selectedId, isHomeActivity) {
  const { allGroupList } = store.getState().BoardGroups;
  const { jotsProjectDetails } = store.getState().jotsReducer;
  let result;
  if (isHomeActivity) {
    result = {
      parentTitle: jotsProjectDetails.name,
      parentId: jotsProjectDetails?.id,
    };
  } else if (allGroupList && Array.isArray(allGroupList)) {
    allGroupList.forEach((group) => {
      if (group && group.group_id) {
        if (group.projects && Array.isArray(group.projects)) {
          group.projects.forEach((project) => {
            if (project && project.project_id) {
              if (project.project_id === selectedId) {
                result = {
                  parentTitle: group.name,
                  parentId: group.group_id,
                };
              } else if (project.nodes && Array.isArray(project.nodes)) {
                const traverseNodes = (nodes) => {
                  nodes.forEach((node) => {
                    if (node && node.node_id) {
                      if (node.node_id === selectedId) {
                        result = {
                          parentTitle: project.name,
                          parentId: project.project_id,
                        };
                      } else if (node.nodes && Array.isArray(node.nodes)) {
                        traverseNodes(node.nodes);
                      }
                    }
                  });
                };

                traverseNodes(project.nodes);
              }
            }
          });
        }
      }
    });
  }
  return result;
}
