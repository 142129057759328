import React from 'react';

function SyncIcon (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="18" height="19" viewBox="0 0 18 19">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path fill="#000" d="M2.239 7.258l.038.026L3.892 8.48c.25.185.302.538.117.787-.176.237-.502.297-.749.144l-.038-.026-.601-.444c.398 2.047 1.797 3.796 3.772 4.614 2.352.974 5.06.436 6.86-1.364.22-.22.576-.22.796 0 .22.22.22.576 0 .796-2.123 2.12-5.314 2.755-8.087 1.607-2.301-.953-3.939-2.98-4.43-5.357l-.33.449c-.175.237-.502.297-.748.144l-.038-.026c-.238-.175-.297-.502-.144-.748l.026-.039L1.49 7.402c.176-.238.503-.297.749-.144zM11.7.966c2.744 1.137 4.534 3.814 4.536 6.784 0 .09-.021.175-.06.25l.552-.407c.238-.175.567-.136.758.082l.029.036c.175.238.136.568-.082.758l-.036.03-1.615 1.192c-.237.175-.566.136-.757-.082l-.029-.036-1.193-1.612c-.184-.25-.132-.602.118-.787.237-.175.567-.136.758.082l.029.036.411.557c-.005-.032-.008-.065-.008-.098-.002-2.516-1.517-4.783-3.841-5.745-2.324-.963-5-.431-6.778 1.347-.22.22-.576.22-.796 0-.22-.22-.22-.576 0-.796C5.797.457 8.956-.17 11.7.967z" transform="translate(-1143 -378) translate(0 347.333) translate(1143 31) translate(0 1.5)"/>
                <path fill="#FFA303" d="M14.99 10.77c.22.22.22.576 0 .795-.219.22-.575.22-.795 0-.22-.22-.22-.576 0-.795.22-.22.576-.22.796 0zM3.227 3.713c.22.22.22.576 0 .796-.22.22-.576.22-.795 0-.22-.22-.22-.576 0-.796.22-.22.576-.22.795 0z" transform="translate(-1143 -378) translate(0 347.333) translate(1143 31) translate(0 1.5)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SyncIcon;
