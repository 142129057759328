import React from 'react';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import PublicRoutes from 'router';
import Boot from 'boot';
import ErrorBoundary from './errorBoundary';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { PostHogProvider } from 'posthog-js/react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { GoogleOAuthProvider } from '@react-oauth/google';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['development', 'testing', 'production'],
  releaseStage: process.env.REACT_APP_ENVIRONMENT,
  appVersion: '1.6.2022',
});

BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_KEY });

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  maskAllInputs: true,
  maskTextSelector: '*',
};

const BugsnagErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

function DashApp() {
  return (
    <ErrorBoundary>
      <BugsnagErrorBoundary>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <GoogleOAuthProvider clientId={"776046615225-7q0olumis6ok0fjektek47fgtfsg3vep.apps.googleusercontent.com"}>
            <Provider store={store}>
              <PublicRoutes />
            </Provider>
          </GoogleOAuthProvider>
        </PostHogProvider>
      </BugsnagErrorBoundary>
    </ErrorBoundary>
  );
}

// No need to call the refresh token when user not logged in
if (localStorage.getItem('isLoggedIn')) {
  Boot()
    .then(() => DashApp())
    .catch((error) => console.error(error));
}

export default DashApp;
