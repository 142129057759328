import { store } from 'redux/store';
import asyncActions from 'redux/async/actions';
import {checkIsHomeActivity, handleException, randomIdGenerate, Translate} from './helpers';
import actions from 'redux/auth/actions';
import homeActions from 'redux/home/actions';
import foldersAction from 'redux/folders/actions';
import React from 'react';
import CommonNotificationAlert from '../components/shared/commonNotificationAlert';
import { ReactComponent as AlertInfoIcon } from 'images/svgs/alertInfoIcon.svg';
import { cloneDeep } from 'lodash';

export function addAsyncActionProcess(type, payload, tempId) {
  const id = randomIdGenerate('asyncId'),
    updatedPayload = getUpdatedPayload(payload),
    isUpdate = checkForExistingUpdate(type, updatedPayload);
  addTempId(tempId);
  if (isUpdate) {
    const { id, data } = isUpdate;
    updateAsyncAction(id, data);
  } else {
    if (checkForRetry()) {
      store.dispatch({
        type: asyncActions.ADD_ASYNC_ITEM_BEFORE_ERROR,
        payload: {
          status: 'ready',
          id,
          type,
          payload: updatedPayload,
        },
      });
    } else {
      store.dispatch({
        type: asyncActions.ADD_ASYNC_ITEM,
        payload: {
          status: 'ready',
          id,
          type,
          payload: updatedPayload,
        },
      });
      // const hasRefreshed = JSON.parse(
      //   window.sessionStorage.getItem('retry-lazy-refreshed') || false,
      // );
      // if (!hasRefreshed) {
      window.onbeforeunload = () => 'Wait';
      // }
    }
    if (getCurrentAsyncAction()) {
      executeAsyncAction();
    }
  }
}
export function addAsyncAction(type, payload, tempId) {
  const locationAccess = store.getState().Auth.locationAccess;
  if (payload.isCurrentLocationProcess && locationAccess.status) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new window.google.maps.Geocoder(),
            latlng = new window.google.maps.LatLng({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          geocoder.geocode(
            {
              latLng: latlng,
            },
            function (results, status) {
              if (status === window.google.maps.GeocoderStatus.OK) {
                const placeId = results[1].place_id,
                  request = {
                    placeId,
                    fields: [
                      'name',
                      'formatted_address',
                      'place_id',
                      'address_component',
                      'formatted_phone_number',
                      'website',
                    ],
                  };
                if(window.globalMap) {
                  try {
                    new window.google.maps.places.PlacesService(
                      window.globalMap,
                    ).getDetails(request, (place, status) => {
                      if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus.OK
                      ) {
                        const locationDetails = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            address: place.formatted_address,
                            state:
                              place.address_components &&
                              place.address_components.find((data) =>
                                data.types.includes(
                                  'administrative_area_level_1',
                                ),
                              )?.long_name,
                            phone_no: place.formatted_phone_number,
                            website: place.formatted_phone_number,
                            location_name: place.name,
                            formatted_address: place.formatted_address,
                          },
                          updatedPayload = payload.mediaType
                            ? {
                              ...payload,
                              captureItemData: {
                                ...payload.captureItemData,
                                payload: {
                                  ...payload.captureItemData.payload,
                                  payload: {
                                    ...payload.captureItemData.payload.payload,
                                    location: locationDetails,
                                  },
                                },
                              },
                            }
                            : {
                              ...payload,
                              payload: {
                                ...payload.payload,
                                location: locationDetails,
                              },
                            };
                        addAsyncActionProcess(type, updatedPayload, tempId);
                      } else {
                        addAsyncActionProcess(type, payload, tempId);
                      }
                    });
                  } catch (e) {
                    addAsyncActionProcess(type, payload, tempId);
                  }
                } else {
                  addAsyncActionProcess(type, payload, tempId);
                }
              } else {
                addAsyncActionProcess(type, payload, tempId);
              }
            },
          );
        },
        () => {
          addAsyncActionProcess(type, payload, tempId);
        },
      );
    } else {
      addAsyncActionProcess(type, payload, tempId);
    }
  } else {
    addAsyncActionProcess(type, payload, tempId);
  }
}
export function addTempId(data) {
  if (data) {
    data.map((data) => {
      store.dispatch({
        type: asyncActions.ADD_TEMP_ID,
        payload: { [data]: '' },
      });
      return null;
    });
  }
}
export function getUpdatedPayload(data) {
  if (!data) return;
  const list = store.getState().AsyncReducer.asyncListTempId;
  let string = JSON.stringify(data);
  Object.keys(list).map((id) => {
    if (list[id]) {
      string = string.replaceAll(id, list[id]);
    }
    return null;
  });
  return JSON.parse(string);
}
export function removeAsyncAction(id) {
  const list = store.getState().AsyncReducer.asyncList;
  if (list[0].id === id && list.length > 1) {
    if (list[1].status === 'failed') {
      // setAsyncStatus('failed');
    } else {
      executeAsyncAction(list[1]);
    }
  }
  store.dispatch({
    type: asyncActions.REMOVE_ASYNC_ITEM,
    payload: id,
  });
}
export function updateAsyncAction(id, data, isOnlyStatusUpdate = false) {
  store.dispatch({
    type: asyncActions.UPDATE_ASYNC_ITEM,
    payload: { id, data, isOnlyStatusUpdate },
  });
}
export function getCurrentAsyncAction() {
  const { asyncList } = store.getState().AsyncReducer;
  if (asyncList.length) {
    return asyncList[0];
  }
  return false;
}
export function executeAsyncAction(currentAction, retry, value, retryId) {
  const asyncList = store.getState().AsyncReducer.asyncList,
    { type, payload, id, status } = currentAction || asyncList[0];
  if (status === 'ready' || retry) {
    updateAsyncAction(id, { status: retry ? 'retry' : 'running' }, true);
    setAsyncStatus(retry ? 'retry' : 'running');
    store.dispatch({
      type,
      payload,
      id,
      retry,
    });
  }
  if (
    ['note', 'location', 'url', 'contact'].includes(payload?.addBarType) &&
    !value?.retry &&
    retryId !== id
  ) {
    store.dispatch({
      type: asyncActions.UPDATE_MANUAL_RETRY,
      retryAction: currentAction || asyncList[0],
      id,
      retry,
    });
  }
}
export function removeRestriction(list) {
  if (list.length) {
    window.onbeforeunload = () => 'Wait';
  } else {
    window.onbeforeunload = null;
  }
}
export function checkForExistingUpdate(type, newPayload) {
  const { itemId, projectId, nodeId } = newPayload,
    list = store.getState().AsyncReducer.asyncList,
    sameAction = list.filter((data) => {
      const { payload, type: action, status } = data;
      if (type === action && status === 'ready') {
        if (checkSameProject(payload, { itemId, projectId, nodeId })) {
          return true;
        } else if (checkSameNode(payload, { itemId, nodeId })) {
          return true;
        } else if (checkSameItem(payload, { itemId })) {
          return true;
        }
        return false;
      }
      return false;
    });
  if (sameAction.length) {
    return { id: sameAction[0].id, data: newPayload };
  }
  return false;
}
export function checkSameProject(firstPayload, secondPayload) {
  if (
    firstPayload.projectId &&
    secondPayload.projectId &&
    firstPayload.projectId === secondPayload.projectId
  ) {
    return checkSameNode(firstPayload, secondPayload);
  }
  return false;
}
export function checkSameNode(firstPayload, secondPayload) {
  if (
    firstPayload.nodeId &&
    secondPayload.nodeId &&
    firstPayload.nodeId === secondPayload.nodeId
  ) {
    return checkSameItem(firstPayload, secondPayload);
  }
  return false;
}
export function checkSameItem(firstPayload, secondPayload) {
  return (
    firstPayload.itemId &&
    secondPayload.itemId &&
    firstPayload.itemId === secondPayload.itemId
  );
}
export function updateAsyncItemTempId(tempId, realId) {
  store.dispatch({
    type: asyncActions.ADD_TEMP_ID,
    payload: { [tempId]: realId },
  });
  store.dispatch({
    type: asyncActions.UPDATE_ASYNC_ITEM_TEMP_ID,
    tempId,
    realId,
  });
}
export function pushIntoFirstAndExecute(type, payload, tempId) {
  const id = randomIdGenerate('asyncId'),
    updatedPayload = getUpdatedPayload(payload),
    data = {
      status: 'ready',
      id,
      type,
      payload: updatedPayload,
    };
  if (tempId) {
    tempId.map((data) => {
      store.dispatch({
        type: asyncActions.ADD_TEMP_ID,
        payload: { [data]: '' },
      });
      return null;
    });
  }
  store.dispatch({
    type: asyncActions.PUSH_ITEM_AT_FIRST,
    payload: data,
  });
  window.onbeforeunload = () => 'Wait';
  executeAsyncAction(data);
}
export function retryAsync(id, error, callbackId) {
  const { response } = error;
  if (response) {
    const list = store.getState().AsyncReducer.asyncList;
    if (
      response &&
      response.status === 401 &&
      response.config.url !== '/login'
    ) {
      updateAsyncAction(id, { status: 'ready' }, true);
      pushIntoFirstAndExecute(actions.REFRESH_TOKEN);
    } else if (response.status === 422) {
      let currentListItem = cloneDeep(list.find((data) => data.id === id));
      if (isReadyAction()) {
        moveRetryActionToLast();
      }
      removeAsyncAction(id);
      handleException(error)
      updateAsyncAction(id, {
        payload: {
          ...currentListItem?.payload,
          errorMessage:
            response?.data?.error?.message ||
            response?.data?.errors?.map((data) => data?.message),
        },
        status: 'failed',
      });
      setAsyncStatus('failed');
    } else if (response?.status >= 500 && response?.status < 600) {
      removeAsyncAction(id);
      const commonNotificationAlertArgs = {
        type: 'warning',
        message: <Translate>server_error_occurred_alert</Translate>,
        description: (
          <>
            <div>
              <Translate>something_went_wrong_alert</Translate>
            </div>
            <div
              onClick={() => window.location.reload()}
              className={'underline-text'}
            >
              <Translate>try_again_alert</Translate>
            </div>
          </>
        ),
        icon: (
          <div className={'info-icon'}>
            <AlertInfoIcon />
          </div>
        ),
        className: 'server-error',
        top: 60,
      };
      CommonNotificationAlert(commonNotificationAlertArgs);
    } else {
      list.map((data) => {
        if (data.id === id) {
          let value = {
            retry: data.retry ? data.retry + 1 : 1,
          };
          if (value.retry < 1) {
            updateAsyncAction(id, value, true);
            executeAsyncAction(data, callbackId || true, value);
          } else {
            if (isReadyAction()) {
              moveRetryActionToLast();
            }
            if (response?.status === 404) {
              // let currentListItem = cloneDeep(list.find((data) => data.id === id));
              removeAsyncAction(id);
              // updateAsyncAction(id, {
              //   payload: {
              //     ...currentListItem?.payload,
              //     errorMessage: response?.data?.error?.message,
              //   },
              //   status: 'failed',
              // });
            } else {
              // updateAsyncAction(id, { status: 'failed' });
              removeAsyncAction(id);
            }
            // setAsyncStatus('failed');
          }
        }
        return null;
      });
    }
  } else {
    if (isReadyAction()) {
      moveRetryActionToLast();
    }
    removeAsyncAction(id);
    // updateAsyncAction(id, { status: 'failed' });
    // setAsyncStatus('failed');
  }
}
export function isReadyAction() {
  const list = store.getState().AsyncReducer.asyncList;
  return list.some((data) => ['ready'].includes(data.status));
}
export function moveRetryActionToLast() {
  let list = store.getState().AsyncReducer.asyncList;
  let index = list.findIndex((data) => ['ready'].includes(data.status));
  list = list.concat(list.splice(0, index));
  store.dispatch({
    type: asyncActions.UPDATE_ASYNC_LIST,
    payload: list,
  });
  executeAsyncAction(list[0]);
}
export function setAsyncStatus(data) {
  store.dispatch({
    type: asyncActions.SET_ASYNC_LOADER_STATUS,
    payload: data,
  });
}
export function executeCallback(action, tempId, id, retryId) {
  addTempId(tempId);
  const { type, ...payload } = action;
  updateAsyncAction(id, { status: 'callback' }, true);
  store.dispatch({
    type,
    payload,
    id,
  });
  if (retryId !== id && retryId !== 'retry-all') {
    store.dispatch({
      type: asyncActions.UPDATE_MANUAL_RETRY,
      retryAction: action,
      id,
    });
  }
}
export function getNodeDataFromProject(
  projectId,
  action = 'CREATE_NODE_FROM_CAPTURE_TO_BOARD',
) {
  const list = store.getState().AsyncReducer.asyncList;
  return list.filter(
    ({ type, payload }) =>
      type === action &&
      (payload.projectId === projectId || payload.project_id === projectId),
  );
}
export function checkForRetry() {
  const list = store.getState().AsyncReducer.asyncList;
  return list.some((data) => ['failed', 'retry'].includes(data.status));
}
export function checkForRunningOrReadyAsyncAction() {
  const list = store.getState().AsyncReducer.asyncList;
  return list.some((data) => ['ready', 'running'].includes(data.status));
}

export function removeAddedAsyncItem(
  asyncId,
  itemType,
  tempId,
  projectId,
  nodeId,
) {
  if (itemType === 'capture-item') {
    store.dispatch({
      type: 'DELETE_CAPTURE_SUCCESS',
      payload: tempId,
    });
  } else {
    store.dispatch({
      type: checkIsHomeActivity(projectId)
        ? homeActions.DELETE_HOME_NODE_ITEMS
        : foldersAction.DELETE_NODE_ITEMS,
      nodeId: nodeId,
      items: [tempId],
    });
  }
}
export function getIdForTempId(tempId) {
  if (!tempId) return;
  const list = store.getState().AsyncReducer.asyncListTempId;
  return list[tempId];
}

export function resetAsyncList() {
  store.dispatch({ type: 'RESET_ASYNC_LIST' });
}

export function executeNextFileToUpload() {
  const list = store.getState().AsyncReducer.asyncList;
  if (list.length) {
    const index = list.findLastIndex(
      (data) => data.status === 'running' || data.status === 'callback',
    );
    // Find the running file index and execute the next file
    if (index !== -1 && list[index + 1]) {
      executeAsyncAction(list[index + 1]);
    }
  }
}