import axios from 'axios';
import settings from 'config/settings';
import { store } from 'redux/store';

export const axiosClient = axios.create({
  validateStatus: (status) => status >= 200 && status < 300 && status !== 205,
});

axiosClient.defaults.baseURL = settings.HOST_URL;

axiosClient.defaults.headers = settings.headers;

export function getRequest(URL, params, cancelToken) {
  const { accountLanguage, socketId } = store.getState().Auth;
  axiosClient.defaults.headers['content-language'] = accountLanguage;
  if (socketId) {
    axiosClient.defaults.headers['X-Socket-ID'] = socketId;
  }
  return axiosClient
    .get(`/${URL}`, {
      withCredentials: true,
      params,
      cancelToken: cancelToken ? cancelToken : '',
    })
    .then((response) => response);
}

export function postRequest(URL, payload) {
  const { accountLanguage, socketId } = store.getState().Auth;
  axiosClient.defaults.headers['content-language'] = accountLanguage;
  if (socketId) {
    axiosClient.defaults.headers['X-Socket-ID'] = socketId;
  }
  return axiosClient
    .post(`/${URL}`, payload, { withCredentials: true })
    .then((response) => response);
}

export function patchRequest(URL, payload) {
  const { accountLanguage, socketId } = store.getState().Auth;
  axiosClient.defaults.headers['content-language'] = accountLanguage;
  if (socketId) {
    axiosClient.defaults.headers['X-Socket-ID'] = socketId;
  }
  return axiosClient
    .patch(`/${URL}`, payload, { withCredentials: true })
    .then((response) => response);
}

export function deleteRequest(URL, payload) {
  const { accountLanguage, socketId } = store.getState().Auth;
  axiosClient.defaults.headers['content-language'] = accountLanguage;
  if (socketId) {
    axiosClient.defaults.headers['X-Socket-ID'] = socketId;
  }
  return axiosClient
    .delete(`/${URL}`, { data: payload, withCredentials: true })
    .then((response) => response);
}
export function putRequest(URL, payload) {
  const { accountLanguage, socketId } = store.getState().Auth;
  axiosClient.defaults.headers['content-language'] = accountLanguage;
  if (socketId) {
    axiosClient.defaults.headers['X-Socket-ID'] = socketId;
  }
  return axiosClient
    .put(`/${URL}`, payload, { withCredentials: true })
    .then((response) => response);
}
