import { store } from '../redux/store';
import commonActions from '../redux/common/actions';
import jotsActions from '../redux/jots/actions';
import boardGroupActions from '../redux/boardGroups/actions';
import { alertView, openFullScreenFolderModal } from './helpers';
import { addAsyncAction } from './asyncHelper';
import {
  deleteBoardSharedApi,
  deleteDocumentSharedApi,
  deleteFolderSharedApi,
  deleteGroupSharedApi,
} from '../components/contextMenu/helper';
import actions from '../redux/folders/actions';
import { isCloudAreaListView } from '../components/boardGroups/helper';
import sharedSparksActions from '../redux/sharedSparks/actions';
import sharedSparkActions from '../redux/sharedSparks/actions';
import cloudAreaAlertView from '../components/notification/myFilesAlertView';
import jotsAlertView from '../components/notification/jotsAlertView';

export function deleteModalVisibility(data = {}) {
  const {
    groupId,
    projectId,
    nodeId,
    itemId,
    deleteParent,
    id,
    isListView,
    isArchive,
    deleteItemType,
    isHomeActivity,
    nodeType,
    isAllArchiveItems,
    alertMessage,
  } = data;
  const {
    isOpenFullScreenFolderModal,
    asyncFolderCreationId,
  } = store.getState().Folder;
  if (isOpenFullScreenFolderModal || asyncFolderCreationId) {
    store.dispatch({
      type: commonActions.FULLSCREEN_DOCUMENT_DELETE_MODAL,
      fullScreenFolderDeleteModalData: {
        visibility: true,
        id,
        projectId,
        nodeId,
        itemId,
        deleteParent,
        isHomeActivity,
        nodeType,
        deleteItemType,
        isAllArchiveItems,
      },
    });
  } else if (
    deleteParent === 'jots' ||
    deleteParent === 'jots_document' ||
    deleteItemType === 'jotsDocument' ||
    deleteItemType === 'jotsListItem' || deleteItemType === 'jotsArchivePage'
  ) {
    store.dispatch({
      type: jotsActions.JOTS_DELETE_MODAL,
      jotsDeleteModalData: {
        visibility: true,
        id,
        groupId,
        projectId,
        nodeId,
        itemId,
        deleteParent,
        deleteItemType,
        isHomeActivity,
        isAllArchiveItems,
        alertMessage,
      },
    });
  } else if (
    deleteItemType === 'group' ||
    deleteItemType === 'board' ||
    deleteItemType === 'folder' ||
    deleteItemType === 'document' ||
    deleteParent === 'files_document' ||
    deleteParent === 'boards' ||
    deleteParent === 'folder' ||
    deleteItemType === 'filesListItem' || deleteItemType === 'myFilesArchivePage'
  ) {
    store.dispatch({
      type: boardGroupActions.FILES_DELETE_MODAL,
      filesDeleteModalData: {
        visibility: true,
        id,
        groupId,
        projectId,
        nodeId,
        itemId,
        deleteParent,
        isListView,
        isArchive,
        deleteItemType,
        isHomeActivity,
        isAllArchiveItems,
        alertMessage,
      },
    });
  } else if (deleteItemType === 'shared_spark') {
    store.dispatch({
      type: sharedSparksActions.SHARED_SPARK_DELETE_MODAL,
      sparkDeleteModalData: {
        visibility: true,
        groupId,
        projectId,
        nodeId,
        deleteItemType,
        nodeType,
        isListView,
        id,
      },
    });
  }
}

export function confirmDelete(container) {
  const {
    isOpenFullScreenFolderModal,
    asyncFolderCreationId,
  } = store.getState().Folder;
  if (isOpenFullScreenFolderModal || asyncFolderCreationId) {
    const {
      id,
      projectId,
      nodeId,
      deleteParent,
      deleteItemType,
      isHomeActivity,
    } = store.getState().commonReducer.fullScreenFolderDeleteModalData;
    store.dispatch({
      type: commonActions.RESET_FULLSCREEN_DOCUMENT_DELETE_MODAL,
    });
    store.dispatch({
      type: actions.RESET_SELECTED_DOCUMENT_ITEMS_LIST,
    });
    if (deleteItemType === 'document' || deleteItemType === 'jotsDocument') {
      openFullScreenFolderModal(false);
      if (isHomeActivity) {
        store.dispatch({
          type: jotsActions.DELETE_JOTS_DOCUMENT,
          deleteDocumentNodeId: id,
        });
        deleteDocumentSharedApi({
          id,
          projectId,
          isHomeActivity: true,
          nodeType: 'jotsDocument',
        });
      } else if (isCloudAreaListView()) {
        deleteDocumentSharedApi({
          id,
          projectId,
          isHomeActivity,
          isListView: true,
        });
      } else {
        store.dispatch({
          type: boardGroupActions.DELETE_DOCUMENT,
          deleteDocumentNodeId: id,
        });
        store.dispatch({
          type: actions.UPDATE_DELETE_NODE_MODAL_VISIBILITY,
          payload: false,
        });
        deleteDocumentSharedApi({ id, projectId, nodeType: 'documents' });
      }
    } else {
      const deleteId = Array.isArray(id) ? id : [id];
      if (deleteParent === 'jots_document') {
        store.dispatch({
          type: jotsActions.DELETE_JOTS_DOCUMENT_ITEMS,
          projectId,
          nodeId,
          itemId: id,
        });
      } else if (deleteParent === 'files_document') {
        store.dispatch({
          type: boardGroupActions.DELETE_DOCUMENT_ITEM,
          projectId,
          nodeId,
          itemId: id,
        });
      } else if (deleteParent === 'list_view_fullscreen') {
        store.dispatch({
          type: commonActions.DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM,
          projectId,
          nodeId,
          itemIds: deleteId,
        });
      }
      const action =
        deleteParent === 'list_view_fullscreen'
          ? 'DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_API'
          : 'DOCUMENT_ITEM_DELETE_API';
      addAsyncAction(commonActions[action], {
        projectId,
        nodeId,
        isHomeActivity,
        payload: {
          items: deleteId,
        },
      });
    }
  } else if (container === 'jots') {
    const {
      id,
      deleteParent,
      projectId,
      nodeId,
      deleteItemType,
      isAllArchiveItems,
      alertMessage,
    } = store.getState().jotsReducer.jotsDeleteModalData;
    store.dispatch({
      type: jotsActions.RESET_JOTS_DELETE_MODAL,
    });
    if (deleteItemType === 'jotsDocument') {
      if (isAllArchiveItems) {
        store.dispatch({
          type: commonActions.DELETE_ALL_ARCHIVE_ITEM,
          itemType: 'documents',
          deleteId: id,
          key: 'node_id',
        });
      } else {
        store.dispatch({
          type: jotsActions.DELETE_JOTS_DOCUMENT,
          deleteDocumentNodeId: id,
        });
      }
      deleteDocumentSharedApi({
        id,
        projectId,
        isAllArchiveItems,
        isHomeActivity: true,
        nodeType: 'jotsDocument',
        alertMessage,
      });
    } else if (deleteItemType === 'jotsListItem') {
      addAsyncAction(commonActions.INDIVIDUAL_ITEM_DELETE_API, {
        projectId,
        payload: {
          items: [id],
        },
        deleteItemNodeId: nodeId,
      });
    } else if (deleteParent === 'jots') {
      store.dispatch({
        type: jotsActions.DELETE_JOTS_INDIVIDUAL_ITEMS,
        itemId: id,
      });
      addAsyncAction(commonActions.INDIVIDUAL_ITEM_DELETE_API, {
        projectId,
        payload: {
          items: [id],
        },
        isHomeActivity: true,
      });
    } else if (deleteParent === 'jots_document') {
      store.dispatch({
        type: jotsActions.DELETE_JOTS_DOCUMENT_ITEMS,
        projectId,
        nodeId,
        itemIds: [id],
      });
      jotsAlertView({
        parentContainer: 'jots',
        alertText: 'delete_item_success',
        type: 'success',
        delay: 4,
        deleteMessage: true,
        nodeId,
        itemIds: [id],
        nodeType: 'jotsDocumentItem',
      });
      setTimeout(() => {
        addAsyncAction(commonActions.DOCUMENT_ITEM_DELETE_API, {
          projectId,
          nodeId,
          isHomeActivity: true,
          payload: {
            items: [id],
          },
        });
      }, 5000);
    } else if (deleteItemType === 'jotsArchivePage') {
      store.dispatch({
        type: jotsActions.JOTS_DELETE_ARCHIVED_PAGE,
        nodeId: id,
      })
      deleteDocumentSharedApi({
        id,
        projectId,
        isHomeActivity: true,
        alertMessage,
      });
    }
  } else if (container === 'shared_spark') {
    const {
      projectId,
      nodeId,
      groupId,
      nodeType,
      id,
    } = store.getState().SharedSparks.sparkDeleteModalData;
    const payload = {
      visibility: false,
    };
    store.dispatch({
      type: sharedSparkActions.SHARE_MODAL,
      payload,
    });
    store.dispatch({
      type: sharedSparkActions.RESET_SHARED_SPARK_DELETE_MODAL,
    });
    store.dispatch({
      type: sharedSparkActions.DELETE_LATEST_SHARED_SPARKS_SUCCESS,
      id,
    });
    alertView({
      parentContainer: 'direct',
      alertText: 'shared_spark_deleted',
      type: 'success',
      delay: 4,
      deleteMessage: true,
      nodeId: nodeId,
      projectId: projectId,
      groupId: groupId,
      nodeType: 'sharedSpark',
      sharedSparkNodeType: nodeType,
    });
    store.dispatch({
      type: sharedSparkActions.DELETE_LATEST_SHARED_SPARKS,
      id,
    });
  } else {
    const {
      id,
      deleteParent,
      projectId,
      nodeId,
      isListView,
      isArchive,
      groupId,
      deleteItemType,
      isAllArchiveItems,
      alertMessage,
    } = store.getState().BoardGroups.filesDeleteModalData;
    store.dispatch({
      type: boardGroupActions.RESET_FILES_DELETE_MODAL,
    });
    if (deleteItemType === 'group') {
      if (isAllArchiveItems) {
        store.dispatch({
          type: commonActions.DELETE_ALL_ARCHIVE_ITEM,
          itemType: 'groups',
          deleteId: id,
          key: 'group_id',
        });
      } else if (!isArchive) {
        store.dispatch({
          type: boardGroupActions.UPDATE_DELETE_GROUP,
          deleteGroupId: id,
        });
      } else if (isArchive) {
        store.dispatch({
          type: boardGroupActions.DELETE_ARCHIVED_CATEGORY,
          deleteId: id,
        });
      }
      deleteGroupSharedApi({ id, isListView });
    } else if (deleteItemType === 'board') {
      if (!isListView) {
        if (isArchive) {
          store.dispatch({
            type: boardGroupActions.MY_FILES_DELETE_ARCHIVED_NODE,
            id,
          });
        } else {
          store.dispatch({
            type: boardGroupActions.UPDATE_DELETE_BOARD,
            deleteBoardId: id,
            groupId,
          });
        }
      }
      deleteBoardSharedApi({ id, isListView, isArchive, isAllArchiveItems });
    } else if (deleteItemType === 'folder') {
      store.dispatch({
        type: boardGroupActions.UPDATE_DELETE_FOLDER,
        deleteFolderId: id,
      });
      deleteFolderSharedApi({ id, isListView, projectId });
    }
    else if (deleteItemType === 'document') {
      // TODO: Card-2148 Need to handle list view delete
      if (!isListView) {
        if (isAllArchiveItems) {
          store.dispatch({
            type: commonActions.DELETE_ALL_ARCHIVE_ITEM,
            itemType: 'documents',
            deleteId: id,
            key: 'node_id',
          });
        } else {
          store.dispatch({
            type: boardGroupActions.DELETE_DOCUMENT,
            deleteDocumentNodeId: id,
            isArchiveContext: isArchive,
          });
        }
        store.dispatch({
          type: actions.UPDATE_DELETE_NODE_MODAL_VISIBILITY,
          payload: false,
        });
      }
      deleteDocumentSharedApi({
        id,
        projectId,
        isListView,
        isArchive,
        isAllArchiveItems,
        alertMessage: 'document_delete_success',
      });
    } else if (deleteItemType === 'filesListItem') {
      addAsyncAction(commonActions.INDIVIDUAL_ITEM_DELETE_API, {
        projectId,
        payload: {
          items: [id],
        },
        deleteItemNodeId: nodeId,
        updateList: true,
      });
    } else if (deleteParent === 'boards' || deleteParent === 'folder') {
      const { projectId } = store.getState().BoardGroups;
      store.dispatch({
        type: boardGroupActions.DELETE_INDIVIDUAL_ITEMS,
        itemId: id,
      });
      addAsyncAction(commonActions.INDIVIDUAL_ITEM_DELETE_API, {
        projectId: projectId,
        payload: {
          items: [id],
        },
        nodeType: 'boardsItem',
        itemIds: [id],
        isHomeActivity: false,
        deleteItemNodeId: nodeId,
      });
    } else if (deleteParent === 'files_document') {
      store.dispatch({
        type: boardGroupActions.DELETE_DOCUMENT_ITEM,
        projectId,
        nodeId,
        itemIds: [id],
      });
      cloudAreaAlertView({
        parentContainer: 'boards',
        alertText: 'delete_item_success',
        type: 'success',
        delay: 4,
        deleteMessage: true,
        nodeId,
        itemIds: [id],
        nodeType: 'documentItem',
      });
      setTimeout(() => {
        addAsyncAction(commonActions.DOCUMENT_ITEM_DELETE_API, {
          projectId,
          nodeId,
          isHomeActivity: false,
          payload: {
            items: [id],
          },
        });
      }, 5000);
    } else if (deleteItemType === 'myFilesArchivePage') {
      store.dispatch({
        type: boardGroupActions.MY_FILES_DELETE_ARCHIVED_NODE,
        id,
      })
      deleteDocumentSharedApi({
        id,
        projectId,
        isHomeActivity: true,
        alertMessage,
      });
    }
  }
}

export function abortDeleteConfirmation(container) {
  if (container === 'jots') {
    store.dispatch({
      type: jotsActions.RESET_JOTS_DELETE_MODAL,
    });
  } else if (container === 'fullscreen_document') {
    store.dispatch({
      type: commonActions.RESET_FULLSCREEN_DOCUMENT_DELETE_MODAL,
    });
  } else if (container === 'shared_spark') {
    store.dispatch({
      type: sharedSparksActions.RESET_SHARED_SPARK_DELETE_MODAL,
    });
  } else {
    store.dispatch({
      type: boardGroupActions.RESET_FILES_DELETE_MODAL,
    });
  }
}
