import actions from './actions';
import {tourAttributes} from "../../components/tour/constants";

const initState = {
  visibility: true,
  tourModal: {
    visibility: localStorage.getItem('tourModalScreen') === 's4',
    screen: localStorage.getItem('tourModalScreen') === 's4' ? 's4' : 's1',
  },
  tour: {
    target: tourAttributes.TOUR_STEP_1,
  },
  isTourCompleted: false,
  quickTour: false,
};

export default function TourReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOUR_VISIBILITY: {
      return {
        ...state,
        visibility: true,
      };
    }
    case actions.UPDATE_MODAL_SCREEN: {
      localStorage.setItem('tourModalScreen', action.payload);
      return {
        ...state,
        tourModal: {
          ...state.tourModal,
          screen: action.payload,
        },
      };
    }
    case actions.TOUR_MODAL_VISIBILITY: {
      return {
        ...state,
        tourModal: {
          ...state.tourModal,
          visibility: action.payload,
        },
      };
    }
    case actions.END_TOUR: {
      return {
        ...state,
        isTourCompleted: action.payload,
      };
    }
    case actions.QUICK_TOUR: {
      return {
        ...state,
        quickTour: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
