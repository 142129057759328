const actions = {
  ADD_ASYNC_ITEM: 'ADD_ASYNC_ITEM',
  REMOVE_ASYNC_ITEM: 'REMOVE_ASYNC_ITEM',
  UPDATE_ASYNC_ITEM: 'UPDATE_ASYNC_ITEM',
  UPDATE_ASYNC_ITEM_TEMP_ID: 'UPDATE_ASYNC_ITEM_TEMP_ID',
  ADD_TEMP_ID: 'ADD_TEMP_ID',
  SET_ASYNC_LOADER_STATUS: 'SET_ASYNC_LOADER_STATUS',
  PUSH_ITEM_AT_FIRST: 'PUSH_ITEM_AT_FIRST',
  ASYNC_UPLOAD_MEDIA_FILE: 'ASYNC_UPLOAD_MEDIA_FILE',
  ADD_ASYNC_ITEM_BEFORE_ERROR: 'ADD_ASYNC_ITEM_BEFORE_ERROR',
  UPDATE_ASYNC_LIST: 'UPDATE_ASYNC_LIST',
  RESET_ASYNC_LIST: 'RESET_ASYNC_LIST',
  UPDATE_MANUAL_RETRY: 'UPDATE_MANUAL_RETRY',
  REMOVE_ASYNC_ID: 'REMOVE_ASYNC_ID',
};

export default actions;
