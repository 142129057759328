const actions = {
  SET_PREMIUM_PLAN_INDEX_MODAL_VISIBILITY:
    'SET_PREMIUM_PLAN_INDEX_MODAL_VISIBILITY',
  SET_PREMIUM_PLAN_HELPER_MODAL_VISIBILITY:
    'SET_PREMIUM_PLAN_HELPER_MODAL_VISIBILITY',
  GET_LATEST_PLANS_LIST: 'GET_LATEST_PLANS_LIST',
  GET_LATEST_PLANS_LIST_SUCCESS: 'GET_LATEST_PLANS_LIST_SUCCESS',
  GET_LATEST_PLANS_LIST_FAILURE: 'GET_LATEST_PLANS_LIST_FAILURE',
  UPDATE_PLANS_INDEX_MODAL_VISIBILITY: 'UPDATE_PLANS_INDEX_MODAL_VISIBILITY',
  CHECKOUT_TO_CHARGEBEE: 'CHECKOUT_TO_CHARGEBEE',
  CHECKOUT_TO_CHARGEBEE_SUCCESS: 'CHECKOUT_TO_CHARGEBEE_SUCCESS',
  CHECKOUT_TO_CHARGEBEE_FAILURE: 'CHECKOUT_TO_CHARGEBEE_FAILURE',
  PLAN_CHECKOUT: 'PLAN_CHECKOUT',
  PLAN_CHECKOUT_SUCCESS: 'PLAN_CHECKOUT_SUCCESS',
  PLAN_CHECKOUT_FAILURE: 'PLAN_CHECKOUT_FAILURE',
  INVITE_EMAIL: 'INVITE_EMAIL',
  SET_INVITE_EMAIL_BUTTON_LOADER: 'SET_INVITE_EMAIL_BUTTON_LOADER',
  DOWNGRADE_DEVICE_LIST: 'DOWNGRADE_DEVICE_LIST',
  GET_CURRENT_PLAN_DETAILS: 'GET_CURRENT_PLAN_DETAILS',
  GET_CURRENT_PLAN_DETAILS_SUCCESS: 'GET_CURRENT_PLAN_DETAILS_SUCCESS',
  GET_CURRENT_PLAN_DETAILS_FAILURE: 'GET_CURRENT_PLAN_DETAILS_FAILURE',
  GET_PREMIUM_PLANS_CLIENT_PORTAL_URL: 'GET_PREMIUM_PLANS_CLIENT_PORTAL_URL',
  GET_PREMIUM_PLANS_CLIENT_PORTAL_URL_SUCCESS:
    'GET_PREMIUM_PLANS_CLIENT_PORTAL_URL_SUCCESS',
  UPDATE_PLAN: 'UPDATE_PLAN',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',
  GET_LATEST_REMOVE_CANCELLATION_URL: 'GET_LATEST_REMOVE_CANCELLATION_URL',
};
export default actions;
