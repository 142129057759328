import 'styles/components/commonNotificationAlert.scss';
import { notification } from 'antd';

const CommonNotificationAlert = ({
  type,
  message = null,
  duration = null,
  key = null,
  description = null,
  icon = null,
  className,
  top,
}) =>
  notification[type]({
    message,
    className: `common-notification-alert ${className}`,
    duration,
    key,
    description,
    icon,
    top,
  });

export default CommonNotificationAlert;
