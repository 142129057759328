import { store } from 'redux/store';
import actions from 'redux/auth/actions';
window.timer = {};

export default () =>
  new Promise(() => {
    store.dispatch({
      type: actions.REFRESH_TOKEN,
      isFromBoot: true,
    });
  });
