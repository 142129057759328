import React, { Component } from 'react';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({ showSpinner: false });
export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
      this._isMounted = false;
    }

    UNSAFE_componentWillMount() {
      NProgress.start();
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    lazyRetry = function (componentImport) {
      return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
        );
        // try to import the component
        componentImport()
          .then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
          })
          .catch((error) => {
            if (!hasRefreshed) {
              // not been refreshed yet
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
          });
      });
    };
    async componentDidMount() {
      this._isMounted = true;
      NProgress.start();
      const { default: component } = await this.lazyRetry(() =>
        importComponent(),
      );
      NProgress.done();
      if (this._isMounted) {
        this.setState({
          component: component,
        });
      }
    }
    render() {
      const Component = this.state.component;
      return Component ? <Component {...this.props} /> : <div />;
    }
  }
  return AsyncFunc;
}
