import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getRequest, postRequest } from 'utility/axiosClient';
import { handleException } from 'utility/helpers';
import {
  setPremiumPlanHelperModalVisibility,
  setPremiumPlanIndexModalVisibility,
} from '../../components/premiumPlan/helpers/utility';
import actions from './actions';
import authActions from '../auth/actions';
import plansActions from './actions';
import { cloneDeep } from 'lodash';
import { store } from '../store';
import { PLAN_IDS } from 'components/premiumPlan/helpers/constants';

export function* getPlansList(params) {
  try {
    const response = yield call(() => getRequest('v4/user/plans/list'));
    yield put({
      type: actions.GET_LATEST_PLANS_LIST_SUCCESS,
      payload: response.data,
    });
    if (params?.payload?.isFromSettingsPage) {
      const { FREE_FOREVER } = PLAN_IDS;
      yield put({
        type: actions.GET_CURRENT_PLAN_DETAILS,
      });
      yield put({
        type: authActions.SET_STORAGE_LIMIT,
        payload: {
          storageLimit:
            response.data?.plan_id === FREE_FOREVER ? 500000000 : 100000000000,
        },
      });
    }
    if (!params?.payload?.preventOpen) {
      setPremiumPlanIndexModalVisibility({
        visibility: true,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_LATEST_PLANS_LIST_FAILURE,
    });
    handleException(error);
  }
}

export function* updatePlansIndexModalVisibility(params) {
  const { visibility } = params.payload;
  yield call(() =>
    postRequest('v4/user/plan-model-visibility', {
      plan_model_visibility: visibility,
    }),
  );
}
export function* updatePlan(params) {
  const { payload } = params;
  try {
    yield call(() =>
      postRequest(`plan/${payload?.planId}/update`, {
        platform: 'web',
      }),
    );
    setPremiumPlanIndexModalVisibility({
      visibility: false,
    });
    setPremiumPlanHelperModalVisibility({
      visibility: false,
    });
    yield put({
      type: actions.UPDATE_PLAN_SUCCESS,
    });
    yield put({
      type: plansActions.GET_LATEST_PLANS_LIST,
      payload: {
        preventOpen: true,
        isFromSettingsPage: true,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_PLAN_FAILURE,
    });
    handleException(error);
  }
}
export function* checkOutToChargeBee(params) {
  const {
    planId,
    payload,
    plansIndexModalVisibility = false,
    plansHelperModalVisibility = false,
  } = params;
  try {
    const response = yield call(() =>
      postRequest(`v4/chargebee/plan/${planId}/checkout`, payload),
    );
    yield put({
      type: actions.CHECKOUT_TO_CHARGEBEE_SUCCESS,
    });
    setPremiumPlanIndexModalVisibility({
      visibility: plansIndexModalVisibility,
    });
    setPremiumPlanHelperModalVisibility({
      visibility: plansHelperModalVisibility,
    });
    window.location.href = response?.data?.hosted_page_url;
    // INFO: In case of tracking the ChargeBee api’s response, the below line is used. Because on success, the page get refreshed.
    // window.open(response.data.hosted_page_url, '_blank');
  } catch (error) {
    yield put({
      type: actions.CHECKOUT_TO_CHARGEBEE_FAILURE,
    });
    handleException(error);
  }
}

export function* planCheckOut(params) {
  const {
    planId,
    payload,
    plansIndexModalVisibility = false,
    plansHelperModalVisibility = false,
  } = params;
  try {
    yield call(() => postRequest(`v4/plan/${planId}/checkout`, payload));
    yield put({
      type: actions.PLAN_CHECKOUT_SUCCESS,
    });
    if (params?.initateGetPlansList) {
      yield put({
        type: plansActions.GET_LATEST_PLANS_LIST,
        payload: {
          preventOpen: true,
          isFromSettingsPage: true,
        },
      });
    }
    setPremiumPlanIndexModalVisibility({
      visibility: plansIndexModalVisibility,
    });
    setPremiumPlanHelperModalVisibility({
      visibility: plansHelperModalVisibility,
    });
  } catch (error) {
    yield put({
      type: actions.PLAN_CHECKOUT_FAILURE,
    });
    handleException(error);
  }
}

export function* inviteEmail(params) {
  const { payload, callback } = params;
  try {
    yield call(() => postRequest('v4/user/invite_email', payload));
    callback();
    yield put({
      type: actions.SET_INVITE_EMAIL_BUTTON_LOADER,
    });
    yield put({
      type: authActions.PLANS_INVITE_EMAIL_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: actions.SET_INVITE_EMAIL_BUTTON_LOADER,
    });
    handleException(error);
  }
}
export function* downgradeDeviceList(params) {
  const { payload } = params;
  try {
    yield call(() =>
      postRequest('v4/users/session/session-for-downgrade', payload),
    );
  } catch (error) {
    handleException(error);
  }
}
export function* getCurrentPlanDetails(params) {
  try {
    const response = yield call(() =>
      getRequest('v4/user/plan/current_plan_detail'),
    );
    yield put({
      type: actions.GET_CURRENT_PLAN_DETAILS_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: authActions.SET_CURRENT_PLAN_DETAILS,
      payload: {
        plan_id: response.data?.current_plan_id,
        plan_name: response.data?.current_plan_name,
      },
    });
  } catch (error) {
    yield put({
      type: actions.GET_CURRENT_PLAN_DETAILS_FAILURE,
    });
    handleException(error);
  }
}
export function* getLatestClientPortalUrl(params) {
  try {
    const response = yield call(() => getRequest('get_client_portal_url'));
    yield put({
      type: actions.GET_PREMIUM_PLANS_CLIENT_PORTAL_URL_SUCCESS,
      payload: response?.data?.client_portal_url,
    });
    window.location.href = response?.data?.client_portal_url;
  } catch (error) {
    handleException(error, params);
  }
}

export function* getLatestRemoveCancellationUrl(params) {
  try {
    const response = yield call(() =>
      getRequest('chargebee/plan/get_url_for_remove_plan_cancellation'),
    );
    const token = cloneDeep(store.getState().Auth.token);
    window.location.href = `${response?.data}?token=${token}&platform=web`;
  } catch (error) {
    handleException(error, params);
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LATEST_PLANS_LIST, getPlansList),
    takeEvery(
      actions.UPDATE_PLANS_INDEX_MODAL_VISIBILITY,
      updatePlansIndexModalVisibility,
    ),
    takeEvery(actions.CHECKOUT_TO_CHARGEBEE, checkOutToChargeBee),
    takeEvery(actions.PLAN_CHECKOUT, planCheckOut),
    takeEvery(actions.INVITE_EMAIL, inviteEmail),
    takeEvery(actions.DOWNGRADE_DEVICE_LIST, downgradeDeviceList),
    takeEvery(actions.GET_CURRENT_PLAN_DETAILS, getCurrentPlanDetails),
    takeEvery(
      actions.GET_PREMIUM_PLANS_CLIENT_PORTAL_URL,
      getLatestClientPortalUrl,
    ),
    takeEvery(actions.UPDATE_PLAN, updatePlan),
    takeEvery(
      actions.GET_LATEST_REMOVE_CANCELLATION_URL,
      getLatestRemoveCancellationUrl,
    ),
  ]);
}
