import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from 'utility/asyncComponent';
import { InitialLoader } from 'containers/pages/initialLoader';
import { store } from 'redux/store';
import commonActions from 'redux/common/actions';
import {
  getUserId,
  ipadProOrAir,
  mobileAndTabletCheck,
} from './utility/helpers';
import MobileAppView from 'components/shared/mobileAppView';

const setWebsiteSelectedPlan = (selectedPlan, planNature = '') => {
  store.dispatch({
    type: commonActions.UPDATE_WEBSITE_PLAN_ID_TO_SUBSCRIBE,
    payload: selectedPlan,
    planNature: planNature,
  });
  sessionStorage.setItem('organyzWebsitePlanIdToSubscribe', selectedPlan);
};

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (props?.location?.search) {
        let planId = new URLSearchParams(props.location.search).get('plan_id'),
          isClientPortalUrl = new URLSearchParams(props.location.search).get(
            'get_client_portal_url',
          );
        if (planId) {
          setWebsiteSelectedPlan(
            new URLSearchParams(props.location.search).get('plan_id'),
            new URLSearchParams(props.location.search).get('plan_nature'),
          );
        } else if (isClientPortalUrl) {
          sessionStorage.setItem('isClientPortalUrl', true);
        }
      }
      return isLoggedIn ? (
        <>
          {props.history.action === 'REPLACE' &&
            !localStorage.getItem(`firstEntryExperience-${getUserId()}`) && (
              <>
                <Redirect
                  to={{
                  pathname: '/app',
                }}
                />
                {localStorage.setItem('activeTab', [
                'home',
                'capture',
                'activities',
              ])}
            </>
          )}
          <Component {...props} />
        </>
      ) : (
        <>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
          {localStorage.removeItem(`firstEntryExperience-${getUserId()}`)}
        </>
      );
    }}
  />
);

const PreventedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  const { search } = useLocation(),
    emailParam = search?.split('?', 2)?.pop()?.split('&')?.[0],
    tokenParam = search?.split('?', 2)?.pop()?.split('&')?.[1],
    socialUserParam = search?.split('&', 3)?.reverse()?.[0];
  return (
    <Route
      {...rest}
      render={(props) => {
        let { from } = props.location.state || {
          from: { pathname: '/app' },
        };
        return isLoggedIn && !(emailParam || tokenParam || socialUserParam) ? (
          <Redirect to={from} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

const MobileAppViewRouter = () => (
  <Router>
    <Switch>
      <Route
        path={'/reset-password'}
        component={asyncComponent(() =>
          import('containers/onBoarding/resetPassword'),
        )}
      />
      <Route path="*" component={MobileAppView} />
    </Switch>
  </Router>
);

const PublicRoutes = ({ isLoggedIn, refreshingToken }) => {
  if (mobileAndTabletCheck() || ipadProOrAir() || window.innerWidth < 991) {
    return <MobileAppViewRouter />;
  }
  if (refreshingToken) {
    return <InitialLoader refreshToken={refreshingToken} />;
  }
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={'/'}
          render={() => <Redirect to={{ pathname: '/login' }} />}
        />
        <PreventedRoute
          exact
          path="/login"
          component={asyncComponent(() =>
            import('containers/onBoarding/index'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PreventedRoute
          exact
          path="/register"
          component={asyncComponent(() =>
            import('containers/onBoarding/index'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PreventedRoute
          path="/reset-password"
          component={asyncComponent(() =>
            import('containers/onBoarding/resetPassword'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PreventedRoute
          exact
          path="/forgot-password"
          component={asyncComponent(() =>
            import('containers/onBoarding/forgotPassword'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PrivateRoute
          path="/app"
          component={asyncComponent(() => import('containers/app'))}
          isLoggedIn={isLoggedIn}
          setWebsiteSelectedPlan={setWebsiteSelectedPlan}
        />
        <Route
          path={'/pay-now'}
          component={asyncComponent(() => import('./containers/pages/payNow'))}
        />
        <Route
          path={'/collect-now'}
          component={asyncComponent(() =>
            import('./containers/pages/collectNow'),
          )}
        />
        <Route
          path={'/plans-detail'}
          component={asyncComponent(() =>
            import('./containers/pricingPlan/planDetail'),
          )}
        />
        <Route
          path={'/404'}
          component={asyncComponent(() =>
            import('./containers/pages/notFound'),
          )}
        />
        <Redirect from="*" to="/404" />
      </Switch>
    </Router>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  refreshingToken: state.Auth.refreshingToken,
}))(PublicRoutes);
