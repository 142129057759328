const actions = {
  ADD_BAR_MODAL_VISIBLE: 'ADD_BAR_MODAL_VISIBLE',
  SET_ADD_BAR_MODAL_EDIT_STATUS: 'SET_ADD_BAR_MODAL_EDIT_STATUS',
  ADD_PHOTO: 'ADD_PHOTO',
  ADD_PHOTO_SUCCESS: 'ADD_PHOTO_SUCCESS',
  ADD_PHOTO_FAILURE: 'ADD_PHOTO_FAILURE',
  ADD_VIDEO: 'ADD_VIDEO',
  ADD_VIDEO_SUCCESS: 'ADD_VIDEO_SUCCESS',
  ADD_VIDEO_FAILURE: 'ADD_VIDEO_FAILURE',
  ADD_AUDIO: 'ADD_AUDIO',
  ADD_AUDIO_SUCCESS: 'ADD_AUDIO_SUCCESS',
  ADD_AUDIO_FAILURE: 'ADD_AUDIO_FAILURE',
  GET_URL: 'GET_URL',
  GET_URL_SUCCESS: 'GET_URL_SUCCESS',
  GET_URL_FAILURE: 'GET_URL_FAILURE',
  SET_MEDIA_TYPE: 'SET_MEDIA_TYPE',
  RESET_URL_PREVIEW: 'RESET_URL_PREVIEW',
  SET_CLIPBOARD_VALUE: 'SET_CLIPBOARD_VALUE',
  SET_SELECTED_ADD_BAR_STATUS: 'SET_SELECTED_ADD_BAR_STATUS',
  GET_PLACE_ID_VALUE: 'GET_PLACE_ID_VALUE',
  GET_PLACE_DETAILS_OF_SELECTED: 'GET_PLACE_DETAILS_OF_SELECTED',
  ADD_UNKNOWN_TYPE_FILE: 'ADD_UNKNOWN_TYPE_FILE',
  ADD_UNKNOWN_TYPE_FILE_SUCCESS: 'ADD_UNKNOWN_TYPE_FILE_SUCCESS',
  ADD_UNKNOWN_TYPE_FILE_FAILURE: 'ADD_UNKNOWN_TYPE_FILE_FAILURE',
  GET_CURRENT_PLACE_DETAILS: 'GET_CURRENT_PLACE_DETAILS',
  ADD_CAPTURE_ITEM: 'ADD_CAPTURE_ITEM',
  SET_ADD_BAR_MODAL_STATUS: 'SET_ADD_BAR_MODAL_STATUS',
  ADD_FOLDER_ITEM: 'ADD_FOLDER_ITEM',
  RESET_NODE_VALUE_STATUS: 'RESET_NODE_VALUE_STATUS',
  ADD_FOLDER_MEDIA_ITEM: 'ADD_FOLDER_MEDIA_ITEM',
  SET_CLIPBOARD_PERMISSION_STATUS: 'SET_CLIPBOARD_PERMISSION_STATUS',
  SET_SELECTED_SIGNATURES: 'SET_SELECTED_SIGNATURES',
  ADD_SIGNATURE_TO_FOLDER: 'ADD_SIGNATURE_TO_FOLDER',
  ADD_SIGNATURE_TO_CAPTURE: 'ADD_SIGNATURE_TO_CAPTURE',
  SET_CLIPBOARD_BROWSER_STATUS: 'SET_CLIPBOARD_BROWSER_STATUS',
  SET_CURRENT_LOCATION_DETAILS: 'SET_CURRENT_LOCATION_DETAILS',
  STORE_RECORDING_DATA: 'STORE_RECORDING_DATA',
  STORE_TOTAL_USED_STORAGE: 'STORE_TOTAL_USED_STORAGE',
  DISPLAY_STORAGE: 'DISPLAY_STORAGE',
  NOTIFICATION_MODAL_VISIBILITY: 'NOTIFICATION_MODAL_VISIBILITY',
  PLAYER: 'PLAYER',
  RECORDING_STATE: 'RECORDING_STATE',
  RESET_VIDEO_RECORDER: 'RESET_VIDEO_RECORDER',
  SET_NOTE_UPDATE_LOADER: 'SET_NOTE_UPDATE_LOADER',
  GET_CAPTURES_NOTE_ITEM_ID: 'GET_CAPTURES_NOTE_ITEM_ID',
  RESET_CAPTURES_NOTE_ITEM_ID: 'RESET_CAPTURES_NOTE_ITEM_ID',
  SET_EXPAND_COLLAPSE_ADDBAR: 'SET_EXPAND_COLLAPSE_ADDBAR',
  RESET_ADDBAR_STATUS: 'RESET_ADDBAR_STATUS',
  ADD_PROJECT_ITEM: 'ADD_PROJECT_ITEM',
  SELECTED_ADD_BAR_DETAILS: 'SELECTED_ADD_BAR_DETAILS',
  ADD_BAR_DETAILS: 'ADD_BAR_DETAILS',
  RENDER_ADD_BAR: 'RENDER_ADD_BAR',
  RESET_ADD_BAR: 'RESET_ADD_BAR',
  SET_CLOSE_ICON_VISIBILITY: 'SET_CLOSE_ICON_VISIBILITY',
  SET_ADD_BAR_MODAL_LOADER: 'SET_ADD_BAR_MODAL_LOADER',
  UPDATE_ITEM: 'UPDATE_ITEM',
  SET_ADD_BAR_ITEM_ID: 'SET_PROJECT_NOTE_ITEM_ID',
  SET_LOCATION_VISIBILITY: 'SET_LOCATION_VISIBILITY',
};

export default actions;
