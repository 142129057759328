import React from 'react';
import { createRoot } from 'react-dom/client';

// import 'antd/dist/antd.less';

import './index.scss';
import 'styles/global.scss';
import 'styles/_themeVariable.scss';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';

window.analytics &&
  window.analytics.track('Application Opened', { Platform: 'Web App' });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ConfigProvider theme={{ hashed: false, token: { fontFamily: 'Roboto' } }}>
    <DashApp />
  </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
