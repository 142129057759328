const actions = {
  GET_SIGNATURE_LIST: 'GET_SIGNATURE_LIST',
  GET_SIGNATURE_LIST_SUCCESS: 'GET_SIGNATURE_LIST_SUCCESS',
  GET_SIGNATURE_LIST_FAILURE: 'GET_SIGNATURE_LIST_FAILURE',
  CREATE_SIGNATURE: 'CREATE_SIGNATURE',
  CREATE_SIGNATURE_SUCCESS: 'CREATE_SIGNATURE_SUCCESS',
  CREATE_SIGNATURE_FAILURE: 'CREATE_SIGNATURE_FAILURE',
  DELETE_SIGNATURE: 'DELETE_SIGNATURE',
  DELETE_SIGNATURE_FAILURE: 'DELETE_SIGNATURE_FAILURE',
  UPDATE_SIGNATURE: 'UPDATE_SIGNATURE',
  UPDATE_SIGNATURE_SUCCESS: 'UPDATE_SIGNATURE_SUCCESS',
  UPDATE_SIGNATURE_FAILURE: 'UPDATE_SIGNATURE_FAILURE',
  SORT_SIGNATURE_LIST: 'SORT_SIGNATURE_LIST',
  DELETE_SIGNATURE_MODAL_VISIBILITY: 'DELETE_SIGNATURE_MODAL_VISIBILITY',
  DELETE_SIGNATURE_VIA_PUSHER: 'DELETE_SIGNATURE_VIA_PUSHER',
};

export default actions;
