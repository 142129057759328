import settings from 'config/settings';

export const iframePrivacyPolicyPageLink = `${settings.REACT_APP_IFRAME_URL}privacy-policy-m`;
export const privacyPolicyPageLink = `${settings.REACT_APP_IFRAME_URL}privacy-policy`;
export const iframeTermsOfUsePageLink = `${settings.REACT_APP_IFRAME_URL}terms-of-use-m`;
export const termsOfUsePageLink = `${settings.REACT_APP_IFRAME_URL}terms-of-use`;
export const contributorsPageLink = `${settings.REACT_APP_IFRAME_URL}contributors-m`;
export const releaseNotesPageLink = `${settings.REACT_APP_IFRAME_URL}release-notes-m`;
export const roadmapPageLink = `${settings.REACT_APP_IFRAME_URL}roadmap-m`;
export const thirdpartyPageLink = `${settings.REACT_APP_IFRAME_URL}third-party-m`;
export const announcementsPageLink = `${settings.REACT_APP_IFRAME_URL}announcements-m`;

export const language = [{ key: 'en', value: 'english' }, { key: 'number', value: 'numbers' }]

export const timePeriod = ['today'];

export const plansDetailActions = {
  in_trial_no_card: ['add', 'cancel'],
  in_trial_valid_card: ['cancel'],
  active_with_pending_invoices: ['pay', 'cancel'],
  active: [],
  scheduled_changes: ['remove'],
  basic: [],
};

export const plansUsageActions = {
  in_trial_no_card: 'add',
  active_with_pending_invoices: 'pay',
  basic: 'upgrade',
};

export const screenName = {
  register_screen: 'Registration',
  login_screen: 'Login',
  shared_spark: 'Shared Spark',
  settings: 'Settings',
  account_details: 'Account details',
  change_password: 'Change Password',
  deactivate_account: 'Deactivate Account',
  language: 'Language',
  privacy_policy: 'Privacy policy',
  contributors_list: 'Contributors List',
  terms_and_condition: 'Terms and Condition',
  synchronization: 'Synchronization',
  cloud_storage: 'Cloud Storage',
  plan_and_subscription: 'Plan and Subscription ',
  search: 'Search',
  plan_details_status_screen: 'Plan Details and status screen ',
  hosted_page: 'Hosted page',
  pay_now: 'Pay now',
  add_card: 'Add Card',
  change_plan: 'Change Plan',
  cancel_plan: 'Cancel Plan',
  reschedule_change: 'Reschedule Change',
  app_page: 'App page',
};

export const adjustEvents = {
  sign_up: { development: '3iovam', testing: '3iovam', production: 'bhgolz' },
};

export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;

export const settingsSubMenu = ['data', 'sparkbox'];

export const dataSubMenuItems = ['show-location', 'active-sessions'];

export const sparkBoxSubMenuItems = ['privacy', 'terms', 'contributors'];

export const isArchiveNotFunction = ['free_space', 'my_order'];

export const zoomObject = {
  0: 0,
  1: 25,
  2: 50,
  3: 75,
  4: 100,
  5: 150,
  6: 200,
  7: 300,
};

export const defaultFolderWidth = 280;
export const defaultFolderHeight = 450;
export const defaultCompactFolderHeight = 200;
export const defaultFolderMinHeight = 450;
export const reducedFolderWidth = 280;
export const folderLoadingSmallSize = 150;

export const compactViewSize = {
  width: `${defaultFolderWidth}px`,
  height: `${defaultCompactFolderHeight}px`,
};
export const folderLoadingSize = {
  width: `${defaultFolderWidth}px`,
  height: `${folderLoadingSmallSize}px`,
};
export const itemToday = ['just_now', 'today'];
export const singleTab = ['shared', 'settings', 'trash', 'people', 'search'];
export const sharedSparkListOrder = [
  'just_now',
  'today',
  'this_week',
  'this_month',
  'older',
  'archived',
];
export const onBoardingPage = ['/forgot-password', '/login', '/register'];
export const imageSupportedFormats = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/bmp',
];
export const newFolderAddListEnd = ['free_space', 'oldest_first'];

export const imageMinimumValue = 450;

export const mediumLoaderSize = 20;

export const checkForRunningOrReadyAsyncActionInterval = 1000;

export const showTipskeyValues = {
  add_location: 'show_tips_add_your_current_location_or_search_for_a_new_one',
  new_signature_modal:
    'show_tips_toggle_the_switch_off_for_the_information_signature',
  new_contact_modal:
    'show_tips_toggle_the_switch_off_for_the_information_contact',
  new_board_modal: 'show_tips_new_board_created_now_give_it_a_name',
};
export const showTipsSkipFoldersSortBy = [
  'newest_first',
  'oldest_first',
  'sparks_only',
];
export const audioSupportedFormats = ['audio/ogg', 'audio/mpeg', 'audio/wav'];
export const videoSupportedFormats = ['video/mp4'];

// eslint-disable-next-line
export const emailInString = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const phoneRegex = /^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/;

export const phoneNumberFormat = '(###)###-####';

export const extensionChromeStoreURL =
  'https://chrome.google.com/webstore/detail/sparkpico-web-clipper/lkiabenohaagiipilenlgelhpjnijckh';
export const mobileSizeWidth = 760;

export const parallelFileUploadLimit = 3;

export const parallelFileUploadUploadSpeedThreshold = 1; // Mbps

export const tabletSizeWidth = 1024;

export const helpCenterURL =
  'https://nmblsoft.atlassian.net/servicedesk/customer/portal/1';

export const knowledgeBaseURL =
  'https://nmblsoft.atlassian.net/wiki/spaces/CSM3/pages/160069812338/Knowledge+Base';

export const faqURL =
  'https://nmblsoft.atlassian.net/wiki/spaces/CSM3/pages/160070467581/Frequently+Asked+Questions';

export const boardGroupsListLoader = 3;

export const boardsListLoader = 3;

export const foldersListLoader = 3;

export const documentsListLoader = 3;

export const sparksListLoader = 7;

export const itemsListLoader = 5;

export const defaultUrl = 'https://test-sparks.sparkpi.co/';

export const cloudAreaLoader = 3;

export const cloudAreaGroupsLoader = 6;

export const defaultOrderBy = 'newest_first';

export const ipadResolution = {
  pro: {
    proWidth: 1024,
    proHeight: 1366,
  },
  air: {
    airWidth: 820,
    airHeight: 1180,
  },
};
