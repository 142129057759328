const actions = {
  UPDATE_HOME_NODE_LIST: 'UPDATE_HOME_NODE_LIST',
  SET_HOME_LOADER: 'SET_HOME_LOADER',
  GET_HOME_ACTIVITY_DETAILS: 'GET_HOME_ACTIVITY_DETAILS',
  GET_HOME_ACTIVITY_DETAILS_SUCCESS: 'GET_HOME_ACTIVITY_DETAILS_SUCCESS',
  UPDATE_HOME_FOLDER: 'UPDATE_HOME_FOLDER',
  UPDATE_HOME_FOLDER_FAILURE: 'UPDATE_HOME_FOLDER_FAILURE',
  SET_HOME_LOCK_STATUS: 'SET_HOME_LOCK_STATUS',
  HOME_SHARE_SPARK: 'HOME_SHARE_SPARK',
  SHARE_HOME_NODE_SUCCESS: 'SHARE_HOME_NODE_SUCCESS',
  DUPLICATE_HOME_NODE_OR_SPARK: 'DUPLICATE_HOME_NODE_OR_SPARK',
  SET_SELECTED_HOME_NODE_ID: 'SET_SELECTED_HOME_NODE_ID',
  DUPLICATE_HOME_SUCCESS: 'DUPLICATE_HOME_SUCCESS',
  GET_HOME_FOLDER_ITEMS_LIST: 'GET_HOME_FOLDER_ITEMS_LIST',
  GET_HOME_FOLDER_ITEMS_LIST_SUCCESS: 'GET_HOME_FOLDER_ITEMS_LIST_SUCCESS',
  DELETE_HOME_NODE: 'DELETE_HOME_NODE',
  SHOW_HOME_FOLDER_PROPERTIES_MODAL: 'SHOW_HOME_FOLDER_PROPERTIES_MODAL',
  UPDATE_HOME_FOLDER_PROPERTIES_SUCCESS:
    'UPDATE_HOME_FOLDER_PROPERTIES_SUCCESS',
  DELETE_HOME_NODE_SUCCESS: 'DELETE_HOME_NODE_SUCCESS',
  UPDATE_HOME_NODE_ON_LIST: 'UPDATE_HOME_NODE_ON_LIST',
  UPDATE_HOME_FOLDER_DETAILS: 'UPDATE_HOME_FOLDER_DETAILS',
  UPDATE_HOME_NODE_ITEMS_DATA: 'UPDATE_HOME_NODE_ITEMS_DATA',
  UPDATE_HOME_FOLDER_ITEM_SUCCESS: 'UPDATE_HOME_FOLDER_ITEM_SUCCESS',
  ROTATE_HOME_IMAGE_ANGLE: 'ROTATE_HOME_IMAGE_ANGLE',
  SAVE_ROTATED_HOME_IMAGE_SUCCESS: 'SAVE_ROTATED_HOME_IMAGE_SUCCESS',
  ASYNC_SAVE_ROTATED_HOME_IMAGE_SUCCESS:
    'ASYNC_SAVE_ROTATED_HOME_IMAGE_SUCCESS',
  EDIT_HOME_MODAL_VISIBLE: 'EDIT_HOME_MODAL_VISIBLE',
  UPDATE_HOME_NOTE_ITEM_SUCCESS: 'UPDATE_HOME_NOTE_ITEM_SUCCESS',
  UPDATE_HOME_CONTACT_ITEM_SUCCESS: 'UPDATE_HOME_CONTACT_ITEM_SUCCESS',
  UPDATE_HOME_LOCATION_ITEM_SUCCESS: 'UPDATE_HOME_LOCATION_ITEM_SUCCESS',
  UPDATE_HOME_LINK_ITEM_SUCCESS: 'UPDATE_HOME_LINK_ITEM_SUCCESS',
  SHOW_HOME_ITEM_PROPERTIES_MODAL: 'SHOW_HOME_ITEM_PROPERTIES_MODAL',
  UPDATE_HOME_ITEM_PROPERTIES_SUCCESS: 'UPDATE_HOME_ITEM_PROPERTIES_SUCCESS',
  DUPLICATE_HOME_NODE_ITEMS: 'DUPLICATE_HOME_NODE_ITEMS',
  DUPLICATE_HOME_NODE_ITEMS_SUCCESS: 'DUPLICATE_HOME_NODE_ITEMS_SUCCESS',
  DELETE_HOME_NODE_ITEMS: 'DELETE_HOME_NODE_ITEMS',
  UPDATE_DELETE_HOME_NODE_MODAL_VISIBILITY:
    'UPDATE_DELETE_HOME_NODE_MODAL_VISIBILITY',
  UPDATE_DELETE_HOME_NODE_ITEMS_MODAL_VISIBILITY:
    'UPDATE_DELETE_HOME_NODE_ITEMS_MODAL_VISIBILITY',
  SET_HOME_SORT_BY_VALUE_FOLDERS: 'SET_HOME_SORT_BY_VALUE_FOLDERS',
  CREATE_QUICK_SPARK_FOLDER_SUCCESS: 'CREATE_QUICK_SPARK_FOLDER_SUCCESS',
  SET_HOME_DOCUMENT_VIEW_TYPE: 'SET_HOME_DOCUMENT_VIEW_TYPE',
  ADD_FOLDER_INTO_HOME: 'ADD_FOLDER_INTO_HOME',
  ASYNC_UPDATE_HOME_NODES_LISTS: 'ASYNC_UPDATE_HOME_NODES_LISTS',
  UPDATE_SHOW_HOME_NODE_IN_DOM: 'UPDATE_SHOW_HOME_NODE_IN_DOM',
  UPDATE_UNLOCK_HOME_MODAL_VISIBILITY: 'UPDATE_UNLOCK_HOME_MODAL_VISIBILITY',
  ADD_ITEM_FROM_CAPTURE_TO_HOME: 'ADD_ITEM_FROM_CAPTURE_TO_HOME',
  ADD_ITEM_FROM_CAPTURE_TO_HOME_SUCCESS:
    'ADD_ITEM_FROM_CAPTURE_TO_HOME_SUCCESS',
  ADD_ITEM_FROM_CAPTURE_TO_HOME_FAILURE:
    'ADD_ITEM_FROM_CAPTURE_TO_HOME_FAILURE',
  MOVE_HOME_NODE_ITEM: 'MOVE_HOME_NODE_ITEM',
  ADD_HOME_ITEM_FROM_CAPTURE_SUCCESS: 'ADD_HOME_ITEM_FROM_CAPTURE_SUCCESS',
  CONVERTING_HOME_ITEM_TO_NODE: 'CONVERTING_HOME_ITEM_TO_NODE',
  CREATE_HOME_NEW_FOLDER_FROM_ITEM_SUCCESS:
    'CREATE_HOME_NEW_FOLDER_FROM_ITEM_SUCCESS',
  CREATE_HOME_NODE_FROM_CAPTURE: 'CREATE_HOME_NODE_FROM_CAPTURE',
  CREATE_HOME_NODE_FROM_CAPTURE_SUCCESS:
    'CREATE_HOME_NODE_FROM_CAPTURE_SUCCESS',
  MOVE_NODE_TO_HOME_ACTIVITY_SUCCESS: 'MOVE_NODE_TO_HOME_ACTIVITY_SUCCESS',
  REORDER_HOME_NODE: 'REORDER_HOME_NODE',
  SHARE_HOME_SPARK_SUCCESS: 'SHARE_HOME_SPARK_SUCCESS',
  UPDATE_HOME_PREVIEW_MODAL_VISIBILITY: 'UPDATE_HOME_PREVIEW_MODAL_VISIBILITY',
  REORDER_HOME_NODE_ITEM: 'REORDER_HOME_NODE_ITEM',
  UPDATE_HOME_FOLDER_MEDIA_ITEM: 'UPDATE_HOME_FOLDER_MEDIA_ITEM',
  UPDATE_HOME_FOLDER_STACK_ORDER: 'UPDATE_HOME_FOLDER_STACK_ORDER',
  UPDATE_HOME_EMPTY_SHARE_MODAL_VISIBILITY:
    'UPDATE_HOME_EMPTY_SHARE_MODAL_VISIBILITY',
  SET_HOME_DESELECTED_NODE_ID: 'SET_HOME_DESELECTED_NODE_ID',
  UPDATE_HOME_FOLDER_LISTS_FETCH_TIME: 'UPDATE_HOME_FOLDER_LISTS_FETCH_TIME',
  UPDATE_HOME_IMAGE_THUMBNAIL_VALUES: 'UPDATE_HOME_IMAGE_THUMBNAIL_VALUES',
  UPDATE_HOME_NODE_MEDIA_ITEM: 'UPDATE_HOME_NODE_MEDIA_ITEM',
  UPDATE_HOME_ACTIVITY_DETAILS: 'UPDATE_HOME_ACTIVITY_DETAILS',
  UPDATE_HOME_SELECT_MULTIPLE_FOLDER_ITEMS_MODAL:
    'UPDATE_HOME_SELECT_MULTIPLE_FOLDER_ITEMS_MODAL',
  UPDATE_HOME_SELECT_MULTIPLE_FOLDER_ITEMS:
    'UPDATE_HOME_SELECT_MULTIPLE_FOLDER_ITEMS',
  UPDATE_DELETED_HOME_NODE_ITEMS: 'UPDATE_DELETED_HOME_NODE_ITEMS',
  UPDATE_DELETED_HOME_NODE: 'UPDATE_DELETED_HOME_NODE',
  ASYNC_ARCHIVE_HOME_FOLDER_STATUS: 'ASYNC_ARCHIVE_FOLDER_STATUS',
  ARCHIVE_HOME_FOLDER: 'ARCHIVE_FOLDER',
  ASYNC_UNARCHIVE_HOME_FOLDER_STATUS: 'ASYNC_UNARCHIVE_FOLDER_STATUS',
  UNARCHIVE_HOME_FOLDER: 'UNARCHIVE_FOLDER',
  REORDER_ARCHIVED_HOME_NODE: 'REORDER_ARCHIVED_HOME_NODE',
  REORDER_ARCHIVED_HOME_NODE_FAILURE: 'REORDER_ARCHIVED_HOME_NODE_FAILURE',
  UPDATE_HOME_TARGET_ZONE_REORDER: 'UPDATE_HOME_TARGET_ZONE_REORDER',
  ITEMS_HOME_FOLDER_DOWNLOAD_SUCCESSFULLY: 'ITEMS_HOME_FOLDER_DOWNLOAD_SUCCESSFULLY',
  UPDATE_HOME_ALL_FOLDERS_FOLDER_VIEW: 'UPDATE_HOME_ALL_FOLDERS_FOLDER_VIEW',
  MOVE_HOME_NODE_SELECTED_ITEM: 'MOVE_HOME_NODE_SELECTED_ITEM',
  UPDATE_HOME_TARGET_ZONE_REORDER_CALLBACK: 'UPDATE_HOME_TARGET_ZONE_REORDER_CALLBACK',
};

export default actions;
