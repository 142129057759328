import commonActions from '../../redux/common/actions';
import { store } from '../../redux/store';
import sharedSparkActions from '../../redux/sharedSparks/actions';
import React from 'react';
import { getApiDetails } from '../shared/helper';
import { shareObject, showSourceSpark } from '../contextMenu/helper';
import { isObjectEmpty, setActiveTabs, Translate, updateHistory } from '../../utility/helpers';
import actions from '../../redux/boardGroups/actions';
import { expandHierarchyLevel } from '../boardGroups/helper';
import i18n from '../../i18n';

export function SuccessAlertMessage({
  notificationAPI,
  idData,
  nodeId,
  itemId,
  projectId,
  isFromTrash,
  currentTab,
  highlightId,
  currentHierarchy,
  isGroup,
  nodeType,
  groupId,
  parentId,
  itemType,
  preventShareRedirection,
  isFromSparkContextMenu,
  sharedLink,
  sharingData,
  isShareIndividualItem,
  onboardingState,
  extractName,
  alertText,
  data,
  directParent = false,
}) {
  function AlertMessage() {
    if (!isObjectEmpty(extractName)) {
      return (
        <>
          {extractName.sourceName}{' '}
          <Translate>successfully_moved</Translate>{' '}
          {extractName.destinationName}
        </>
      );
    } else {
      return <div>{i18n.t(alertText, { data })}</div>;
    }
  }
  function GoToIt() {
    if (idData) {
      const { jotsProjectDetails } = store.getState().jotsReducer;
      return (
        <div
          className={'optional-go-to'}
          onClick={() => {
            if (isFromTrash) {
              updateHistory('/app');
              if (jotsProjectDetails?.id === projectId) {
                localStorage.setItem('activeTab', ['home']);
                store.dispatch({
                  type: commonActions.HIGHLIGHT_ITEM,
                  payload: itemId || nodeId,
                });
              } else if (projectId === null && nodeId === null) {
                localStorage.setItem('activeTab', ['capture']);
                store.dispatch({
                  type: commonActions.HIGHLIGHT_ITEM,
                  payload: itemId,
                });
              } else {
                store.dispatch({
                  type: commonActions.SET_REDIRECTED_ITEM_DETAILS,
                  payload: {
                    redirectedItemDetails: {
                      id: data?.id,
                      parentId: data?.parent_id,
                      objectType: data?.object_type,
                    },
                  },
                });
                localStorage.setItem('activeTab', ['activities']);
              }
            } else if (isGroup) {
              if (!currentHierarchy) {
                store.dispatch({
                  type: actions.CLOUD_AREA_COMMON_LOADER,
                  payload: true,
                });
                store.dispatch({
                  type: actions.GET_BOARD_GROUPS_LIST,
                });
                store.dispatch({
                  type: actions.RESET_CURRENT_TYPE,
                });
                store.dispatch({
                  type: commonActions.HIGHLIGHT_ITEM,
                  payload: highlightId,
                });
              } else if (!currentTab?.includes('activites')) {
                setActiveTabs(['activities', 'home']);
                store.dispatch({
                  type: commonActions.HIGHLIGHT_ITEM,
                  payload: highlightId,
                });
              }
            } else if (currentHierarchy) {
              store.dispatch({
                type: commonActions.HIGHLIGHT_ITEM,
                payload: highlightId,
              });
            } else if (parentId === jotsProjectDetails?.id) {
              if (!currentTab?.includes('home')) {
                setActiveTabs(['activities', 'home']);
              }
              store.dispatch({
                type: commonActions.HIGHLIGHT_ITEM,
                payload: highlightId,
              });
            } else if (idData !== jotsProjectDetails?.id) {
              if (!currentTab?.includes('activities')) {
                setActiveTabs(['activities', 'home']);
              }
              expandHierarchyLevel(
                { selectedKey: idData, itemType, highlightId },
                true,
              );
            } else {
              if (!currentTab?.includes('home')) {
                setActiveTabs(['activities', 'home']);
              }
              store.dispatch({
                type: commonActions.HIGHLIGHT_ITEM,
                payload: highlightId,
              });
            }
            notificationAPI.destroy();
          }}
        >
          Go to it
        </div>
      );
    }
    return null;
  }
  function ViewSharedLink() {
    if (
      preventShareRedirection &&
      !isFromSparkContextMenu &&
      sharedLink &&
      directParent
    ) {
      return (
        <div
          className={'optional-go-to'}
          onClick={() => {
            const { typeId, location, parentId, itemDetails } = getApiDetails(
              sharingData?.itemType,
              sharingData,
            );
            store.dispatch({
              type: sharedSparkActions.UPDATE_PAUSED_STATUS_PREVIEW_MODAL,
              payload: itemDetails?.paused,
            });
            shareObject({
              id: typeId,
              location,
              parentId,
              itemDetails,
              url: sharingData?.url,
            });
          }}
        >
          <Translate>view_url</Translate>
        </div>
      );
    }
    return null;
  }
  function GoToSharedLinksPage() {
    if (
      !preventShareRedirection &&
      !isFromSparkContextMenu &&
      !sharingData?.url &&
      sharedLink &&
      !isShareIndividualItem &&
      !(onboardingState === 4 || onboardingState === 5) &&
      directParent
    ) {
      return (
        <div
          className={'optional-go-to'}
          onClick={() => {
            const goToSpark = async () => {
              await updateHistory('/app/shared');
              store.dispatch({
                type: sharedSparkActions.RESET_SPARK_MODAL,
              });
              store.dispatch({
                type: commonActions.HIGHLIGHT_ITEM,
                payload: sharingData?.id,
              });
              setTimeout(() => {
                store.dispatch({
                  type: commonActions.HIGHLIGHT_ITEM,
                });
              }, 3000);
            };
            goToSpark();
          }}
        >
          <SharedLinksGotoHelper sharingData={sharingData} />
        </div>
      );
    }
    return null;
  }
  function ViewSourceFromSharedLinkPage() {
    if (
      !preventShareRedirection &&
      sharingData?.url &&
      !isShareIndividualItem &&
      directParent
    ) {
      return (
        <div
          className={'optional-go-to'}
          onClick={() => {
            const { id: sharingId, itemDetails: data } = sharingData,
              {
                node_id: shareItemNodeId,
                group_id: shareItemGroupId,
                project_id: shareItemProjectId,
                location: shareItemLocation,
              } = data;
            let updatedNodeType;
            if (data?.object_type === 'page') {
              updatedNodeType = 'documents';
            } else if (data?.object_type === 'folder') {
              updatedNodeType = 'folders';
            } else {
              updatedNodeType = 'boards';
            }
            showSourceSpark(
              sharingId,
              shareItemLocation === 'Jots',
              shareItemNodeId,
              shareItemGroupId,
              shareItemProjectId,
              updatedNodeType,
              data,
            );
          }}
        >
          <SharedLinksGotoHelper sharingData={sharingData} />
        </div>
      );
    }
    return null;
  }
  function SharedLinksGotoHelper() {
    switch (sharingData?.nodeType) {
      case 'document':
        return <Translate>view_page</Translate>;
      case 'folder':
        return <Translate>view_folder</Translate>;
      case 'board':
        return <Translate>view_board</Translate>;
      default:
        return <Translate>view_page</Translate>;
    }
  }

  return (
    <>
      <AlertMessage />
      <GoToIt />
      <ViewSharedLink />
      <ViewSourceFromSharedLinkPage />
      <GoToSharedLinksPage />
    </>
  );
}
