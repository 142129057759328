import 'styles/containers/initialLoader.scss';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, Tooltip } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { browserWarmLoad, browserWarmLoadTime } from 'containers/app/constants';
import { ReactComponent as WaterMarkInfo } from 'images/svgs/waterMarkInfo.svg';
import { Translate } from 'utility/helpers';
import { ReactComponent as ModalClose } from 'images/svgs/close.svg';

export const InitialLoader = ({ size, className, refreshToken }) => {
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    if (refreshToken) {
      const intervalId = setInterval(() => {
        setVisibility(true);
      }, browserWarmLoadTime);
      return () => clearInterval(intervalId);
    }
  }, [refreshToken]);
  return (
    <>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: size ? size : 40 }} spin />
        }
        className={`initial-loading ${className ? className : ''}`}
      />
      {visibility && (
        <Modal
          title={
            <div>
              <WaterMarkInfo />
            </div>
          }
          width={330}
          open={visibility}
          className={'browser-warm-load-modal'}
          closeIcon={
            <Tooltip title={<Translate>tt_close</Translate>}>
              <ModalClose alt={'close-icon'} height={24} />
            </Tooltip>
          }
          onCancel={() => setVisibility(false)}
          footer={
            <Button onClick={() => setVisibility(false)}>
              <Translate>browser_warm_load_button</Translate>
            </Button>
          }
          centered
          maskClosable={false}
        >
          {browserWarmLoad.map((data) => {
            const { translate } = data;
            return (
              <div>
                <Translate>{translate}</Translate>
              </div>
            );
          })}
        </Modal>
      )}
    </>
  );
};
